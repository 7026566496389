import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { Tabs, Tab, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Menu, MenuItem, InputAdornment, IconButton } from "@mui/material";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { CSVLink } from "react-csv";
import manualcss from '../assest/css/manualcss.css';
import ssk from "../assest/recommendationimage/ssk.svg";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import commoncss from "../assest/css/common.css";
import calenderIcon from '../assest/images/details/calender-icon.png'; // Import the calendar icon
import vendorBookingCss from "../assest/css/vendor_booking.css";
import kyccss from "../FieldsExecutive/kyc.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BookIcon from '@mui/icons-material/Book'; // Import the booking icon

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const VendorBooking = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const vendorId = localStorage.getItem("id2");
    const [value, setValue] = useState(0);
    const [venues, setVenues] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFilterInputs, setShowFilterInputs] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        fetchVenueData(venues[newValue].venue_id);
    };

    const loadVenues = async () => {
        try {
            const result = await axios.get(`${apiBaseUrl}/VenueReport/${vendorId}`);
            setVenues(result.data.vendorBookingCounts || []);
            if (result.data.vendorBookingCounts.length > 0) {
                fetchVenueData(result.data.vendorBookingCounts[0].venue_id);
            }
        } catch (error) {
            console.error("Error fetching venue data:", error);
        }
    };

    const fetchVenueData = async (venueId) => {
        try {
            const result = await axios.get(`${apiBaseUrl}/particularVenueReport/${venueId}`);
            setData(result.data.particularVenueReport || []);
            setFilteredData(result.data.particularVenueReport || []);
        } catch (error) {
            console.error("Error fetching venue report data:", error);
        }
    };

    const fetchFilteredData = async () => {
        try {
            const result = await axios.get(`${apiBaseUrl}/date_post/${vendorId}`, {
                params: {
                    start_date: startDate,
                    end_date: endDate
                }
            });
            setFilteredData(result.data.date_post || []);
        } catch (error) {
            console.error("Error fetching filtered data:", error);
        }
    };

    useEffect(() => {
        loadVenues();
    }, []);

    const back = () => {
        navigate("/vendor_dashboard");
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/vendor_signin");
    };

    const handleDownloadClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDownloadClose = () => {
        setAnchorEl(null);
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({ html: '#table-to-download' });
        doc.save('report.pdf');
    };

    const csvHeaders = [
        { label: "Sr.No", key: "srNo" },
        { label: "User Name", key: "user_name" },
        { label: "Amount", key: "amount" },
        { label: "Booking Date", key: "booking_date" },
        { label: "Slot Time", key: "slot_time" },
        { label: "Status", key: "booking_status" },
    ];

    const csvData = filteredData.map((row, index) => ({
        srNo: index + 1,
        user_name: row.user_name,
        amount: row.amount,
        booking_date: row.booking_date,
        slot_time: `${row.start_time}-${row.end_time}`,
        booking_status: row.booking_status,
    }));

    return (
        <main>
            <div style={{ marginBottom: "80px" }}>
                <div className="page-title pageHead">
                    <button
                        onClick={back}
                        type="button"
                        className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                    >
                        <img src={arrowleftblack} alt="arrow" />
                    </button>
                    <h3 className="main-title mainTitleLight">Booking</h3>
                </div>
                <div >
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <Button variant="contained" style={{ marginLeft: '10px', backgroundColor: "#0e795d" }} onClick={() => setShowFilterInputs(!showFilterInputs)}>Apply Filter</Button>
                        <Button variant="contained" style={{ marginRight: '10px', backgroundColor: "#0e795d" }} onClick={handleDownloadClick}>Download Report</Button>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleDownloadClose}
                        >
                            <MenuItem onClick={downloadPDF}>Download as PDF</MenuItem>
                            <MenuItem>
                                <CSVLink
                                    data={csvData}
                                    headers={csvHeaders}
                                    filename="report.csv"
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    Download as CSV
                                </CSVLink>
                            </MenuItem>
                        </Menu>
                    </Box>
                    {showFilterInputs && (
                        <Box display="flex" justifyContent="space-between" mb={2}>
                            <TextField
                                id="start-date"
                                label="Start Date"
                                type="date"
                                placeholder="dd-mm-yyyy"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    className: 'custom-date-picker', // Apply custom CSS class
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <img src={calenderIcon} width="20px" alt="calendar icon" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ marginRight: '10px', marginLeft: '5px', width: "150px" }}
                            />
                            <TextField
                                id="end-date"
                                label="End Date"
                                type="date"
                                placeholder="dd-mm-yyyy"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    className: 'custom-date-picker', // Apply custom CSS class
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <img src={calenderIcon} width="20px" alt="calendar icon" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ marginRight: '10px', width: "150px" }}
                            />
                            <Button variant="contained" style={{ marginLeft: '8px', marginRight: '5px', backgroundColor: '#0e795d', }} onClick={fetchFilteredData}>Done</Button>
                        </Box>
                    )}
                </div>
                <div>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" sx={{
                        '& .Mui-selected': {
                            color: '#0e795d',
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#0e795d',
                        },
                    }}>
                        {venues.map((venue, index) => (
                            <Tab
                                className="bookingTabes"
                                label={venue.venue_name}
                                {...a11yProps(index)}
                                key={venue.venue_id}
                            />
                        ))}
                    </Tabs>
                    {venues.map((venue, index) => (
                        <TabPanel value={value} index={index} key={venue.venue_id}>
                            {filteredData.length ? (
                                <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
                                    <Table aria-label="simple table" id="table-to-download">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="right">Sr.No</TableCell>
                                                <TableCell align="right">User Name</TableCell>
                                                <TableCell align="right">Amount</TableCell>
                                                <TableCell align="right">Booking Date</TableCell>
                                                <TableCell align="right">Slot Time</TableCell>
                                                <TableCell align="right">Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredData.map((row, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell align="right">{idx + 1}</TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.user_name}
                                                    </TableCell>
                                                    <TableCell align="right">{row.amount}</TableCell>
                                                    <TableCell align="right">{row.booking_date}</TableCell>
                                                    <TableCell align="right">{row.start_time}-{row.end_time}</TableCell>
                                                    <TableCell align="right">{row.booking_status}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Typography>No data available for this venue.</Typography>
                            )}
                        </TabPanel>
                    ))}
                </div>
            </div>
            {/* Footer */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "#fff",
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-around",
                    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                }}
            >
                <Link to="/vendor_dashboard">
                    <IconButton>
                        <HomeIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_bookings"> {/* Add the booking link */}
                    <IconButton>
                        <BookIcon /> {/* Use the booking icon */}
                    </IconButton>
                </Link>
                <Link to="/vendor_addvenue">
                    <IconButton>
                        <AddCircleIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_query">
                    <IconButton>
                        <QuestionAnswerIcon />
                    </IconButton>
                </Link>
                <Link to="/vendorUpdate_profile">
                    <IconButton>
                        <PersonIcon />
                    </IconButton>
                </Link>
            </Box>
        </main>
    );
};

export default VendorBooking;
