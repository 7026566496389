import React, { useState } from 'react';
import Calendar from 'react-calendar'; // Assuming you're using react-calendar library
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CalendarContainer = styled.div`
  margin-bottom: 20px;
`;

const TimeSlotsContainer = styled.div`
  text-align: center;
`;

const CalendarWithTimeSlots = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  // Dummy time slots, replace with actual data or fetch from an API
  const timeSlots = ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM'];

  return (
    <Container>
      <CalendarContainer>
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
        />
      </CalendarContainer>
      <TimeSlotsContainer>
        <h2>Available Time Slots for {selectedDate.toDateString()}:</h2>
        <ul>
          {timeSlots.map((slot, index) => (
            <li key={index}>{slot}</li>
          ))}
        </ul>
      </TimeSlotsContainer>
    </Container>
  );
};

export default CalendarWithTimeSlots;
