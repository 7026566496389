import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import "../assest/css/bootstrap.min.css";
import "../slots/slot_list.css";
import "../assest/css/swiper-bundle.min.css";
import "../assest/css/common.css";
import "../assest/css/welcome.css";
import "../assest/css/animations.css";
import "../assest/css/auth.css";
import "../assest/css/profile.css";
import eventCSS from '../assest/css/event.css';
import plus from '../assest/svg/plus-black.png';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BookIcon from '@mui/icons-material/Book'; // Import the booking icon

const ViewRules = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const venueIddddid = searchParams.get("id");
    const navigate = useNavigate();

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [data, setData] = useState(null);

    const id2 = localStorage.getItem("id2");

    useEffect(() => {
        fetchData();
    }, [venueIddddid]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/rulesView/${id2}`);
            setData(response.data);
            console.log("data", response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("token", token);
        if (token == null) {
            navigate("/vendor_dashboard");
        } else {
            fetchData();
        }
    }, [navigate]);

    const back = () => {
        window.history.back();
    };
    const addRules = () => {
        navigate("/vendor_rules");
    };

    return (
        <div>
            <main className="tour-guide" style={{ marginBottom: "80px" }}>
                <div className="page-title pageHead">
                    <button
                        onClick={back}
                        type="button"
                        className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                    >
                        <img src={arrowleftblack} alt="arrow" />
                    </button>
                    <h3 className="main-title mainTitleLight" style={{ fontSize: "18px" }}>Rules and Terms & Condition</h3>
                    <button
                        onClick={addRules}
                        type="button"
                        className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
                        <img src={plus} alt="arrow" />
                    </button>
                </div>
                <div className="" style={{ padding: '0px 20px 20px 20px' }}>
                    <h4>Rules and Regulation</h4>
                    <div>
                        {data && data.rules && data.rules.length > 0 ? (
                            <ul style={{ padding: "0px" }}>
                                {data.rules.map((rule, index) => (
                                    <li key={index}>
                                        <div style={{ padding: '0 10px 0 20px' }}>
                                            <div className="about-description mb-3">
                                                <p style={{ fontSize: '18px' }}>{rule.rules}</p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="addvenue" style={{ textAlign: "center" }}>
                                <p style={{ fontSize: '20px' }}>No Rules</p>
                            </div>
                        )}
                    </div>
                    <h4>Terms and Condition</h4>
                    <div>
                        {data && data.rules && data.rules.length > 0 ? (
                            <ul style={{ padding: "0px" }}>
                                {data.rules.map((rule, index) => (
                                    <li key={index}>
                                        <div style={{ padding: '0 10px 0 20px' }}>
                                            <div className="about-description mb-1">
                                                <p style={{ fontSize: '18px' }}>{rule.additional_terms}</p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="addvenue" style={{ textAlign: "center" }}>
                                <p style={{ fontSize: '20px' }}>No Terms and Conditions</p>
                            </div>
                        )}
                    </div>
                </div>
            </main>
            {/* Footer */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "#fff",
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-around",
                    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                }}
            >
                <Link to="/vendor_dashboard">
                    <IconButton>
                        <HomeIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_bookings"> {/* Add the booking link */}
                    <IconButton>
                        <BookIcon /> {/* Use the booking icon */}
                    </IconButton>
                </Link>
                <Link to="/vendor_addvenue">
                    <IconButton>
                        <AddCircleIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_query">
                    <IconButton>
                        <QuestionAnswerIcon />
                    </IconButton>
                </Link>
                <Link to="/vendorUpdate_profile">
                    <IconButton>
                        <PersonIcon />
                    </IconButton>
                </Link>
            </Box>
        </div>
    );
};

export default ViewRules;
