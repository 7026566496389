import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// or

import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';

import bootstrapcss from "../assest/css/bootstrap.min.css";
import commoncss from "../assest/css/common.css";
import avatar from "../assest/images/profile/avatar.png";
import mapmarker from "../assest/svg/map-marker.svg";
import bellblack from "../assest/svg/bell-black.svg";
import executiveprofile from "../assest/images/profile/executive.png";
import kyc1 from "../assest/homeimage/ky1.svg";
import kyc2 from "../assest/homeimage/newkyc.jpg";
import logout from "../assest/homeimage/newlogout.png";
import addvenue from "../assest/homeimage/add.png";
import venuelist from "../assest/homeimage/vendorlist.png";
import history from "../assest/homeimage/history.png";
import reportIcon from "../assest/homeimage/vendor_report_icon.png";
import EventIcon from "../assest/homeimage/event_icon.png";
import query from "../assest/homeimage/query.png";

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BookIcon from '@mui/icons-material/Book'; // Import the booking icon

import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import slider1 from "../assest/homeimage/slider2.png";
import slider2 from "../assest/homeimage/slider3.png";
import slider3 from "../assest/homeimage/v1.png";
import slider4 from "../assest/homeimage/v2.png";
import { Helmet } from 'react-helmet';
import kyccss from "../FieldsExecutive/kyc.css";
import { requestPermission, messagingPromise } from '../firebase';

import Slider from "react-slick";


const VendorDashboard = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const fetchData = async () => {
    try {
      const id2 = localStorage.getItem("id2");

      console.log('id2:', id2)
      const vendorResponse = await axios.get(`${apiBaseUrl}/vendorIndex/${id2}`);

      console.log("vendor data", vendorResponse);


      setData(vendorResponse.data);
      console.log("dta", vendorResponse.data);
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  //notification function
  const [getRows, setRows] = useState([]);
  const [loadData, setAData] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
    loadList();
  }

  const updateNotification = () => {
    //Update Notification table 
    axios.post(`${apiBaseUrl}/updateNotification`)
      .then((result) => {
        setOpen(false)
        getLatestRowDetails();
      })
      .catch(() => {
        alert('Error in the Code');
      });
  }

  const loadList = async () => {
    try {
      const id2 = localStorage.getItem("id2");
      console.log(id2);
      const result = await axios.get(`${apiBaseUrl}/vendor_notif_list/${id2}`);
      console.log("Result list data:", result.data); // Log the fetched data
      setAData(result.data); // Set the fetched data to state
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors here
    }
  };

  // To get total count from backend
  const getLatestRowDetails = async () => {

    // const id2 = localStorage.getItem("id2");
    // console.log(id2);
    // const results = await axios.get(`${apiBaseUrl}/vendor_notif_getLatestRow/${id2}`);
    const results = await axios.get(`${apiBaseUrl}/getLatestRow`);
    console.log("Result latest row data:", results.data);
    setRows(results.data);
  };

  useEffect(() => {
    // Replace icons after component is mounted

    const token = localStorage.getItem("token");
    const id2 = localStorage.getItem("id2");
    const user_type = localStorage.getItem("user_type");

    console.log(user_type);

    if (token === null) {
      navigate("/vendor_signin");
    } else if (user_type === 'Vendor') {
      navigate("/vendor_dashboard");
    } else {
      navigate("/fields_executive_dashboard");
    }
    // feather.replace();

    loadList();
    getLatestRowDetails();

    fetchData();

  }, []);

  useEffect(() => {
    requestPermission();

    // Listen for messages in the foreground
    messagingPromise.then((messaging) => {
      if (messaging) {
        // This check is redundant, as it is handled in firebase.js
        console.log('Listening for foreground messages');
      } else {
        console.error("Messaging is not initialized.");
      }
    }).catch((error) => {
      console.error("Error initializing messaging: ", error);
    });
  }, []);

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem("token");
    // Navigate to the login page

    navigate("/vendor_signin");
  };

  const venueList = () => {
    navigate("/vendor_venue_list");
  };


  // const viewslot = () =>{
  //    navigate("/viewall_slot");
  // }


  const viewslot = (id) => {
    navigate(`/viewall_slot?id=${id}`);
  };
  const bookSlot = (id) => {
    navigate(`/selfSlotBook?id=${id}`);
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div style={{ padding: "10px" }} className="bg-primary-main">
          {/* Header */}
          <Helmet>
            <title>Dashboard</title>
            {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
          </Helmet>

          <section className="info d-flex align-items-start justify-content-between">
            <div className="d-flex align-items-center justify-content-between gap-14 " style={{ padding: '8px' }}>
              <div className=" d-flex ">
                <div
                  className="image"
                  style={{ width: "50px", height: "50px" }}
                >
                  <Link to="/vendorUpdate_profile" style={{ textDecoration: "none" }}>
                    <img
                      src={avatar}
                      alt="avatar"
                      className="object-fit-cover img-fluid rounded-full"
                    />
                  </Link>
                </div>
                <div>
                  <h5
                    style={{
                      fontSize: "15px",
                      paddingLeft: "10px",
                      color: "#ffffff",
                      marginTop: "4px",
                    }}
                  >
                    {/* Hi, {data && data.show && data.show.name}{" "} */}
                    Hi,{" "}
                    {loadData &&
                      loadData.vendor &&
                      loadData.vendor.owner_name
                    }{" "}
                  </h5>
                  <h7
                    className="d-flex "
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      color: "#ffffff",
                    }}
                  >
                    <img src={mapmarker} alt="icon" />
                    India
                  </h7>
                </div>
              </div>

              <div></div>
            </div>

            {/* <ul className="d-flex  gap-16" style={{ marginTop: "10px", paddingRight: "0 !important", marginRight: "0 !important" }}> */}
            <ul className="d-flex gap-6" style={{ paddingRight: 0, marginRight: 0, paddingTop: '3px' }}>

              <li style={{ marginRight: '-10px' }}>
                <Link to="/vendor_notification">
                  <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static"
                      style={{ backgroundColor: "transparent", boxShadow: "none", }}>
                      {/* <Button onClick={handleOpen}><Badge badgeContent={getRows == '0' ? "0" : getRows }  color="warning"> <NotificationsIcon /> </Badge> </Button> */}
                      <MenuItem onClick={handleOpen}>
                        <IconButton
                          size="large"
                          aria-label="show 17 new notifications"
                          color="inherit"
                        >
                          <Badge badgeContent={getRows == '0' ? "0" : getRows} color="error">
                            <NotificationsIcon />
                          </Badge>
                        </IconButton>

                      </MenuItem>
                    </AppBar>
                  </Box>
                </Link>
              </li>
              <li style={{ margin: '3px 0 5px 0', important: true }}>
                <Link to="/status_change" style={{ flexGrow: 1 }}>
                  <AppBar position="static" style={{ backgroundColor: "transparent", boxShadow: "none" }}>
                    <MenuItem style={{
                      marginTop: "1px"
                    }}>
                      <SettingsIcon />
                    </MenuItem>
                  </AppBar>
                </Link>
              </li>
              {/* <li onClick={handleLogout}>
                <a className="d-flex align-items-center justify-content-center  position-relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#ffffff"
                      d="M12 3.25a.75.75 0 0 1 0 1.5a7.25 7.25 0 0 0 0 14.5a.75.75 0 0 1 0 1.5a8.75 8.75 0 1 1 0-17.5"
                    />
                    <path
                      fill="#ffffff"
                      d="M16.47 9.53a.75.75 0 0 1 1.06-1.06l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H10a.75.75 0 0 1 0-1.5h8.19z"
                    />
                  </svg>
                </a>
              </li> */}
            </ul>
          </section>
        </div>

        <div className="top-border-radius bg-none">
          <div>
            <div>
              <section
                style={{ justifyContent: "center", paddingBottom: "0px" }}
                className="mb-1"
              >
                <div className="custom-container" style={{ margin: "0 auto" }}>
                  <Slider {...settings}>
                    <div>
                      <img
                        src={slider1}
                        alt="color"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={slider2}
                        alt="color"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                        }}
                      />
                    </div>
                  </Slider>
                </div>
              </section>
            </div>

            <div>
              <div className="field-dash">
                <h3
                  style={{
                    fontSize: "14px",
                    paddingTop: "5px",
                  }}
                >
                  Welcome To Vendor Panel
                </h3>
              </div>

              {data && data.show && data.show.is_active === "inactive" ? (
                <div class="container-fluid  mt-3">
                  <div class="row">
                    <div class="col">
                      <div
                        class="kyc-card  app-main-card app-main-card"
                        style={{ padding: "10px" }}
                      >
                        <div class="card-body kyc-image-text-container">
                          <img
                            src={kyc1}
                            alt="KYC Image"
                            class="img-fluid"
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                          />
                          <div style={{ marginLeft: "10px" }}>
                            <h5
                              class="card-title"
                              style={{ fontSize: "medium" }}
                            >
                              KYC Status
                            </h5>
                            <p
                              class="card-text "
                              style={{ fontSize: "smaller", color: "red" }}
                            >
                              Pending
                            </p>
                          </div>
                          <div class="ms-auto" style={{ marginRight: "5px" }}>
                            <button class="btn btn-sm btn-kyc" style={{}}>
                              <Link
                                to="/"
                                style={{
                                  textDecoration: "none",
                                  fontFamily: "auto",
                                }}
                              >
                                Complete{" "}
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" ">
                  <div className="row" style={{ padding: "5px 8px" }}>
                    <div className="col-6">
                      <div
                        onClick={venueList}
                        style={{ textDecoration: "none" }}
                      >
                        <div className=" text-dark  mb-3 app-main-card">
                          <div className="header   d-flex align-items-center">
                            <img
                              src={venuelist}
                              alt="Vendor Image"
                              className="img-fluid mr-3"
                              style={{ maxWidth: "30px" }}
                            />

                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              Venue List
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-6">
                      <Link
                        to={{
                          pathname: "/vendor_addvenue",
                        }}
                        style={{
                          textDecoration: "none",
                          alignItems: "center",
                        }}
                      >
                        <div className=" text-dark  mb-3 app-main-card">
                          <div className="header d-flex align-items-center">
                            <img
                              src={addvenue}
                              alt="Vendor Image"
                              className="img-fluid mr-3"
                              style={{ maxWidth: "30px" }}
                            />

                            <h5
                              className="card-title mb-0"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              Add Venue
                            </h5>
                          </div>
                        </div>
                      </Link>
                    </div> */}

                    {/* <div className="col-6">
                      <div className="text-darke  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={history}
                            alt="Vendor Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />
                          <Link
                            to="/vendor_query"
                            style={{ textDecoration: "none" }}
                          >
                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              Query
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-6" onClick={() => viewslot(data[0].id)}>
                      <div className=" text-dark  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={query}
                            alt="Vendor Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />

                          <h5
                            className="card-text"
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontFamily: "systemui",
                              marginLeft: "10px",
                            }}
                          >
                            {" "}
                            View All Slots
                          </h5>

                        </div>
                      </div>
                    </div>

                    {/* <div className="col-6">
                      <div className="text-darke  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={history}
                            alt="Vendor Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />
                          <Link
                            to="/vendor_rules"
                            style={{ textDecoration: "none" }}
                          >
                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              Add Rules/Terms and Condition
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-6">
                      <div className="text-darke  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={history}
                            alt="Vendor Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />
                          <Link
                            to="/view_rules"
                            style={{ textDecoration: "none" }}
                          >
                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              View Rules/Terms and Condition
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-6">
                      <div className="text-darke  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={reportIcon}
                            alt="Booking Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />
                          <Link
                            to="/vendor_bookings"
                            style={{ textDecoration: "none" }}
                          >
                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              Booking
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>
 */}

                    <div className="col-6">
                      <div className="text-darke  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={EventIcon}
                            alt="Event Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />
                          <Link
                            to="/vendor_event"
                            style={{ textDecoration: "none" }}
                          >
                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              Event
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="text-darke  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={reportIcon}
                            alt="Booking Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />
                          <Link
                            to="/venue_wise_booking"
                            style={{ textDecoration: "none" }}
                          >
                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              Venue Wise Report
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-6" onClick={() => bookSlot(data[0].id)}>
                      <div className=" text-dark  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={query}
                            alt="Vendor Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />

                          <h5
                            className="card-text"
                            style={{
                              color: "black",
                              fontSize: "14px",
                              fontFamily: "systemui",
                              marginLeft: "10px",
                            }}
                          >
                            {" "}
                            View All Slots
                          </h5>

                        </div>
                      </div>
                    </div> */}

                    <div className="col-6">
                      <div className="text-darke  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={addvenue}
                            alt="Event Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />
                          <Link
                            to="/viewVenueBooking"
                            style={{ textDecoration: "none" }}
                          >
                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              Self Slot Booking
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              )}
            </div>
          </div>

        </div>
        {/* Footer */}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            padding: 1,
            display: "flex",
            justifyContent: "space-around",
            boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <Link to="/vendor_dashboard">
            <IconButton>
              <HomeIcon />
            </IconButton>
          </Link>
          <Link to="/vendor_bookings"> {/* Add the booking link */}
            <IconButton>
              <BookIcon /> {/* Use the booking icon */}
            </IconButton>
          </Link>
          <Link to="/vendor_addvenue">
            <IconButton>
              <AddCircleIcon />
            </IconButton>
          </Link>
          <Link to="/vendor_query">
            <IconButton>
              <QuestionAnswerIcon />
            </IconButton>
          </Link>
          <Link to="/vendorUpdate_profile">
            <IconButton>
              <PersonIcon />
            </IconButton>
          </Link>
        </Box>
      </div>
    </>
  );
};



export default VendorDashboard;
