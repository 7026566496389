import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import HomeApp from "./HomeComponent.js/home_app";
import UserSignup from "./Credantails/user_signup";
import UserSignin from "./Credantails/user_signin";
import UpdateProfile from "./FieldsExecutive/update_profile";
import UpdateKyc from "./FieldsExecutive/updatekyc";
import Recommendation from "./HomeComponent.js/recommendation";
import Fields_Executive_Dashboard from "./FieldsExecutive/fields_executive_dashboard";
import AddFieldsData from "./FieldsExecutive/add_fields_data";
import AddExecutiveData from "./FieldsExecutive/add_executive_data";
import ExecutiveQuery from "./FieldsExecutive/executive_query";
import ExecutiveSupport from "./FieldsExecutive/executive_support";
import Notification from "./FieldsExecutive/fe_notification.js";
import History from "./FieldsExecutive/history.js";
import BookSlot from "./slots/book_slots";
import CalendarWithTimeSlots from "./slots/CalendarWithTimeSlots ";
import VendorList from "./Vendor/vendor_list";
import AddVenue from "./Vendor/add_venue";

import Test from "./FieldsExecutive/test";
import UpdateExecutive from "./FieldsExecutive/update_executive";
import VendorSignin from "./Vendor/vendor_signin";
import VendorSignup from "./Vendor/vendor_signup";

import VendorDashboard from "./Vendor/vendor_dashboard";
import VendorUpdateProfile from "./Vendor/vendor_update_profile";
import VendorForgetpassword from "./Vendor/vendor_forgetpassword";
import VendorOtpVerify from "./Vendor/vendor_otpverify";
import VendorVerifyPassword from "./Vendor/vendor_verifypassword";
import VenueList from "./Vendor/venue_list";
import VenueWiseBooking from "./Vendor/venue_wise_booking";
import VenueSlot from "./Vendor/venue_slot";
import VendorAddVenue from "./Vendor/vendor_addvenue";
import UpdateVenueList from "./Vendor/update_venuelist";
import EditExecutive from "./FieldsExecutive/edit_executive";
import SlotsList from "./slots/slots_list";
import UpdateVenue from "./Vendor/update_venue";
import VendorBooking from "./Vendor/bookings";
import VendorRules from "./Vendor/vendor_rules";
import UpdateSlot from "./Vendor/update_slot.js";
import ViewRules from "./Vendor/view_rules";
import VendorEvent from "./Vendor/event";
import AddEvent from "./Vendor/add_event";
import EditEvent from "./Vendor/edit_event";
import BookingRequest from "./Vendor/booking_request";
import Status from "./Vendor/Status";
import TurfView from "./Vendor/turf_view";
import VendorNotification from "./Vendor/vendor_notification.js";
import VendorQuery from "./Vendor/vendor_query";
import VendorSupport from "./Vendor/vendor_support";
import BookingStatus from "./Vendor/booking_status";
import Modal from "./Credantails/modal";
import MyModal from "./Credantails/mymodal";


import VendorVenueList from "./Vendor/vendor_venue_list";
import VendorUpdateVenue from "./Vendor/vendor_update_venue";


import CreateLogin from "./User/create_login";
import UserLogin from "./User/user_login";

import ViewAllSlot from "./Vendor/viewall_slot";
import SelfSlotBook from "./Vendor/self_slot_book";
import BookingDetails from "./Vendor/booking_details.js";
import ViewVenueBooking from "./Vendor/viewVenueBooking.js";
import ExecutiveViewslot from "./FieldsExecutive/executive_viewslot";


import UserRegister from "./User/user_register";

const root = ReactDOM.createRoot(document.getElementById("root"));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/modal" element={<Modal />} />
        <Route path="/mymodal" element={<MyModal />} />
        <Route path="/home_app" element={<HomeApp />} />
        <Route path="/" element={<VendorSignin />} />
        <Route path="/user_signin" element={<UserSignin />} />
        <Route path="/user_signup" element={<UserSignup />} />
        <Route path="/update_profile" element={<UpdateProfile />} />
        <Route path="/updatekyc" element={<UpdateKyc />} />
        <Route path="/recommendation" element={<Recommendation />} />
        <Route
          path="/fields_executive_dashboard"
          element={<Fields_Executive_Dashboard />}
        />
        <Route path="/notification" element={<Notification />} />
        <Route path="/executive_query" element={<ExecutiveQuery />} />
        <Route path="/executive_support" element={<ExecutiveSupport />} />
        <Route path="/add_fields_data" element={<AddFieldsData />} />
        <Route path="/add_executive_data" element={<AddExecutiveData />} />
        <Route path="/fe_history" element={<History />} />
        <Route path="/book_slots" element={<BookSlot />} />
        <Route path="/update_slot" element={<UpdateSlot />} />
        <Route path="/test" element={<Test />} />
        <Route
          path="/CalendarWithTimeSlots"
          element={<CalendarWithTimeSlots />}
        />
        <Route path="/vendor_list" element={<VendorList />} />
        <Route path="/venue_wise_booking" element={<VenueWiseBooking />} />
        <Route path="/vendor_notification" element={<VendorNotification />} />
        <Route path="/add_venue" element={<AddVenue />} />
        <Route path="/update_executive" element={<UpdateExecutive />} />
        <Route path="/edit_executive" element={<EditExecutive />} />
        <Route path="/vendor_signin" element={<VendorSignin />} />
        <Route path="/vendor_signup" element={<VendorSignup />} />
        <Route path="/vendor_dashboard" element={<VendorDashboard />} />
        <Route path="/vendorUpdate_profile" element={<VendorUpdateProfile />} />
        <Route path="/vendor_query" element={<VendorQuery />} />
        <Route path="/vendor_rules" element={<VendorRules />} />
        <Route path="/view_rules" element={<ViewRules />} />
        <Route path="/vendor_support" element={<VendorSupport />} />
        <Route path="/vendor_bookings" element={<VendorBooking />} />
        <Route path="/vendor_event" element={<VendorEvent />} />
        <Route path="/add_event" element={<AddEvent />} />
        <Route path="/edit_event" element={<EditEvent />} />
        <Route path="/booking_request" element={<BookingRequest />} />
        <Route path="/status" element={<Status />} />
        <Route path="/status_change" element={<BookingStatus />} />
        <Route path="/turf_view" element={<TurfView />} />
        <Route
          path="/vendor_forgetpassword"
          element={<VendorForgetpassword />}
        />
        <Route path="/vendor_otpverify" element={<VendorOtpVerify />} />
        <Route
          path="/vendor_verifypassword"
          element={<VendorVerifyPassword />}
        />
        <Route path="/venue_list" element={<VenueList />} />
        <Route path="/venue_slot" element={<VenueSlot />} />
        <Route path="/vendor_addvenue" element={<VendorAddVenue />} />
        <Route path="/update_venuelist" element={<UpdateVenueList />} />
        <Route path="/slots_list" element={<SlotsList />} />
        <Route path="/update_venue" element={<UpdateVenue />} />
        <Route path="/vendor_venue_list" element={<VendorVenueList />} />
        <Route path="/vendor_update_venue" element={<VendorUpdateVenue />} />
        <Route path="/user_login" element={<UserLogin />} />
        <Route path="/create_login" element={<CreateLogin />} />
        <Route path="/viewall_slot" element={<ViewAllSlot />} />
        <Route path="/selfSlotBook" element={<SelfSlotBook />} />
        <Route path="/booking_details" element={<BookingDetails />} />
        <Route path="/viewVenueBooking" element={<ViewVenueBooking />} />

        <Route path="/executive_viewslot" element={<ExecutiveViewslot />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
