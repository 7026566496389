import React, { useEffect, useState } from "react";

import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import details from "../assest/css/home.css";
import chat from '../assest/css/chat.css';
import plus from '../assest/svg/plus-white.svg'
import datetimepicker from "../assest/css/datetimepicker.css";
import avatar from "../assest/images/profile/avatar.png";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import manualcss from '../assest/css/manualcss.css';


import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";

const ExecutiveQuery = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [data, setData] = useState(null);
    const [id, setId] = useState(localStorage.getItem("id"));
    const [con_id, setConId] = useState(localStorage.getItem("con_id"));
    const navigate = useNavigate();

    // const handleSubmission = async (e) => {
    //     e.preventDefault(); // Prevent default form submission behavior

    //     const formData = new FormData();
    //     formData.append("user_id", id);
    //     formData.append("con_id", con_id);
    //     // formData.append("desc", desc);

    //     try {
    //         await fetch(`${apiBaseUrl}/new_chat`, {
    //             method: "POST",
    //             body: formData,
    //         });
    //         const responseData = await response.json();
    //         const newConId = responseData.con_id;

    //         localStorage.setItem("con_id", newConId);
    //         console.log("id created finally:", id);

    //         // Navigate to the executive_support page with con_id
    //         navigate(`/executive_support?id=${newConId}`);

    //     } catch (error) {
    //         console.error("Error submitting data:", error);
    //     }
    // };

    const handleSubmission = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const formData = new FormData();
        formData.append("user_id", id);
        formData.append("con_id", con_id);
        // formData.append("desc", desc);

        try {
            const response = await fetch(`${apiBaseUrl}/new_chat`, {
                method: "POST",
                body: formData,
            });
            const responseData = await response.json();
            const newConId = responseData.con_id;

            localStorage.setItem("con_id", newConId);
            console.log("id created finally:", id);

            // Navigate to the executive_support page with con_id
            navigate(`/executive_support?id=${newConId}`);

        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const id = localStorage.getItem("id");
                const response = await axios.get(`${apiBaseUrl}/view_chat/${id}`);
                if (response.data) {
                    setData(response.data);
                    console.log("dataaaaaa", response);
                } else {
                    navigate("/fields_executive_dashboard");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const token = localStorage.getItem("token");
        console.log("token", token);
        if (token == null) {
            navigate("/user_signin");
        } else {
            fetchData();
        }
    }, [navigate, apiBaseUrl]);

    const editQuery = (query) => {
        navigate(`/update_query`, { state: { data: query } });
    };

    const addQuery = (query) => {
        const encodedId = encodeURIComponent(query.con_id);
        localStorage.setItem("query_con_id", query.con_id);
        navigate(`/add_query?id=${encodedId}`);
    };

    const seeQuery = (query) => {
        const encodedId = encodeURIComponent(query.con_id);
        localStorage.setItem("query_con_id", query.con_id);
        navigate(`/query?id=${encodedId}`);
    };



    console.log(data);

    return (
        <div>
            <>
                <React.Fragment>
                    <main className="tour-guide">
                        <div className="page-title pageHead">
                            <button
                                type="button"
                                className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                            >
                                <Link to="/fields_executive_dashboard">
                                    <img src={arrowleftblack} alt="arrow" />

                                </Link>
                            </button>

                            <h3 className="main-title mainTitleLight">Query List</h3>
                            <Link to="/executive_support">
                                <button
                                    type="button"
                                    onClick={handleSubmission}
                                    // onClick={() => addQuery(query)}
                                    className="add-chat d-flex align-items-center justify-content-center rounded-full"
                                >
                                    <img src={plus} alt="plus" />
                                </button></Link>

                        </div>
                        <section className="budget pt-10">
                            <ul style={{ padding: "0px" }}>
                                {/* item 1 */}

                                {data &&
                                    data.map((query, index) => (
                                        <React.Fragment key={index}>
                                            {/* search end */}
                                            <section className="message mt-15">
                                                {/* item 1 */}
                                                <div className="single-chat position-relative">
                                                    <a
                                                        style={{
                                                            textDecoration: "none",
                                                            backgroundColor: '#ffffff',
                                                            padding: '10px 20px',
                                                            margin: '3px 18px 12px 18px',
                                                            boxShadow: '10px 10px 25px #cfcfcf'
                                                        }}
                                                        className="single-main d-flex align-items-center justify-content-between gap-04 radius-12"
                                                    >
                                                        <div className="shrink-0 d-flex align-items-center gap-2">
                                                            <div className="image shrink-0 position-relative">
                                                                <img
                                                                    src={avatar}
                                                                    alt="Avatar"
                                                                    className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                                                                />
                                                            </div>
                                                            <Link
                                                                to={`/executive_support?id=${query.conversation_id}`}
                                                                style={{ textDecoration: "none" }}
                                                            >
                                                                <div>
                                                                    <h4 style={{ marginBottom: "0" }}>{query.message}</h4>
                                                                    <p className="small">{query.date}</p>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </a>

                                                </div>
                                            </section>

                                        </React.Fragment>

                                    ))}
                            </ul>
                        </section>
                    </main >
                </React.Fragment >
            </>
        </div >
    );
};

export default ExecutiveQuery;
