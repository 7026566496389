import React, { useEffect, useState } from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

const VendorAddVenue = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [gameType, setGameType] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [turfData, setTurfData] = useState({
    venue_name: "",
    venue_sports: [],
    venue_facilities: [],
    venue_image: "",
    vendor_id: localStorage.getItem("id2"),
  });

  const [showPopup, setShowPopup] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchData = async () => {
    try {
      const gameResponse = await axios.get(`${apiBaseUrl}/gametypeData`);
      setGameType(gameResponse.data);
      console.log("gameData", JSON.stringify(gameResponse.data));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      navigate("/vendor_signin");
    } else {
      navigate("/vendor_addvenue");
    }
    fetchData();
  }, [navigate]);

  const handleChange = (event) => {
    if (!event || !event.target) {
      console.error("Event or event.target is undefined");
      return;
    }
    const { name, value, files, type, selectedOptions } = event.target;

    if (type === "file") {
      const selectedFile = files[0];
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
      if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
        setTurfData({
          ...turfData,
          [name]: selectedFile,
        });
      } else {
        alert("Please upload a file with JPEG, PDF, PNG, or JPG format.");
        event.target.value = "";
      }
    } else if (type === "select-multiple") {
      const selectedValues = Array.from(selectedOptions, (option) => option.value);
      setTurfData({
        ...turfData,
        [name]: selectedValues,
      });
    } else {
      setTurfData({
        ...turfData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (e) => {
    const selected = Array.from(e.target.selectedOptions, (option) => option.value);
    const { name } = e.target;
    setTurfData({
      ...turfData,
      [name]: selected,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in turfData) {
        formDataToSend.append(key, turfData[key]);
      }

      const response = await axios.post(`${apiBaseUrl}/create_venue`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate("/vendor_dashboard");
      }, 3000);
    } catch (error) {
      console.log("Error while creating account", error);
    }
  };

  const back = () => {
    navigate("/vendor_dashboard");
  };

  return (
    <div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            Form submitted successfully!
          </div>
        </div>
      )}
      <main>
        <section className="auth signin">
          <div className="page-title pageHead">
            <button
              type="button"
              className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
              onClick={back}
            >
              <img src={arrowleftblack} alt="arrow" />
            </button>
            <h3 className="main-title mainTitleLight">Add Venue</h3>
          </div>

          <div className="form-area auth-form" style={{ height: "80vh" }}>
            <form onSubmit={handleSubmit}>
              <div className="icon-container">
                <div>
                  <label htmlFor="venue_name">Enter Venue Name</label>
                  <input
                    type="text"
                    id="venue_name"
                    name="venue_name"
                    className="input-field"
                    placeholder="Enter Venue Name"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="venue_sports">Select Sports Type</label>
                  <div className="position-relative">
                    <select
                      multiple
                      id="venue_sports"
                      name="venue_sports"
                      className="input-field"
                      value={turfData.venue_sports}
                      onChange={handleSelectChange}
                    >
                      {gameType && gameType.gameTypes
                        ? gameType.gameTypes.map((game, index) => (
                          <option key={index} value={game}>
                            {game}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                </div>

                <div>
                  <label htmlFor="venue_facilities">Select Facilities</label>
                  <select
                    multiple
                    id="venue_facilities"
                    name="venue_facilities"
                    className="input-field"
                    value={turfData.venue_facilities}
                    onChange={handleSelectChange}
                  >
                    {gameType && gameType.facilities
                      ? gameType.facilities.map((facility, index) => (
                        <option key={index} value={facility}>
                          {facility}
                        </option>
                      ))
                      : null}
                  </select>
                </div>

                <div>
                  <label htmlFor="venue_image">Add Venue Image</label>
                  <input
                    type="file"
                    id="venue_image"
                    name="venue_image"
                    className="input-field"
                    placeholder="Venue Image"
                    style={{ padding: "8px" }}
                    onChange={handleChange}
                  />
                </div>

                <button className="btn-primary" style={{ marginTop: "15px" }}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>

      <style jsx>{`
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .popup-content {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          font-size: 1.5rem;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default VendorAddVenue;




// import React, { useEffect, useState } from "react";
// import bootstrapcss from "../assest/css/bootstrap.min.css";
// import swipercss from "../assest/css/swiper-bundle.min.css";
// import jquerycss from "../assest/css/jquery-ui.min.css";
// import commoncss from "../assest/css/common.css";
// import welcomecss from "../assest/css/welcome.css";
// import animationscss from "../assest/css/animations.css";
// import authcss from "../assest/css/auth.css";
// import arrowleftblack from "../assest/svg/arrow-left-black.svg";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import Select from "react-select";

// const VendorAddVenue = () => {
//   const navigate = useNavigate();
//   const [data, setData] = useState(null);
//   const [gameType, setGameType] = useState(null);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [turfData, setTurfData] = useState({
//     venue_name: "",
//     venue_sports: [],
//     venue_facilities: [],
//     venue_image: "",
//     vendor_id: localStorage.getItem("id2"),
//   });

//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

//   const fetchData = async () => {
//     try {
//       const gameResponse = await axios.get(`${apiBaseUrl}/gametypeData`);
//       setGameType(gameResponse.data);
//       console.log("gameData", JSON.stringify(gameResponse.data));
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (token === null) {
//       navigate("/vendor_signin");
//     } else {
//       navigate("/vendor_addvenue");
//     }
//     fetchData();
//   }, [navigate]);

//   const handleChange = (event) => {
//     if (!event || !event.target) {
//       console.error("Event or event.target is undefined");
//       return;
//     }
//     const { name, value, files, type, selectedOptions } = event.target;

//     if (type === "file") {
//       const selectedFile = files[0];
//       const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
//       if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
//         setTurfData({
//           ...turfData,
//           [name]: selectedFile,
//         });
//       } else {
//         alert("Please upload a file with JPEG, PDF, PNG, or JPG format.");
//         event.target.value = "";
//       }
//     } else if (type === "select-multiple") {
//       const selectedValues = Array.from(selectedOptions, (option) => option.value);
//       setTurfData({
//         ...turfData,
//         [name]: selectedValues,
//       });
//     } else {
//       setTurfData({
//         ...turfData,
//         [name]: value,
//       });
//     }
//   };

//   const handleSelectChange = (e) => {
//     const selected = Array.from(e.target.selectedOptions, (option) => option.value);
//     const { name } = e.target;
//     setTurfData({
//       ...turfData,
//       [name]: selected,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formDataToSend = new FormData();
//       for (const key in turfData) {
//         formDataToSend.append(key, turfData[key]);
//       }

//       const response = await axios.post(`${apiBaseUrl}/create_venue`, formDataToSend, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       navigate("/vendor_dashboard");
//     } catch (error) {
//       console.log("Error while creating account", error);
//     }
//   };

//   const back = () => {
//     navigate("/vendor_dashboard");
//   };

//   return (
//     <div>
//       <main>
//         <section className="auth signin">
//           <div className="page-title pageHead">
//             <button
//               type="button"
//               className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
//               onClick={back}
//             >
//               <img src={arrowleftblack} alt="arrow" />
//             </button>
//             <h3 className="main-title mainTitleLight">Add Venue</h3>
//           </div>

//           <div className="form-area auth-form" style={{ height: "80vh" }}>
//             <form onSubmit={handleSubmit}>
//               <div className="icon-container">
//                 <div>
//                   <label htmlFor="venue_name">Enter Venue Name</label>
//                   <input
//                     type="text"
//                     id="venue_name"
//                     name="venue_name"
//                     className="input-field"
//                     placeholder="Enter Venue Name"
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div>
//                   <label htmlFor="venue_sports">Select Sports Type</label>
//                   <div className="position-relative">
//                     <select
//                       multiple
//                       id="venue_sports"
//                       name="venue_sports"
//                       className="input-field"
//                       value={turfData.venue_sports}
//                       onChange={handleSelectChange}
//                     >
//                       {gameType && gameType.gameTypes
//                         ? gameType.gameTypes.map((game, index) => (
//                           <option key={index} value={game}>
//                             {game}
//                           </option>
//                         ))
//                         : null}
//                     </select>
//                   </div>
//                 </div>

//                 <div>
//                   <label htmlFor="venue_facilities">Select Facilities</label>
//                   <select
//                     multiple
//                     id="venue_facilities"
//                     name="venue_facilities"
//                     className="input-field"
//                     value={turfData.venue_facilities}
//                     onChange={handleSelectChange}
//                   >
//                     {gameType && gameType.facilities
//                       ? gameType.facilities.map((facility, index) => (
//                         <option key={index} value={facility}>
//                           {facility}
//                         </option>
//                       ))
//                       : null}
//                   </select>
//                 </div>

//                 <div>
//                   <label htmlFor="venue_image">Add Venue Image</label>
//                   <input
//                     type="file"
//                     id="venue_image"
//                     name="venue_image"
//                     className="input-field"
//                     placeholder="Venue Image"
//                     style={{ padding: "8px" }}
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <button className="btn-primary" style={{ marginTop: "15px" }}>
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </section>
//       </main>
//     </div>
//   );
// };

// export default VendorAddVenue;



// import React, { useEffect, useState } from "react";
// import bootstrapcss from "../assest/css/bootstrap.min.css";
// import swipercss from "../assest/css/swiper-bundle.min.css";
// import jquerycss from "../assest/css/jquery-ui.min.css";
// import commoncss from "../assest/css/common.css";
// import welcomecss from "../assest/css/welcome.css";
// import animationscss from "../assest/css/animations.css";
// import authcss from "../assest/css/auth.css";
// import arrowleftblack from "../assest/svg/arrow-left-black.svg";
// import { json, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Select from "react-select";

// const VendorAddVenue = () => {


//   const back = () => {
//     navigate("/vendor_dashboard");
//   };

//   const [data, setData] = useState(null);
//   const [gameType, setGameType] = useState(null);

//   const id2 = localStorage.getItem("id2");
//   console.log("idddddddd", id2)

//   const venId = 14;
//   const [turfData, setTurfData] = useState({
//     venue_name: "",
//     venue_sports: [],
//     venue_facilities: [],
//     venue_image: "",
//     vendor_id: id2,
//   });





//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
//   const navigate = useNavigate();

//   const fetchData = async () => {
//     try {


//       // const id = localStorage.getItem("id");
//       // const response = await axios.get(`${apiBaseUrl}/showpageapi/${id}`);
//       // setData(response.data);

//       const gameResponse = await axios.get(`${apiBaseUrl}/gametypeData`);
//       setGameType(gameResponse.data);
//       console.log("gameData", JSON.stringify(gameResponse.data));

//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (token === null) {
//       navigate("/vendor_signin");
//     } else {
//       navigate("/vendor_addvenue");
//     }
//     fetchData();
//   }, []);

//   const handleChange = (event) => {
//     if (!event || !event.target) {
//       // If event or event.target is undefined, return early or handle the error
//       console.error("Event or event.target is undefined");
//       return;
//     }
//     const { name, value, files, type, selectedOptions } = event.target;

//     if (type === "select-multiple") {
//       // Handle multi-select change for sports and facilities
//       const selectedValues = Array.from(
//         selectedOptions || [],
//         (option) => option.value
//       );
//       setTurfData({
//         ...turfData,
//         [name]: selectedValues,
//       });
//     }
//     else if (name === "venue_image") {
//       // Handle file input
//       const selectedFile = files[0]; // Use files instead of selectedOptions
//       const allowedFileTypes = [
//         "image/jpeg",
//         "image/png",
//         "image/jpg",
//         "application/pdf",
//       ];
//       if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
//         // If the file type is allowed, update the form data with the file
//         setTurfData({
//           ...turfData,
//           [name]: selectedFile,
//         });
//       } else {
//         alert("Please upload a file with JPEG, PDF, PNG, or JPG format.");
//         // If the file type is not allowed, reset the file input
//         event.target.value = "";
//         console.log("Invalid file type");
//       }
//     } else if (name === "venue_sports" || name === "venue_facilities") {
//       // Handle multi-select change for sports and facilities
//       const selectedValues = Array.from(
//         selectedOptions || [],
//         (option) => option.value
//       );
//       setTurfData({
//         ...turfData,
//         [name]: selectedValues,
//       });
//     } else {
//       // For other inputs, update the form data with the input value
//       setTurfData({
//         ...turfData,
//         [name]: value,
//       });
//     }
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const formDataToSend = new FormData();
//       for (const key in turfData) {
//         formDataToSend.append(key, turfData[key]);
//       }

//       for (let [key, value] of formDataToSend.entries()) {
//         console.log(key + ': ' + value);
//       }


//       console.log("hhadjd", turfData);
//       const response = await axios.post(
//         `${apiBaseUrl}/create_venue`,
//         formDataToSend,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       navigate("/vendor_dashboard");
//     } catch (error) {
//       console.log("Error while creating account", error);
//     }
//   };

//   return (
//     <div>
//       <main>
//         <section className="auth signin">
//           <div className="page-title pageHead">
//             <button
//               type="button"
//               className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
//               onClick={back}
//             >

//               <img src={arrowleftblack} alt="arrow" />

//             </button>
//             <h3 className="main-title mainTitleLight">Add Venue</h3>
//           </div>

//           <div className="form-area auth-form" style={{ height: "80vh" }}>
//             <form onSubmit={handleSubmit}>
//               <div className="icon-container">
//                 <div>
//                   <label htmlFor="venue_name">Enter Venue Name</label>
//                   <input
//                     type="text"
//                     id="venue_name"
//                     name="venue_name"
//                     className="input-field"
//                     placeholder="Enter Venue Name"
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div>
//                   <label htmlFor="venue_sports">Select Sports Type</label>
//                   <div className="position-relative">
//                     <Select
//                       id="venue_sports"
//                       name="venue_sports"
//                       className="input-field"
//                       options={
//                         gameType && gameType.gameTypes
//                           ? gameType.gameTypes.map((game) => ({
//                             label: game,
//                             value: game,
//                           }))
//                           : []
//                       }
//                       isMulti
//                       onChange={(selectedOptions) =>
//                         handleChange({
//                           target: { name: "venue_sports", selectedOptions },
//                         })
//                       }
//                     />
//                   </div>
//                 </div>

//                 <div>
//                   <label htmlFor="venue_facilities">Select Facilities</label>
//                   <Select
//                     id="venue_facilities"
//                     name="venue_facilities"
//                     className="input-field"
//                     options={
//                       gameType && gameType.facilities
//                         ? gameType.facilities.map((facility) => ({
//                           label: facility,
//                           value: facility,
//                         }))
//                         : []
//                     }
//                     isMulti
//                     onChange={(selectedOptions) =>
//                       handleChange({
//                         target: { name: "venue_facilities", selectedOptions },
//                       })
//                     }
//                   />
//                 </div>

//                 <div>
//                   <label htmlFor="venue_image">Add Venue Image</label>
//                   <input
//                     type="file"
//                     id="venue_image"
//                     name="venue_image"
//                     className="input-field"
//                     placeholder="Venue Image"
//                     style={{ padding: "8px" }}
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <button className="btn-primary" style={{ marginTop: "15px" }}>
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </section>
//       </main>
//     </div>
//   );
// };

// export default VendorAddVenue;