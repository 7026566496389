import React, { useEffect, useState } from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import eyeon from "../assest/svg/eye-on.svg";
import eyeoff from "../assest/svg/eye-off.svg";
import icongoogle from "../assest/svg/icon-google.svg";
import iconapple from "../assest/svg/icon-apple.svg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import otp from "../assest/homeimage/otp.png";

import axios from "axios";

import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import Modal from "../Credantails/modal";


const VendorOtpVerify = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const otp = queryParams.get("otp"); // Change 'email' to 'otp' here
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);


  const [formData, setFormData] = useState({
    email: email,
    otp: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // If the input is a file input, set the file to the image field
    setFormData({ ...formData, [name]: value });
    console.log("Updated FormData:", { ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      localStorage.setItem(
        "modalMessage2",
        JSON.stringify({
          title: "OTP Verifed Successfully",
          content: "Reset your password you have enter OTP successfully",
        })
      );

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      console.log("FormData to send:", formDataToSend); // Log the formDataToSend
      await axios.post(`${apiBaseUrl}/vendor_verifyotp`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type to multipart/form-data for file upload
        },
      });

      console.log("OTP verified successfully:", formData);
      navigate(`/vendor_verifypassword?email=${encodeURIComponent(email)}`);
      // You can add any further actions here, like redirecting the user or showing a success message
    } catch (error) {
      console.error("Error OTP Not Verified:", error);
      setModalMessage({
        title: "Error",
        content: "OTP not vaild. Please enter vaild otp.",
      });
      setShowModal(true);

      // Handle error, show error message, etc.
    }
  };

  const [modalMessage, setModalMessage] = useState(null);

  useEffect(() => {

    const storedMessage = localStorage.getItem("modalMessage");
    if (storedMessage) {
      setModalMessage(JSON.parse(storedMessage));
      // Remove modal message from local storage after retrieving
      localStorage.removeItem("modalMessage");
    }
  }, []);


  const closeModal = () => {
    setShowModal(false);
  };

  const back = () => {
    navigate("/vendor_signin");
  };
  return (
    <div>
      <>
        {modalMessage && (
          <Modal
            message={modalMessage}
            closeModal={() => setModalMessage(null)}
          />
        )}
        <main>

          <section className="auth signin">
            <div className="page-title pageHead">
              <button
                onClick={back}
                type="button"
                className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
              >
                <img src={arrowleftblack} alt="arrow" />
              </button>
              <h3 className="main-title mainTitleLight">Enter Otp</h3>
            </div>

            <div className="form-area auth-form" style={{ height: '80vh' }}>
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="lemail1">Otp </label>
                  <input
                    type="password"
                    id="otp"
                    name="otp"
                    placeholder="Enter your Otp "
                    className="input-field"
                    onChange={handleChange}
                  />
                </div>

                <input value="login" type="submit" className="btn-primary" />
              </form>

              <h6>
                {" "}
                Back to Login Page? <Link to="/vendor_signup">Login</Link>
              </h6>
            </div>
          </section>
        </main>
      </>
    </div>
  );
};



export default VendorOtpVerify;