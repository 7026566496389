import React, { useEffect, useState } from "react";

import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import details from "../assest/css/home.css";
import chat from '../assest/css/chat.css';
import plus from '../assest/svg/plus-white.svg'
import datetimepicker from "../assest/css/datetimepicker.css";
import avatar from "../assest/images/profile/avatar.png";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import manualcss from '../assest/css/manualcss.css';
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";

const VendorList = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = localStorage.getItem("id");
        const response = await axios.get(`${apiBaseUrl}/vendor_view/${id}`);
        if (response.data) {
          setData(response.data);
          console.log("dataaaaaa", response);
        } else {
          navigate("/fields_executive_dashboard");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const token = localStorage.getItem("token");
    console.log("token", token);
    if (token == null) {
      navigate("/user_signin");
    } else {
      fetchData();
    }
  }, [navigate, apiBaseUrl]);




  const edit = (vendor) => {
    navigate(`/update_executive`, { state: { data: vendor } });
  };

  const addVenue = (vendor) => {
    const encodedId = encodeURIComponent(vendor.id);
    localStorage.setItem("vendor_id", vendor.id);
    navigate(`/add_venue?id=${encodedId}`);
  };


  const seeVenue = (vendor) => {
    const encodeId = encodeURIComponent(vendor.id);
    localStorage.setItem("vendor_id", vendor.id);
    navigate(`/venue_list?id=${encodeId}`);

  }


  const back = () => {

    navigate("/fields_executive_dashboard");
  }

  console.log(data);

  return (
    <div>
      <>
        <main className="tour-guide">
          <div className="page-title pageHead">
            <button
              onClick={back}
              type="button"
              className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >

              <img src={arrowleftblack} alt="arrow" />

            </button>

            <h3 className="main-title mainTitleLight">Vendor List</h3>
          </div>
          <section className="budget">
            <ul style={{ padding: "0px" }}>
              {/* item 1 */}

              {data &&
                data.map((vendor, index) => (
                  <React.Fragment key={index}>
                    {/* search end */}
                    <section className="message mt-15">
                      {/* item 1 */}
                      <div className="single-chat position-relative">
                        <a
                          style={{ textDecoration: "none", margin: '5px 10px 10px 10px', border: '1px solid #e3e3e3', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                          className="single-main d-flex align-items-center justify-content-between radius-12"
                        >
                          <div className="shrink-0 d-flex align-items-center gap-2">
                            <div className="image shrink-0 position-relative">
                              <img
                                src={avatar}
                                alt="Avatar"
                                className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                              />
                              {/* <small className="active-dot" /> */}
                            </div>
                            <div className="list-container" style={{ width: '180px', marginRight: '3px' }}>
                              <h4 style={{ fontSize: "15px" }}>{vendor.business_name}</h4>
                              <p>{vendor.owner_name}</p>
                            </div>
                          </div>

                          <div className="text-end">
                            <h5 className="pb-2" style={{ marginBottom: "0" }}>
                              <span
                                className="d-inline-block text-center"
                                onClick={() => edit(vendor)}
                                style={{
                                  width: "80px",
                                  borderRadius: "2px",
                                  padding: "0px 2px 2px 2px",
                                  textAlign: "center",
                                  color: "white",
                                  backgroundColor: "rgb(14 121 93)",
                                }}
                              >
                                <a>Edit Vendor</a>
                              </span>
                            </h5>
                            <span
                              className="d-inline-block text-center"
                              onClick={() => seeVenue(vendor)}
                              style={{
                                width: "80px",
                                borderRadius: "2px",
                                padding: "0px 2px 2px 2px",
                                textAlign: "center",
                                backgroundColor: "#fff",
                                border: "1px solid #0e795d",
                                color: "#0e795d",
                              }}
                            >
                              <a>Venues</a>
                            </span>
                          </div>
                        </a>
                      </div>
                    </section>

                  </React.Fragment>
                ))}
            </ul>

          </section>

        </main>
      </>
    </div>
  );
};

export default VendorList;

