import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import details from "../assest/css/home.css";
import chat from '../assest/css/chat.css';
import datetimepicker from "../assest/css/datetimepicker.css";
import avatar from "../assest/images/profile/avatar.png";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import plus from '../assest/svg/plus-white.svg';
import manualcss from '../assest/css/manualcss.css';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BookIcon from '@mui/icons-material/Book'; // Import the booking icon

const VendorQuery = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    const handleSubmission = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const id2 = localStorage.getItem('id2');
        const formData = new FormData();
        formData.append("vendor_id", id2);

        try {
            const response = await fetch(`${apiBaseUrl}/newChat`, {
                method: "POST",
                body: formData,
            });
            const responseData = await response.json();
            const newConId = responseData.con_id;

            localStorage.setItem("con_id", newConId);

            // Navigate to the vendor_support page with con_id
            navigate(`/vendor_support?id=${newConId}`);
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const id2 = localStorage.getItem("id2");
                const response = await axios.get(`${apiBaseUrl}/viewQuery/${id2}`);
                if (response.data) {
                    setData(response.data);
                } else {
                    navigate("/vendor_dashboard");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/vendor_signin");
        } else {
            fetchData();
        }
    }, [navigate, apiBaseUrl]);

    return (
        <div>
            <React.Fragment style={{ marginBottom: "80px" }}>
                <main className="tour-guide ">
                    <div className="page-title pageHead">
                        <button
                            type="button"
                            className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"

                        >
                            <Link to="/vendor_dashboard">
                                <img src={arrowleftblack} alt="arrow" />
                            </Link>
                        </button>
                        <h3 className="main-title mainTitleLight">Query List</h3>
                        <Link to="/vendor_support">
                            <button
                                type="button"
                                onClick={handleSubmission}
                                className="add-chat d-flex align-items-center justify-content-center rounded-full"
                            >
                                <img src={plus} alt="plus" />
                            </button>
                        </Link>
                    </div>
                    <section className="budget pt-10">
                        <ul style={{ padding: "0px" }}>
                            {data &&
                                data.map((query, index) => (
                                    <React.Fragment key={index}>
                                        <section className="message mt-15">
                                            <div className="single-chat position-relative">
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                        backgroundColor: '#ffffff',
                                                        padding: '10px 20px',
                                                        margin: '3px 18px 12px 18px',
                                                        boxShadow: '10px 10px 25px #cfcfcf'
                                                    }}
                                                    className="query_list single-main d-flex align-items-center justify-content-between gap-04 radius-12"
                                                >
                                                    <div className="shrink-0 d-flex align-items-center gap-2">
                                                        <div className="image shrink-0 position-relative">
                                                            <img
                                                                src={avatar}
                                                                alt="Avatar"
                                                                className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                                                            />
                                                        </div>
                                                        <Link
                                                            to={`/vendor_support?id=${query.conversation_id}`}
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            <div>
                                                                <p>{query.message}</p>
                                                                <p className="small">{query.date}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </a>
                                            </div>
                                        </section>
                                    </React.Fragment>
                                ))}
                        </ul>
                    </section>
                </main>
            </React.Fragment>
            {/* Footer */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "#fff",
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-around",
                    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                }}
            >
                <Link to="/vendor_dashboard">
                    <IconButton>
                        <HomeIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_bookings"> {/* Add the booking link */}
                    <IconButton>
                        <BookIcon /> {/* Use the booking icon */}
                    </IconButton>
                </Link>
                <Link to="/vendor_addvenue">
                    <IconButton>
                        <AddCircleIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_query">
                    <IconButton>
                        <QuestionAnswerIcon />
                    </IconButton>
                </Link>
                <Link to="/vendorUpdate_profile">
                    <IconButton>
                        <PersonIcon />
                    </IconButton>
                </Link>
            </Box>
        </div>
    );
};

export default VendorQuery;
