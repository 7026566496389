import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import bootstrapcss from '../assest/css/bootstrap.min.css';
import swipercss from '../assest/css/swiper-bundle.min.css';
import jquerycss from '../assest/css/jquery-ui.min.css';
import commoncss from '../assest/css/common.css';
import welcomecss from '../assest/css/welcome.css';
import animationscss from '../assest/css/animations.css';
import authcss from '../assest/css/auth.css';
import buttonswitch from '../assest/css/buttonswitch.css';
import skillintrest from '../assest/css/skillintrest.css';
import venuecss from '../assest/css/venue.css';
import x from '../assest/images/profile/close.png';
import firstaid from '../assest/svg/facility/firstaid.svg';
import frestroom from '../assest/svg/facility/frestroom.svg';
import fhotel from '../assest/svg/facility/fhotel.svg';
import fparking from '../assest/svg/facility/fparking.svg';
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import eventCSS from '../assest/css/event.css';

const EditEvent = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [flashMessage, setFlashMessage] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const eventId = searchParams.get("id");
    const navigate = useNavigate();
    const [loadData, setLoadData] = useState(null);
    const [turfData, setTurfData] = useState({
        title: "",
        description: "",
        date: "",
        start_time: "",
        end_time: "",
        price: "",
        location: "",
        locationLink: "",
        banner: "",
    });

    const loadList = async () => {
        try {
            const eventId = localStorage.getItem('id');
            const response = await axios.get(`${apiBaseUrl}/viewParticularEvent/${eventId}`);
            const data = response.data;
            console.log("Fetched data:", data); // Log the entire response data
            setLoadData(data);
            if (data && data.ParticularEventDetails && data.ParticularEventDetails.length > 0) {
                const ParticularEventDetails = data.ParticularEventDetails[0];
                setTurfData({
                    title: ParticularEventDetails.title || "",
                    description: ParticularEventDetails.description || "",
                    date: ParticularEventDetails.date ? ParticularEventDetails.date.split('T')[0] : "",
                    start_time: ParticularEventDetails.start_time || "",
                    end_time: ParticularEventDetails.end_time || "",
                    price: ParticularEventDetails.price || "",
                    location: ParticularEventDetails.location || "",
                    locationLink: ParticularEventDetails.locationLink || "",
                    banner: ParticularEventDetails.images || "",
                });
            } else {
                console.error("No event details found");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/user_sign');
        } else {
            loadList();
        }
    }, [eventId, navigate, apiBaseUrl]);

    useEffect(() => {
        console.log("Updated turfData:", turfData);
    }, [turfData]);

    const handleChange = (event) => {
        const { name, value, files, type } = event.target;
        if (type === "file") {
            const selectedFile = files[0];
            const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
            if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
                setTurfData({
                    ...turfData,
                    [name]: selectedFile,
                });
            } else {
                alert("Please upload a file with JPEG, PDF, PNG, or JPG format.");
                event.target.value = "";
            }
        } else {
            setTurfData({
                ...turfData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            for (const key in turfData) {
                formDataToSend.append(key, turfData[key]);
            }
            const eventId = localStorage.getItem('id');
            await axios.post(`${apiBaseUrl}/editEvent/${eventId}`, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            // Show flash message
            setFlashMessage(true);

            // Hide flash message after 3 seconds and navigate
            setTimeout(() => {
                setFlashMessage(false);
                navigate("/vendor_event");
            }, 3000);
        } catch (error) {
            console.log("Error while creating account", error);
        }
    };

    return (
        <div>
            {flashMessage && (
                <div className="popup">
                    <div className="popup-content">
                        Form submitted successfully!
                    </div>
                </div>
            )}
            <main>
                <section className="auth signin">
                    <div className="page-title pageHead">
                        <button
                            type="button"
                            className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                        >
                            <Link to="/vendor_event">
                                <img src={arrowleftblack} alt="arrow" />
                            </Link>

                        </button>
                        <h3 className="main-title mainTitleLight">Edit Event</h3>
                    </div>
                    {loadData && (
                        <div className="form-area auth-form">
                            <form onSubmit={handleSubmit}>
                                <div className="icon-container">
                                    <div>
                                        <label htmlFor="title">Event Title</label>
                                        <input
                                            type="text"
                                            id="title"
                                            name="title"
                                            className="input-field"
                                            placeholder="Enter the Event Title"
                                            value={turfData.title}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="description">Event Description</label>
                                        <input
                                            type="text"
                                            id="description"
                                            name="description"
                                            className="input-field"
                                            placeholder="Enter the Event Description"
                                            value={turfData.description}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="banner">Event Banner</label>
                                        <input
                                            type="file"
                                            id="banner"
                                            name="banner"
                                            className="input-field"
                                            style={{ padding: "8px" }}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="date">Event Date</label>
                                        <input
                                            type="date"
                                            id="date"
                                            name="date"
                                            className="input-field"
                                            value={turfData.date}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="start_time">Event Start Time</label>
                                        <input
                                            type="time"
                                            id="start_time"
                                            name="start_time"
                                            className="input-field"
                                            value={turfData.start_time}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="end_time">Event End Time</label>
                                        <input
                                            type="time"
                                            id="end_time"
                                            name="end_time"
                                            className="input-field"
                                            value={turfData.end_time}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="price">Event Price</label>
                                        <input
                                            type="text"
                                            id="price"
                                            name="price"
                                            className="input-field"
                                            placeholder="Enter Your Event Price"
                                            value={turfData.price}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="location">Event Location</label>
                                        <input
                                            type="text"
                                            id="location"
                                            name="location"
                                            className="input-field"
                                            placeholder="Enter Your Event Location"
                                            value={turfData.location}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="locationLink">Event Location Link</label>
                                        <input
                                            type="text"
                                            id="locationLink"
                                            name="locationLink"
                                            className="input-field"
                                            placeholder="Enter Your Event Location Link. eg. google map"
                                            value={turfData.locationLink}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <button className="btn-primary" style={{ marginTop: "15px" }}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </section>
            </main>
            <style jsx>{`
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .popup-content {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          font-size: 1.5rem;
          text-align: center;
        }
      `}</style>
        </div>
    );
};

export default EditEvent;

