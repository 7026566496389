import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import "../assest/assets-demo/css/style.css";
import "../App.css";
import "../assest/css/home.css";
import "../assest/css/welcome.css";
import "../assest/css/common.css";
import "../assest/css/jquery-ui.min.css";
import "../assest/css/swiper-bundle.min.css";
import "../assest/css/bootstrap.min.css";
import "../assest/css/ticket.css";
import "../assest/css/manualcss.css";

const SelfSlotBook = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const venueIddddid = searchParams.get("id");

    const [data, setData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dates, setDates] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const id2 = localStorage.getItem("id2");

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async (date) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/viewNotBookSlots/${venueIddddid}?date=${date}`);
            setData(response.data);
            console.log("data", response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("token", token);
        if (token == null) {
            navigate("/vendor_dashboard");
        }
        fetchData(formatDate(selectedDate));
    }, [selectedDate]);

    useEffect(() => {
        const generateDates = () => {
            const datesArray = [];
            const today = new Date();
            for (let i = 0; i < 30; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() + i);
                datesArray.push(date);
            }
            setDates(datesArray);
        };

        generateDates();
    }, []);

    const back = () => {
        navigate("/viewVenueBooking");
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        fetchData(formatDate(date));
    };

    const handleSlotSelect = (slotId) => {
        setSelectedSlots(prevSelectedSlots =>
            prevSelectedSlots.includes(slotId)
                ? prevSelectedSlots.filter(id => id !== slotId)
                : [...prevSelectedSlots, slotId]
        );
    };

    const handleNext = async () => {
        const token = localStorage.getItem("token");
        const bookingDetails = {
            vendor_id: id2,
            venue_id: venueIddddid,
            slot_id: selectedSlots,
            booking_date: formatDate(selectedDate)
        };
        try {
            const response = await axios.post(`${apiBaseUrl}/storeBooking`, bookingDetails, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const bookingCode = response.data.booking_code;
            navigate(`/booking_details?booking_code=${bookingCode}`);
            // navigate("/booking_details");
        } catch (error) {
            console.error("Error booking slots:", error);
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentMonth = monthNames[selectedDate.getMonth()];
    const currentYear = selectedDate.getFullYear();

    return (
        <div>
            <section className="budget">
                <div className="page-title pageHead">
                    <button
                        onClick={back}
                        type="button"
                        className="white_button btn back-page-btn h-80 w-60 d-flex align-items-center justify-content-center rounded-full"
                    >
                        <img src={arrowleftblack} alt="arrow" />
                    </button>
                    <h3 className="main-title mainTitleLight">Available Slots</h3>
                </div>
                <div className="calendar-header">
                    <h3 style={{ textAlign: 'center' }}>{currentMonth} {currentYear}</h3>
                </div>
                <div className="date-list-container">
                    <div className="date-list">
                        {dates.map((date) => (
                            <button
                                key={date.toISOString()}
                                onClick={() => handleDateChange(date)}
                                className={`date-item ${selectedDate.toDateString() === date.toDateString() ? 'selected' : ''}`}
                            >
                                {date.toLocaleDateString('en-US', { weekday: 'short', day: '2-digit' })}
                            </button>
                        ))}
                    </div>
                </div>

                {data && data.slots && (
                    <div className="slot-container">
                        {data.slots.map((row) => (
                            <div key={row.id} className="another-chat position-relative">
                                <section
                                    className={`another-main d-flex align-items-center justify-content-between radius-12 ${selectedSlots.includes(row.id) ? 'selected' : ''}`}
                                    onClick={() => handleSlotSelect(row.id)}
                                >
                                    <div className="list-chat-book">
                                        <p style={{ fontSize: '20px' }}>{row.slot_start_time} - {row.slot_end_time}</p>
                                        <span className="text-end" style={{ paddingTop: '2px' }}>Rs.{row.price}</span>
                                    </div>
                                </section>
                            </div>
                        ))}
                    </div>
                )}

                {selectedSlots.length > 0 && (
                    <button onClick={handleNext} className="next-button">
                        Next
                    </button>
                )}
            </section>
        </div>
    );
};

export default SelfSlotBook;
