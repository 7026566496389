import React, { useEffect, useState } from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import axios from "axios";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import Select from "react-select";

const VendorUpdateVenue = () => {
  const back = () => {
    navigate("/vendor_venue_list");
  };

  const location = useLocation();
  const responseData = location?.state?.venueData;
  const venueId = responseData?.id;

  const [data, setData] = useState(null);
  const [gameType, setGameType] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const [turfData, setTurfData] = useState({
    turfname: responseData?.name || "",
    facilities: responseData?.facilities
      ? Array.isArray(responseData.facilities)
        ? responseData.facilities
        : [responseData.facilities]
      : [],
    turfsports: responseData?.game_names
      ? Array.isArray(responseData.game_names)
        ? responseData.game_names
        : [responseData.game_names]
      : [],
    venue_image: responseData?.images || "",
    venueId: venueId,
  });

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const gameResponse = await axios.get(`${apiBaseUrl}/gametypeData`);
      setGameType(gameResponse.data);
      const response = await axios.get(`${apiBaseUrl}/update_Venue/${venueId}`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/user_sign");
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    if (!event || !event.target) {
      console.error("Event or event.target is undefined");
      return;
    }

    const { name, value, files, type, selectedOptions } = event.target;

    if (type === "select-multiple") {
      const selectedValues = Array.from(
        selectedOptions || [],
        (option) => option.value
      );
      setTurfData({
        ...turfData,
        [name]: selectedValues,
      });
    } else if (name === "venue_image") {
      const selectedFile = files[0];
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
        setTurfData({
          ...turfData,
          [name]: selectedFile,
        });
      } else {
        alert("Please upload a file with JPEG, PDF, PNG, or JPG format.");
        event.target.value = "";
        console.log("Invalid file type");
      }
    } else if (name === "turfsports" || name === "facilities") {
      const selectedValues = Array.from(
        selectedOptions || [],
        (option) => option.value
      );
      setTurfData({
        ...turfData,
        [name]: selectedValues,
      });
    } else {
      setTurfData({
        ...turfData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (e, fieldName) => {
    const selected = Array.from(e.target.selectedOptions, option => option.value);
    setTurfData({
      ...turfData,
      [fieldName]: selected,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const venueId = responseData?.id;

    try {
      const formDataToSend = new FormData();
      for (const key in turfData) {
        formDataToSend.append(key, turfData[key]);
      }

      const response = await axios.post(
        `${apiBaseUrl}/update_Venue/${venueId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.errors) {
        const errorMessages = [];
        for (const field in response.data.errors) {
          errorMessages.push(...response.data.errors[field]);
        }
        alert(errorMessages.join("\n"));
      } else {
        navigate("/vendor_venue_list");
      }
    } catch (error) {
      console.log("Error while updating venue", error);
    }
  };

  return (
    <div>
      <main>
        <section className="auth signin">
          <div className="page-title pageHead">
            <button
              onClick={back}
              type="button"
              className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowleftblack} alt="arrow" />
            </button>
            <h3 className="main-title mainTitleLight">Update Venue</h3>
          </div>

          <div className="form-area auth-form" style={{ height: '80vh' }}>
            <form onSubmit={handleSubmit}>
              <div className="icon-container">
                <div>
                  <label htmlFor="lemail1">Add Venue Name</label>
                  <input
                    type="text"
                    id="turfname"
                    name="turfname"
                    value={turfData.turfname}
                    className="input-field"
                    placeholder="Venue Name"
                    onChange={handleChange}
                  />
                </div>
                <input
                  type="hidden"
                  id="venueId"
                  name="venueId"
                  value={venueId}
                  className="input-field"
                  placeholder="Venue Name"
                  onChange={handleChange}
                />
                <div>
                  <label htmlFor="venueImage">Add Venue Image</label>
                  <input
                    type="file"
                    id="venue_image"
                    name="venue_image"
                    className="input-field"
                    placeholder="Venue Image"
                    style={{ padding: "8px" }}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="turfsports">Select Sports Type</label>
                  <div className="position-relative">
                    <select
                      multiple
                      value={turfData.turfsports}
                      onChange={(e) => handleSelectChange(e, "turfsports")}
                      id="turfsports"
                      name="turfsports"
                      required
                    >
                      {gameType && gameType.gameTypes
                        ? gameType.gameTypes.map((game, index) => (
                          <option key={index} value={game}>
                            {game}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                </div>
                <div>
                  <label htmlFor="facilities">Select Facilities</label>
                  <select
                    multiple
                    value={turfData.facilities}
                    onChange={(e) => handleSelectChange(e, "facilities")}
                    id="facilities"
                    name="facilities"
                    required
                  >
                    {gameType && gameType.facilities
                      ? gameType.facilities.map((facility, index) => (
                        <option key={index} value={facility}>
                          {facility}
                        </option>
                      ))
                      : null}
                  </select>
                </div>
                <button className="btn-primary" style={{ marginTop: "15px" }}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
    </div>
  );
};

export default VendorUpdateVenue;
