import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import arrowleftblack from '../assest/svg/arrow-left-black.svg';
import profileavatar from '../assest/images/profile/avatar.png';
import editwhite from '../assest/svg/edit-white.svg';
import camerafillblack from '../assest/svg/camera-fill-black.svg';
import filefillblack from '../assest/svg/file-fill-black.svg';
import trashfillred from '../assest/svg/trash-fill-red.svg';
import '../assest/css/bootstrap.min.css';
import '../assest/css/swiper-bundle.min.css';
import '../assest/css/jquery-ui.min.css';
import '../assest/css/jquery.datetimepicker.css';
import '../assest/css/datetimepicker.css';
import '../assest/css/common.css';
import '../assest/css/welcome.css';
import '../assest/css/animations.css';
import '../assest/css/auth.css';
import '../assest/css/profile.css';
import Modal from "../Credantails/modal"
import bodyCss from "../assest/css/body.css";
import manualcss from '../assest/css/manualcss.css';
const VendorUpdateProfile = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const id2 = localStorage.getItem("id2");
    const [loadData, setLoadData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState({});
    const [formData, setFormData] = useState({
        id: id2,
        name: "",
        phone: "",
        email: "",
        dob: "",
        address: "",
    });

    const loadList = async () => {
        try {
            const vendor_id = localStorage.getItem("id2");
            const result = await axios.get(`${apiBaseUrl}/viewVendor_profile/${vendor_id}`);
            setLoadData(result.data);
            if (result.data && result.data.profile) {
                const profile = result.data.profile[0]; // Assuming only one profile is fetched
                setFormData({
                    id: vendor_id,
                    name: profile.owner_name,
                    phone: profile.owner_mobile,
                    email: profile.owner_email,
                    dob: profile.owner_dob ? profile.owner_dob.split('T')[0] : "", // Fixing date format
                    address: profile.owner_address,
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        loadList();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                formDataToSend.append(key, formData[key]);
            }
            await axios.post(`${apiBaseUrl}/vendorUpdate_profile`, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            setModalMessage({
                title: "Success",
                content: "Profile updated successfully",

            });
            setShowModal(true);
            navigate("/vendorUpdate_profile");


            navigate("/vendor_dashboard");

        } catch (error) {
            console.error("Error while updating profile:", error);
        }
    };


    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
            <main>
                <div className="page-title pageHead">
                    <button
                        type="button"
                        className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                    >
                        <Link to="/vendor_dashboard">
                            <img src={arrowleftblack} alt="arrow" />
                        </Link>
                    </button>
                    <h3 className="main-title mainTitleLight">Profile</h3>
                </div>

                <section className="profile-image py-16">
                    <div className="image">
                        <img
                            src={profileavatar}
                            alt="avatar"
                            className="w-100 h-100 object-fit-cover img-fluid rounded-full"
                        />
                        <button
                            type="button"
                            className="d-flex align-items-center justify-content-center rounded-full"
                            data-bs-toggle="modal"
                            data-bs-target="#editImageModal"
                        >
                            <img src={editwhite} alt="icon" />
                        </button>
                    </div>
                </section>

                {loadData && (
                    <section className="user-info px-24">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-16">
                                <label className="input-label">First Name</label>
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    name="name"
                                    onChange={handleChange}
                                    className="input"
                                    value={formData.name}
                                />
                            </div>

                            <div className="mb-16">
                                <label className="input-label">Mobile Number</label>
                                <input
                                    type="text"
                                    placeholder="Enter your Mobile Number"
                                    name="phone"
                                    onChange={handleChange}
                                    className="input"
                                    value={formData.phone}
                                />
                            </div>

                            <div className="mb-16">
                                <label className="input-label">E-mail</label>
                                <input
                                    type="email"
                                    placeholder="E-mail"
                                    name="email"
                                    onChange={handleChange}
                                    className="input"
                                    value={formData.email}
                                />
                            </div>

                            <div className="mb-16">
                                <label className="input-label">Date of Birth</label>
                                <div className="dob position-relative d-flex align-items-center">
                                    <input
                                        type="date"
                                        placeholder="Date of Birth"
                                        className="input"
                                        id="dob"
                                        name="dob"
                                        onChange={handleChange}
                                        value={formData.dob}
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="input-label">Address</label>
                                <textarea
                                    id="myTextarea"
                                    placeholder="Address"
                                    className="input"
                                    name="address"
                                    onChange={handleChange}
                                    value={formData.address}
                                />
                            </div>

                            <input type="submit" className="btn-primary" value="Submit" />
                        </form>
                    </section>
                )}
            </main>

            <div
                className="modal fade dateOfBirthModal dateModal modalBg"
                id="dateOfBirthModal"
                tabIndex={-1}
                aria-labelledby="dateOfBirthModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h1 className="modal-title" id="dateOfBirthModalLabel">
                                Date Of Birth
                            </h1>
                        </div>
                        <div className="modal-body">
                            <input type="text" id="dateOfBirthDateInput" />
                            <div className="btns d-flex align-items-center gap-16">
                                <button
                                    type="button"
                                    className="radius-20 w-50 cancel-btn"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="radius-20 w-50 apply-btn"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade editImageModal modalBg"
                id="editImageModal"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h1>Change your picture</h1>
                        </div>
                        <div className="modal-body">
                            <ul>
                                <li>
                                    <button
                                        type="button"
                                        className="d-flex align-items-center gap-16 radius-8 action-btn"
                                    >
                                        <img
                                            src={camerafillblack}
                                            alt="icon"
                                            className="shrink-0"
                                        />
                                        Take a photo
                                    </button>
                                </li>
                                <li>
                                    <label htmlFor="choose-file" className="d-block w-100">
                                        <input type="file" id="choose-file" />
                                        <span className="d-flex align-items-center gap-16 radius-8 action-btn">
                                            <img
                                                src={filefillblack}
                                                alt="icon"
                                                className="shrink-0"
                                            />
                                            Choose from your file
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className="d-flex align-items-center gap-16 radius-8 action-btn delete"
                                    >
                                        <img
                                            src={trashfillred}
                                            alt="icon"
                                            className="shrink-0"
                                        />
                                        Delete Photo
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VendorUpdateProfile;
