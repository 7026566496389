import React, { useEffect, useState } from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import slotscss from "../slots/slot_list.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import profile from "../assest/css/profile.css";

import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios, { Axios } from "axios";
import { Hidden } from "@mui/material";




const ExecutiveViewslot = () => {




  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const vendorIdFromParams = searchParams.get("id");
console.log("vvvvvvvvvvv", vendorIdFromParams);

  const navigate = useNavigate();

  const back = () => {
    navigate("/vendor_list");
  };

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [data, setData] = useState(null);

  const id1 = localStorage.getItem("id1");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/all_Slots/${vendorIdFromParams}`
      );
      setData(response.data);
      console.log("data", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("token", token);
    if (token == null) {
      navigate("/fields_executive_dashboard");
    }
    fetchData();
  }, []);

  return (
    <div>
      <>
        <main className="tour-guide">
          <div className="page-title">
            <button
              onClick={back}
              type="button"
              className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowleftblack} alt="arrow" />
            </button>

            <h3 className="main-title">Slots List</h3>
          </div>
          <div class="venue-button-container">
            <div>
              <button class="venue-button active">Venue 1</button>
            </div>
          </div>

          <section className="budget pt-10">
            <div
              className="card"
              style={{
                padding: "5px 20px !important",
              }}
            >
              <div className="card-body" style={{ padding: "0px" }}>
                <div className="">
                  <div class="time-section">
                    <div className="time-info text-muted">
                      <p>Start Time: </p>
                      <p>
                        <span>8:00 AM</span>
                      </p>
                    </div>
                    <div className="time-info text-muted">
                      <p>End Time:</p>
                      <p>
                        <span>5:00 PM</span>
                      </p>
                    </div>
                    <div className="text-end">
                      <h5 className="pb-9">
                        <span
                          className="d-inline-block text-center"
                          style={{
                            width: "80px",

                            border: "none",
                            textDecoration: "none",
                            fontSize: "12px",
                            borderRadius: "5px",
                            padding: "5px",
                            textAlign: "center",
                            color: "white",
                            backgroundColor: "#01A669",
                          }}
                        >
                          <a>Update</a>
                        </span>
                      </h5>
                      <span
                        className="d-inline-block text-center"
                        style={{
                          textDecoration: "none",
                          width: "80px",
                          borderRadius: "5px",
                          padding: "2px",
                          fontSize: "12px",
                          textAlign: "center",
                          backgroundColor: "#ddd",
                          color: "#212529",
                        }}
                      >
                        <a>Delete</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    </div>
  );
};




export default ExecutiveViewslot;