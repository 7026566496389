import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';

import bootstrapcss from "../assest/css/bootstrap.min.css";
import commoncss from "../assest/css/common.css";
import avatar from "../assest/images/profile/avatar.png";
import mapmarker from "../assest/svg/map-marker.svg";
import bellblack from "../assest/svg/bell-black.svg";
import executiveprofile from "../assest/images/profile/executive.png";
import kyc1 from "../assest/homeimage/ky1.svg";
import kyc2 from "../assest/homeimage/newkyc.jpg";
import logout from "../assest/homeimage/newlogout.png";
import addvenue from "../assest/homeimage/add.png";
import venuelist from "../assest/homeimage/vendorlist.png";
import history from "../assest/homeimage/history.png";
import query from "../assest/homeimage/query.png";

import "../App.css"

import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import slider1 from "../assest/homeimage/slider2.png";
import slider2 from "../assest/homeimage/slider3.png";
import slider3 from "../assest/homeimage/v1.png";
import slider4 from "../assest/homeimage/v2.png";

import kyccss from "../FieldsExecutive/kyc.css";

import Slider from "react-slick";

const Fields_Executive_Dashboard = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();


  const [data, setData] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/showpageapi/${id}`);

      setData(response.data);
      console.log("dta", response.data);
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  //notification function
  const [getRows, setRows] = useState([]);
  const [loadData, setAData] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
    loadList();
  }

  const updateNotification = () => {
    //Update Notification table 
    axios.post(`${apiBaseUrl}/updateNotification`)
      .then((result) => {
        setOpen(false)
        getLatestRowDetails();
      })
      .catch(() => {
        alert('Error in the Code');
      });
  }

  const loadList = async () => {
    try {
      const id = localStorage.getItem("id");
      console.log(id);
      const result = await axios.get(`${apiBaseUrl}/list/${id}`);
      console.log("Result data:", result.data); // Log the fetched data
      setAData(result.data); // Set the fetched data to state
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors here
    }
  };

  // To get total count from backend
  const getLatestRowDetails = async () => {

    const results = await axios.get(`${apiBaseUrl}/getLatestRow`);
    console.log("Result data:", results.data);
    setRows(results.data);
  };


  useEffect(() => {
    // Replace icons after component is mounted

    const token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");

    console.log(user_type);

    if (token === null) {
      navigate("/user_signin");
    } else if (user_type === 'field_executive') {
      navigate("/fields_executive_dashboard");
    } else {
      navigate("/vendor_dashboard");
    }
    console.log(token);
    // feather.replace();

    // const id = localStorage.getItem("id");
    loadList();
    getLatestRowDetails();

    fetchData();
  }, []);

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem("token");
    // Navigate to the login page

    navigate("/user_signin");
  };

  console.log(loadData);
  // console.log("Result data:", result.data);
  // setAData(result.data);
  return (
    <>

      <div style={{ overflow: "hidden" }}>
        <div style={{ padding: "10px" }} className="bg-primary-main">
          {/* Header */}

          <section className="info d-flex align-items-start justify-content-between">
            <div className="d-flex align-items-center justify-content-between gap-14 " style={{ padding: '15px' }}>
              <div className=" d-flex ">
                <div
                  className="image"
                  style={{ width: "50px", height: "50px" }}
                >
                  <Link to="/update_profile" style={{ textDecoration: "none" }}>
                    <img
                      src={avatar}
                      alt="avatar"
                      className="object-fit-cover img-fluid rounded-full"
                    />
                  </Link>
                </div>
                <div>
                  <h5
                    style={{
                      fontSize: "15px",
                      paddingLeft: "10px",
                      color: "#ffffff",
                      marginTop: "4px",
                    }}
                  >
                    {/* Hi, {data && data.show && data.show.name}{" "} */}
                    Hi,{" "}
                    {data &&
                      data.show &&
                      typeof data.show.name === "string" &&
                      data.show.name}{" "}
                  </h5>
                  <h7
                    className="d-flex "
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      color: "#ffffff",
                    }}
                  >
                    <img src={mapmarker} alt="icon" />
                    India
                  </h7>
                </div>
              </div>
              <div></div>
            </div>

            <ul className="d-flex  gap-16" style={{ marginTop: "10px" }}>
              <li>
                <Link to="/notification">
                  <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static"
                      style={{ backgroundColor: "transparent", boxShadow: "none", }}>
                      {/* <Button onClick={handleOpen}><Badge badgeContent={getRows == '0' ? "0" : getRows }  color="warning"> <NotificationsIcon /> </Badge> </Button> */}
                      <MenuItem onClick={handleOpen}>
                        <IconButton
                          size="large"
                          aria-label="show 17 new notifications"
                          color="inherit"
                        >
                          <Badge badgeContent={getRows == '0' ? "0" : getRows} color="error">
                            <NotificationsIcon />
                          </Badge>
                        </IconButton>

                      </MenuItem>
                    </AppBar>
                  </Box>
                </Link>
                {/* <Link className="d-flex align-items-center justify-content-center  position-relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#ffffff"
                      d="M12 22a2.02 2.02 0 0 1-2.01-2h4a2.02 2.02 0 0 1-.15.78a2.042 2.042 0 0 1-1.44 1.18h-.047A1.922 1.922 0 0 1 12 22Zm8-3H4v-2l2-1v-5.5a8.065 8.065 0 0 1 .924-4.06A4.654 4.654 0 0 1 10 4.18V2h4v2.18c2.579.614 4 2.858 4 6.32V16l2 1v2Z"
                    />
                  </svg>
                  <span className="dot" />
                </Link> */}
              </li>

              <li onClick={handleLogout}>
                <a className="d-flex align-items-center justify-content-center  position-relative ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#ffffff"
                      d="M12 3.25a.75.75 0 0 1 0 1.5a7.25 7.25 0 0 0 0 14.5a.75.75 0 0 1 0 1.5a8.75 8.75 0 1 1 0-17.5"
                    />
                    <path
                      fill="#ffffff"
                      d="M16.47 9.53a.75.75 0 0 1 1.06-1.06l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H10a.75.75 0 0 1 0-1.5h8.19z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </section>
        </div>

        <div className="top-border-radius bg-none">
          <div>
            <div>
              <section
                style={{ justifyContent: "center", paddingBottom: "0px" }}
                className="mb-1"
              >
                <div className="custom-container" style={{ margin: "0 auto" }}>
                  <Slider {...settings}>
                    <div>
                      <img
                        src={slider1}
                        alt="color"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                        }}
                      />
                    </div>
                    <div>
                      <img
                        src={slider2}
                        alt="color"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                        }}
                      />
                    </div>
                  </Slider>
                </div>
              </section>
            </div>

            <div>
              <div className="field-dash">
                <h3
                  style={{
                    fontSize: "14px",
                    paddingTop: "5px",
                  }}
                >
                  Welcome To Field Executive Panel
                </h3>
              </div>

              {data && data.show && data.show.is_active === "inactive" ? (
                <div className="container-fluid  mt-3">
                  <div className="row">
                    <div className="col">
                      <div
                        className="kyc-card  app-main-card app-main-card"
                        style={{ padding: "10px" }}
                      >
                        <div className="card-body kyc-image-text-container">
                          <img
                            src={kyc1}
                            alt="KYC Image"
                            className="img-fluid"
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                          />
                          <div style={{ marginLeft: "10px" }}>
                            <h5
                              className="card-title"
                              style={{ fontSize: "medium" }}
                            >
                              KYC Status
                            </h5>
                            <p
                              className="card-text "
                              style={{ fontSize: "smaller", color: "red" }}
                            >
                              Pending
                            </p>
                          </div>
                          <div className="ms-auto" style={{ marginRight: "5px" }}>
                            <button className="btn btn-sm btn-kyc" style={{}}>
                              <Link
                                to="/updatekyc"
                                style={{
                                  textDecoration: "none",
                                  fontFamily: "auto",
                                }}
                              >
                                Complete{" "}
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" ">
                  <div className="row" style={{ padding: "5px 15px" }}>
                    <div className="col-6">
                      <Link
                        to="/add_executive_data"
                        style={{
                          textDecoration: "none",
                          alignItems: "center",
                        }}
                      >
                        <div className=" text-dark  mb-3 app-main-card">
                          <div className="header d-flex align-items-center">
                            <img
                              src={addvenue}
                              alt="Vendor Image"
                              className="img-fluid mr-3"
                              style={{ maxWidth: "30px" }}
                            />
                            <div>
                              <h5
                                className="card-title mb-0"
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  fontFamily: "systemui",
                                  marginLeft: "10px",
                                }}
                              >
                                Add Vendor
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6">
                      <Link
                        to="/vendor_list"
                        style={{ textDecoration: "none" }}
                      >
                        <div className=" text-dark  mb-3 app-main-card">
                          <div className="header   d-flex align-items-center">
                            <img
                              src={venuelist}
                              alt="Vendor Image"
                              className="img-fluid mr-3"
                              style={{ maxWidth: "30px" }}
                            />

                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              Vendor List
                            </h5>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-6">
                      <div className="text-darke  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={history}
                            alt="Vendor Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />
                          <Link to="/fe_history" style={{ textDecoration: "none" }}>
                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              History
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className=" text-dark  mb-3 app-main-card">
                        <div className="header  d-flex  align-items-center">
                          <img
                            src={query}
                            alt="Vendor Image"
                            className="img-fluid mr-3"
                            style={{ maxWidth: "30px" }}
                          />
                          <Link
                            to="/executive_query"
                            style={{ textDecoration: "none" }}
                          >
                            <h5
                              className="card-text"
                              style={{
                                color: "black",
                                fontSize: "14px",
                                fontFamily: "systemui",
                                marginLeft: "10px",
                              }}
                            >
                              {" "}
                              Query
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fields_Executive_Dashboard;

