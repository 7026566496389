import React, { useEffect, useState } from 'react';
import bootstrapcss from '../assest/css/bootstrap.min.css'
import swipercss from '../assest/css/swiper-bundle.min.css'
import jquerycss from '../assest/css/jquery-ui.min.css'
import jquerydatetimepicker from '../assest/css/jquery.datetimepicker.css'
import datetimepicker from '../assest/css/datetimepicker.css'
import commoncss from '../assest/css/common.css'
import welcomecss from '../assest/css/welcome.css'
import animationscss from '../assest/css/animations.css'
import authcss from '../assest/css/auth.css'
import profile from '../assest/css/profile.css'


import calendarblue from '../assest/svg/calendar-blue.svg'

import arrowleftblack from "../assest/svg/arrow-left-black.svg";

import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { type } from '@testing-library/user-event/dist/type';


import { Navigate } from 'react-router-dom';

const UpdateKyc = () => {

  const back = () => {
    navigate("/fields_executive_dashboard");
  };

  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


  const fetchData = async () => {
    try {

      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/showpageapi/${id}`);

      setData(response.data);



      console.log(response.data);
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };



  useEffect(() => {
    // Replace icons after component is mounted




    const token = localStorage.getItem('token');

    // if token it will navigate to dashboard or signup

    if (token == null) {

      navigate('/user_sign');

    } else {
      navigate('/updatekyc');
    }
    console.log(token)
    // feather.replace();

    fetchData();
  }, []);

  const id = localStorage.getItem('id');


  const [formData, setFormData] = useState({
    id: id,
    dob: "",
    address: "",
    adhar: "",
    adharimg: "",
    pan: "",
    panimg: "",
    bank: "",
    accountname: "",
    accountnumber: "",
    ifsc: "",
  });


  const handleChange = (e) => {

    const {
      name,
      value,
      files
    } = e.target;




    let acceptedFormats = ['image/jpeg', 'application/pdf', 'image/png', 'image/jpg'];







    if (name === 'adharimg' || name === 'panimg') {


      const selectedFile = e.target.files[0];
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];




      if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {

        // If the file type is allowed, update the form data with the file


        setFormData({
          ...formData,
          [name]: selectedFile
        });


      } else {
        alert('Please upload a file with JPEG, PDF, PNG, or JPG format.');
        // If the file type is not allowed, reset the file input
        e.target.value = ''; // Reset the file input to clear the selected file

      }
    } else {
      // For non-file inputs, update the form data with the input value
      setFormData({
        ...formData,
        [name]: value
      });
    }








  };




  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);

      }
      console.log("form data to send :", formDataToSend);

      // for (let pair of formDataToSend.entries()) {
      //   console.log(pair[0] + ': ' + pair[1]);
      // }


      for (let [key, value] of formDataToSend.entries()) {
        console.log(key + ': ' + value);
      }


      const response = await axios.post(`${apiBaseUrl}/updatekyc`,

        formDataToSend, {

        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      );



      navigate("/fields_executive_dashboard")
    }
    catch (error) {

      console.log("Error while creating account", error);
    }

  }


  return (
    <div>
      <div className="auth-main">
        <section className="auth signin">
          <div className="heading" style={{ marginTop: "-60px" }}>
            <button
              type="button"
              onClick={back}
              className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img
                src={arrowleftblack}
                alt="arrow"
                style={{
                  borderRadius: "20px",
                  backgroundColor: "aliceblue",
                }}
              />
            </button>
            <h2>Update Kyc Form</h2>
          </div>

          <div className="form-area auth-form">
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-column gap-16">
                <div>
                  <label htmlFor="remail2">Date Of Birth</label>
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    placeholder="Enter Date of Birth"
                    className="input-field d-block"
                    onChange={handleChange}
                  />
                </div>


                <div>
                  <label className="input-label">Address</label>
                  <textarea
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Address"
                    className="input-field d-block"
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="remail2">Aadhar Number</label>
                  <input
                    type="number"
                    id="adhar"
                    name="adhar"
                    placeholder="Enter your  Aadhar Number"
                    className="input-field d-block"
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="remail2">Aadhar</label>
                  <input
                    type="file"
                    id="adharimg"
                    name="adharimg"
                    placeholder="Upload Aadhar Image"
                    className="input-field d-block"
                    style={{ padding: "8px" }}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="remail2">Pan Number</label>
                  <input
                    type="text"
                    id="pan"
                    name="pan"
                    placeholder="Enter  Pan Number"
                    className="input-field d-block"
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="remail2">Pan</label>
                  <input
                    type="file"
                    id="panimg"
                    name="panimg"
                    placeholder="Upload Pan Image"
                    className="input-field d-block"
                    style={{ padding: "8px" }}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="remail2">Bank </label>
                  <input
                    type="text"
                    id="bank"
                    name="bank"
                    placeholder="Enter  Bank Name"
                    className="input-field d-block"
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="remail2">Account Name </label>
                  <input
                    type="text"
                    id="accountname"
                    name="accountname"
                    placeholder="Enter Account Holder Name"
                    className="input-field d-block"
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="remail2">Account Number </label>
                  <input
                    type="text"
                    id="accountnumber"
                    name="accountnumber"
                    placeholder="Enter Account Number"
                    className="input-field d-block"
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="remail2">Ifsc Code </label>
                  <input
                    type="text"
                    id="ifsc"
                    name="ifsc"
                    placeholder="Enter your Ifsc Code "
                    className="input-field d-block"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <button type="submit" className="btn-primary">
                Update Kyc
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UpdateKyc;