import React, { useEffect, useState } from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import eyeon from "../assest/svg/eye-on.svg";
import eyeoff from "../assest/svg/eye-off.svg";
import icongoogle from "../assest/svg/icon-google.svg";
import iconapple from "../assest/svg/icon-apple.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import Modal from "../Credantails/modal";

const VendorVerifyPassword = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [showModal, setShowModal] = useState(false);
  const email = queryParams.get("email");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: email,
    password: "",
    conf_password: "",
  });

  const [showRequirements, setShowRequirements] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      localStorage.setItem(
        "modalMessage3",
        JSON.stringify({
          title: "Password Changed Successfully",
          content: "Login with new password",
        })
      );
      console.log(formData);

      await axios.post(`${apiBaseUrl}/vendor_setpassword`, formData);
      console.log("Password Changed successfully:", formData);

      navigate("/vendor_signin");
    } catch (error) {
      console.error("Error password not changed:", error);
      setModalMessage({
        title: "Error",
        content:
          "Something went wrong. Please enter correct password and try again later.",
      });
      setShowModal(true);
    }
  };

  const [modalMessage2, setModalMessage] = useState(null);
  useEffect(() => {
    const storedMessage = localStorage.getItem("modalMessage2");
    if (storedMessage) {
      setModalMessage(JSON.parse(storedMessage));
      localStorage.removeItem("modalMessage2");
    }
  }, []);

  const showPasswordRequirements = () => {
    setShowRequirements(true);
  };

  const hidePasswordRequirements = () => {
    setShowRequirements(false);
  };

  const back = () => {
    navigate("/vendor_signin");
  };

  return (
    <div>
      <>
        {modalMessage2 && (
          <Modal
            message={modalMessage2}
            closeModal={() => setModalMessage(null)}
          />
        )}
        <main>
          <section className="auth signin">
            <div className="page-title pageHead">
              <button
                onClick={back}
                type="button"
                className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
              >
                <img src={arrowleftblack} alt="arrow" />
              </button>
              <h3 className="main-title mainTitleLight">Create new Password</h3>
            </div>

            <div className="form-area auth-form">
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter your Password"
                    className="input-field"
                    onChange={handleChange}
                    onFocus={showPasswordRequirements}
                    onBlur={hidePasswordRequirements}
                    required
                  />
                  {showRequirements && (
                    <div id="password-requirements" style={{ background: 'white', border: '1px solid gray', boxShadow: '2px 2px 5px gray', padding: '2px 5px', borderRadius: '10px', margin: '2px 5px 5px 5px' }}>
                      <p style={{ fontSize: '16px', color: "red" }}><strong>Note:</strong> Password must contain</p>
                      <ul style={{ paddingLeft: "8px" }}>
                        <li style={{ color: "red" }}>Minimum length of 8 characters</li>
                        <li style={{ color: "red" }}>At least one lowercase letter</li>
                        <li style={{ color: "red" }}>At least one uppercase letter</li>
                        <li style={{ color: "red" }}>At least one digit</li>
                        <li style={{ color: "red" }}>At least one special character (@, $, !, %, *, #, ?, &)</li>
                      </ul>
                    </div>
                  )}
                </div>

                <div>
                  <label htmlFor="conf_password">Confirm Password</label>
                  <input
                    type="password"
                    id="conf_password"
                    name="conf_password"
                    placeholder="Enter your Confirm Password"
                    className="input-field"
                    onChange={handleChange}
                  />
                </div>

                <input value="login" type="submit" className="btn-primary" />
              </form>

              <div className="divider d-flex align-items-center justify-content-center gap-12">
                <span className="d-inline-block" />
                <small className="d-inline-block">Or continue with</small>
                <span className="d-inline-block" />
              </div>

              <div className="d-flex flex-column gap-16">
                <button
                  type="button"
                  className="social-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#loginSuccess"
                >
                  <img
                    src={icongoogle}
                    alt="Google"
                    style={{ width: "30px", height: "30px" }}
                  />
                  Click to Resend Otp
                </button>
              </div>

              <h6>
                Back to Login Page? <Link to="/vendor_signup">Login</Link>
              </h6>
            </div>
          </section>
        </main>
      </>
    </div>
  );
};

export default VendorVerifyPassword;


// import React, { useEffect, useState } from "react";
// import bootstrapcss from "../assest/css/bootstrap.min.css";
// import swipercss from "../assest/css/swiper-bundle.min.css";
// import jquerycss from "../assest/css/jquery-ui.min.css";
// import commoncss from "../assest/css/common.css";
// import welcomecss from "../assest/css/welcome.css";
// import animationscss from "../assest/css/animations.css";
// import authcss from "../assest/css/auth.css";
// import eyeon from "../assest/svg/eye-on.svg";
// import eyeoff from "../assest/svg/eye-off.svg";
// import icongoogle from "../assest/svg/icon-google.svg";
// import iconapple from "../assest/svg/icon-apple.svg";
// import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
// import otp from "../assest/homeimage/otp.png";

// import axios from "axios";
// import arrowleftblack from "../assest/svg/arrow-left-black.svg";


// import Modal from "../Credantails/modal";
// const VendorVerifyPassword = () => {
//   const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

//   const location = useLocation();

//   const queryParams = new URLSearchParams(location.search);

//   const [showModal, setShowModal] = useState(false);
//   const email = queryParams.get("email");

//   const navigate = useNavigate();

//   //   const email = queryParams.get("email");

//   const [formData, setFormData] = useState({
//     email: email,
//     password: "",
//     conf_password: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };



//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       localStorage.setItem(
//         "modalMessage3",
//         JSON.stringify({
//           title: "Password Changed Successfully",
//           content: "Login with new password",
//         })
//       );
//       console.log(formData);

//       await axios.post(`${apiBaseUrl}/vendor_setpassword`, formData);
//       console.log("Password Changed successfully:", formData);

//       navigate("/vendor_signin");
//     } catch (error) {
//       console.error("Error password not changed:", error);
//       setModalMessage({
//         title: "Error",
//         content:
//           "Some thing went wrong.Please enter correct password and try again later.",
//       });
//       setShowModal(true);
//     }
//   };
//   const [modalMessage2, setModalMessage] = useState(null);
//   useEffect(() => {
//     // Retrieve modal message from local storage
//     const storedMessage = localStorage.getItem("modalMessage2");
//     if (storedMessage) {
//       setModalMessage(JSON.parse(storedMessage));
//       // Remove modal message from local storage after retrieving
//       localStorage.removeItem("modalMessage2");
//     }
//   }, []);

//   const showPasswordRequirements = () => {
//     setShowRequirements(true);
//   };

//   const hidePasswordRequirements = () => {
//     setShowRequirements(false);
//   };


//   const back = () => {
//     navigate("/vendor_signin");
//   };
//   return (
//     <div>
//       <>
//         {modalMessage2 && (
//           <Modal
//             message={modalMessage2}
//             closeModal={() => setModalMessage(null)}
//           />
//         )}
//         <main>
//           <section className="auth signin">
//             <div className="page-title pageHead">
//               <button
//                 onClick={back}
//                 type="button"
//                 className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
//               >
//                 <img src={arrowleftblack} alt="arrow" />
//               </button>
//               <h3 className="main-title mainTitleLight">Create new Password</h3>
//             </div>

//             <div className="form-area auth-form">
//               <form onSubmit={handleSubmit}>
//                 <div>
//                   <label htmlFor="lemail1">Password</label>
//                   <input
//                     type="password"
//                     id="password"
//                     name="password"
//                     placeholder="Enter your Password"
//                     className="input-field"
//                     onChange={handleChange}
//                     onFocus={showPasswordRequirements}
//                     onBlur={hidePasswordRequirements}
//                     required
//                   />
//                   {showRequirements && (
//                     <div id="password-requirements" style={{ background: 'white', border: '1px solid gray', boxShadow: '2px 2px 5px gray', padding: '2px 5px', borderRadius: '10px', margin: '2px 5px 5px 5px' }}>
//                       <p style={{ fontSize: '16px', color: "red" }}><strong>Note:</strong> Password must contain</p>
//                       <ul style={{ paddingLeft: "8px" }}>
//                         <li style={{ color: "red" }}>Minimum length of 8 characters</li>
//                         <li style={{ color: "red" }}>At least one lowercase letter</li>
//                         <li style={{ color: "red" }}>At least one uppercase letter</li>
//                         <li style={{ color: "red" }}>At least one digit</li>
//                         <li style={{ color: "red" }}>At least one special character (@, $, !, %, *, #, ?, &)</li>
//                       </ul>
//                     </div>
//                   )}
//                 </div>

//                 <div>
//                   <label htmlFor="lemail1">Confirm Password</label>
//                   <input
//                     type="password"
//                     id="conf_password"
//                     name="conf_password"
//                     placeholder="Enter your Confirm Password"
//                     className="input-field"
//                     onChange={handleChange}
//                   />
//                 </div>

//                 <input value="login" type="submit" className="btn-primary" />
//               </form>

//               <div className="divider d-flex align-items-center justify-content-center gap-12">
//                 <span className="d-inline-block" />
//                 <small className="d-inline-block">Or continue with</small>
//                 <span className="d-inline-block" />
//               </div>

//               <div className="d-flex flex-column gap-16">
//                 <button
//                   type="button"
//                   className="social-btn"
//                   data-bs-toggle="modal"
//                   data-bs-target="#loginSuccess"
//                 >
//                   <img
//                     src={icongoogle}
//                     alt="Google"
//                     style={{ width: "30px", height: "30px" }}
//                   />
//                   Click to Resend Otp
//                 </button>
//               </div>

//               <h6>
//                 {" "}
//                 Back to Login Page? <Link to="/vendor_signup">Login</Link>
//               </h6>
//             </div>
//           </section>
//         </main>
//       </>
//     </div>
//   );
// };


// export default VendorVerifyPassword;


