import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import HorizontalScroll from 'react-horizontal-scroll';
import '../assest/css/list.css';
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import details from "../assest/css/home.css";
import chat from '../assest/css/chat.css';
import list from '../assest/css/list.css'
import plus from '../assest/svg/plus-white.svg'
import datetimepicker from "../assest/css/datetimepicker.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";

const Status = () => {
    const [currentStartDate, setCurrentStartDate] = useState(moment().startOf('month').month(1));
    const [currentMonth, setCurrentMonth] = useState(moment().format('MMM'));
    const scrollContainerRef = useRef(null);

    const generateDates = (startDate, endDate) => {
        const dates = [];
        let currentDate = moment(startDate);
        while (currentDate <= endDate) {
            dates.push(currentDate);
            currentDate = currentDate.clone().add(1, 'day');
        }
        return dates;
    };

    const updateMonth = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {

            const dateBlockWidth = scrollContainer.offsetWidth / dates.length;

            const firstVisibleDateIndex = Math.floor(scrollContainer.scrollLeft / dateBlockWidth);

            const firstVisibleDate = dates[firstVisibleDateIndex];
            setCurrentMonth(firstVisibleDate.format('MMM'));
        }
    };

    const aprilStartDate = moment().startOf('year').month(3).startOf('month');
    const endDate = moment().endOf('year');
    const dates = generateDates(aprilStartDate, endDate);
    const currentDate = moment();
    const daysToHighlight = 3;

    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            const scrollLeft = scrollContainer.scrollLeft;
            const elementWidth = scrollContainer.offsetWidth / dates.length;
            const scrollIndex = Math.floor(scrollLeft / elementWidth);
            const newStartDate = moment(aprilStartDate).add(scrollIndex, 'days');
            setCurrentStartDate(newStartDate);
        }
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [aprilStartDate]);

    useEffect(() => {
        updateMonth();
    }, []);

    return (
        <div>
            <>
                <main className="tour-guide">
                    <div className="page-title" style={{
                    }}>
                        <button
                            type="button"
                            className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                        >
                            <Link to="/fields_executive_dashboard">
                                <img src={arrowleftblack} alt="arrow" />
                            </Link>
                        </button>

                        <h3 className="main-title">Status</h3>
                    </div>
                    <hr className="horizontal-line" />

                    <div className='hor_cal_container'>
                        <p className='mont_center'>{currentMonth}</p>
                    </div>
                    <HorizontalScroll>
                        <div
                            ref={scrollContainerRef}
                            id="horizontal-scroll-container"
                            className="hide-scrollbar"
                            style={{ display: 'flex', position: 'relative', overflowX: 'scroll' }}
                        >
                            {dates.map((date, index) => {
                                const isHighlighted = date.isSameOrAfter(currentDate.clone().subtract(daysToHighlight, 'days'), 'day') && date.isSameOrBefore(currentDate, 'day');
                                return (
                                    <div
                                        key={date.format('YYYY-MM-DD')}
                                        className={isHighlighted ? 'active-date' : ''}
                                        style={{
                                            position: 'relative',
                                            width: '100px',
                                            height: '60px',
                                            padding: '2px',
                                            marginRight: '10px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <div className='properties'>
                                            <div>{date.format('ddd')}</div>
                                            <div>{date.format('D')}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </HorizontalScroll>

                    <hr className="horizontal-line" />
                    <div className='status_head_btn'>
                        <div class="status-button-container">
                            <button class="status-button active">Booked</button>
                        </div>
                        <div class="status-button-container">
                            <button class="">Available</button>
                        </div>
                    </div>

                    <hr className="hori_btn_line" />
                    <div className='venue_head'>
                        <div class="venue-button-container">
                            <button class="venue-button active">Venue 1</button>
                        </div>
                        <div class="venue-button-container">
                            <button class="venue-button active">Venue 2</button>
                        </div>
                    </div>
                    <hr className="horizontal-line" />
                    <div style={{ padding: '15px' }}>
                        <div className='slotsView'>
                            <p style={{
                                fontSize: '15px',
                            }}>15:00 - 16:00</p>
                            <p>Rs. 1200</p>
                        </div>
                        <div className='slotsView'>
                            <p style={{
                                fontSize: '15px',
                            }}>16:00 - 17:00</p>
                            <p>Rs. 1200</p>
                        </div>
                        <div className='slotsView'>
                            <p style={{
                                fontSize: '15px',
                            }}>18:00 - 19:00</p>
                            <p>Rs. 1200</p>
                        </div>

                    </div>
                </main>
            </>
        </div>
    );
};

export default Status;
