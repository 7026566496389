import React, { useEffect, useState } from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import eyeon from "../assest/svg/eye-on.svg";
import eyeoff from "../assest/svg/eye-off.svg";
import icongoogle from "../assest/svg/icon-google.svg";
import iconapple from "../assest/svg/icon-apple.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import otp from "../assest/homeimage/otpnew.png";
import { requestPermission } from '../firebase';
import manualcss from '../assest/css/manualcss.css';
import axios from "axios";

import Modal from "../Credantails/modal";
const VendorSignin = () => {


  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [isTokenFound, setTokenFound] = useState(false);



  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    console.log("Updated FormData:", { ...formData, [name]: value });
  };


  const closeModal = () => {
    setShowModal(false);
  };
  const [modalMessage3, setModalMessage1] = useState(null);

  useEffect(() => {
    // Retrieve modal message from local storage
    const storedMessage = localStorage.getItem("modalMessage3");
    if (storedMessage) {
      setModalMessage1(JSON.parse(storedMessage));
      // Remove modal message from local storage after retrieving
      localStorage.removeItem("modalMessage3");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      localStorage.setItem(
        "modalMessage4",
        JSON.stringify({
          title: "Vendor  Account login Successfully",
          content: "You have been   Successfully login",
        })
      );

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      console.log("FormData to send:", formDataToSend); // Log the formDataToSend

      const response = await axios.post(
        `${apiBaseUrl}/vendor_login`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const token = response.data.token;
      const id2 = response.data.user_id;
      const user_type = response.data.user_type;

      console.log("Token id is:", token);
      console.log("User id is:", id2);
      console.log("User Type is:", user_type);

      //   token store in local storage

      localStorage.setItem("token", token);
      localStorage.setItem("id2", id2);
      localStorage.setItem("user_type", user_type);
      console.log("User registered successfully:", formData);
      console.log("User tOKEN:", token);
      console.log("User id:", id2);


      // Request FCM token and save it
      await requestPermission(setTokenFound, id2);

      navigate("/vendor_dashboard");
    } catch (error) {
      console.log("Error while creating account", error);

      setModalMessage({
        title: "Error",
        content:
          "Failed to Login.  Please enter vaild email and Password.",
      });
      setShowModal(true);
    }
  };




  return (
    <div>
      <>
        {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
        {modalMessage3 && (
          <Modal
            message={modalMessage3}
            closeModal={() => setModalMessage1(null)}
          />
        )}
        <main className="auth-main">
          <section className="auth signin">
            <div className="heading" style={{ marginTop: "-60px" }}>
              <h2>Vendor Login</h2>
              <p>Login as Field Executive - <Link to="/user_signin" style={{ color: 'white' }}>Click Here</Link></p>
            </div>

            <div className="form-area auth-form">
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="lemail1">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email address"
                    className="input-field"
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="rpass">Password</label>
                  <div className="position-relative">
                    <input
                      type="password"
                      id="rpass"
                      name="password"
                      data-pssws-shown="false"
                      placeholder="Enter your password"
                      onChange={handleChange}
                      className="input-psswd input-field d-block"
                    />
                    {/* <button type="button" className="eye-btn">
                      <span className="eye-off">
                        <img src={eyeoff} alt="Eye Off" />
                      </span>
                      <span className="eye-on d-none">
                        <img src={eyeon} alt="Eye Off" />
                      </span>
                    </button> */}
                  </div>
                </div>

                <div className="remember-option mt-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <input value="Login" type="submit" className="btn-primary" />
              </form>

              {/* <div className="divider d-flex align-items-center justify-content-center gap-12">
                <span className="d-inline-block" />
                <small className="d-inline-block">Or continue with</small>
                <span className="d-inline-block" />
              </div> */}

              <div className="d-flex flex-column gap-16">
                <Link to="/vendor_forgetpassword" style={{ textDecoration: "none", }}>
                  <button
                    type="button"
                    // className="social-btn"
                    className="btn-primary"
                    data-bs-target="#loginSuccess"
                    style={{
                      marginTop: '5px'
                    }}
                  >
                    Forget Password?
                  </button>
                </Link>
              </div>

              <h6>
                Don’t have an account? <Link to="/vendor_signup">Sign Up</Link>
              </h6>
            </div>
          </section>
        </main>
      </>
    </div>
  );
};



export default VendorSignin;

