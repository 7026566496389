import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';
//   <link rel="shortcut icon" href="../assets/images/favicon.png" type="image/x-icon">
// import bootstrapcss from "../assest/css/bootstrap.min.css";
import datetimepicker from "../assest/css/jquery.datetimepicker.css";
import uiMin from "../assest/css/jquery-ui.min.css";
import welcomecss from "../assest/css/welcome.css";
import commoncss from "../assest/css/common.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import notificationcss from "../assest/css/notification.css";
import avatar from "../assest/images/profile/avatar.png";
import mapmarker from "../assest/svg/map-marker.svg";
import bellblack from "../assest/svg/bell-black.svg";
import executiveprofile from "../assest/images/profile/executive.png";
import kyc1 from "../assest/homeimage/ky1.svg";
import kyc2 from "../assest/homeimage/newkyc.jpg";
import logout from "../assest/homeimage/newlogout.png";

import addvenue from "../assest/homeimage/add.png";
import venuelist from "../assest/homeimage/vendorlist.png";
import history from "../assest/homeimage/history.png";
import query from "../assest/homeimage/query.png";
import manualcss from '../assest/css/manualcss.css';
import "../App.css"

import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import slider1 from "../assest/homeimage/slider2.png";
import slider2 from "../assest/homeimage/slider3.png";
import slider3 from "../assest/homeimage/v1.png";
import slider4 from "../assest/homeimage/v2.png";

import kyccss from "../FieldsExecutive/kyc.css";

import Slider from "react-slick";

const FE_Notification = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    //notification function
    const [getRows, setRows] = useState([]);
    const [loadData, setAData] = useState([]);

    const updateNotification = () => {
        //Update Notification table 
        axios.post(`${apiBaseUrl}/updateNotification`)
            .then((result) => {
                // setOpen(false)
                getLatestRowDetails();
            })
            .catch(() => {
                alert('Already Mark is read');
            });
    }

    const loadList = async () => {
        try {
            const id = localStorage.getItem("id");
            console.log(id);
            const result = await axios.get(`${apiBaseUrl}/list/${id}`);
            console.log("Result data:", result.data); // Log the fetched data
            setAData(result.data); // Set the fetched data to state
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle errors here
        }
    };

    // To get total count from backend
    const getLatestRowDetails = async () => {

        const results = await axios.get(`${apiBaseUrl}/getLatestRow`);
        console.log("Result data:", results.data);
        setRows(results.data);
    };


    useEffect(() => {
        loadList();
        getLatestRowDetails();
    }, []);

    const back = () => {

        navigate("/fields_executive_dashboard");
    }

    return (
        <>
            <main>
                <div className="page-title pageHead">
                    <button type="button" onClick={back} className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
                        <img src={arrowleftblack} alt="arrow" />
                    </button>
                    <h3 className="main-title mainTitleLight">Notification</h3>

                </div>
                {loadData && loadData.noti && (
                    <section className="notification">

                        <div>
                            {/* <h3 class="mb-32">Today</h3> */}
                            <ul style={{ paddingLeft: '0 !important' }}>
                                {loadData.noti.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <li className="d-flex gap-12" style={{ paddingLeft: '5px' }}>
                                            {/*<div className="image d-flex align-items-center justify-content-center rounded-full overflow-hidden shrink-0">
                                            <img src={avatar} alt="person" className="img-fluid h-100 w-100 object-fit-cover" />
                                </div>*/}
                                            {/* <div>
                                                <p style={{ marginBottom: "3px" }}><strong>{row.title} </strong> <small textAlign='right' right={0}
                                                >{new Date(row.created_at).toLocaleDateString()}</small></p>
                                                <small className="d-block">{row.desc}</small>
                                            </div> */}
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%', marginRight: '10px', }}>
                                                <div style={{ flex: "1" }}>
                                                    <p style={{ marginBottom: "3px" }}>
                                                        <strong>{row.title}</strong>
                                                    </p>
                                                    <small className="d-block">{row.desc}</small>
                                                </div>
                                                <div style={{ width: "100px", textAlign: "right" }}>
                                                    <small>{new Date(row.created_at).toLocaleDateString()}</small>
                                                </div>
                                            </div>




                                        </li>
                                        <hr style={{ width: '100%', paddingLeft: '0', paddingRight: '0' }} />
                                    </React.Fragment>
                                ))}

                            </ul>
                        </div>
                        <Box textAlign='right' position="fixed" bottom={0} right={0} p={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={updateNotification}
                            >
                                Mark as Read
                            </Button>
                        </Box>

                    </section>

                )}
            </main>

        </>
    );
};

export default FE_Notification;


