import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import homecss from "../assest/css/home.css";
import welcomecss from "../assest/css/welcome.css";
import commoncss from "../assest/css/common.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import ticket from "../assest/css/ticket.css";
import manualcss from '../assest/css/manualcss.css';

import arrowleftblack from "../assest/svg/arrow-left-black.svg";

const BookingDetails = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bookingCodeFromParams = searchParams.get("booking_code");

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    const fetchData = async () => {
        if (!apiBaseUrl || !bookingCodeFromParams) {
            console.error("API base URL or booking code is missing.");
            return;
        }
        try {
            const response = await axios.get(
                `${apiBaseUrl}/vendor_booking_details/${bookingCodeFromParams}`
            );
            setData(response.data);
            console.log("data", response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/vendor_dashboard");
            return;
        }
        fetchData();
    }, [apiBaseUrl, bookingCodeFromParams]);

    const back = () => {
        window.history.back();
    };

    const transformGameNames = (gameNamesString) => {
        if (!gameNamesString) return "N/A";
        try {
            const cleanedString = gameNamesString.slice(2, -2).replace(/\\/g, '');
            const gamesArray = cleanedString.split('","');
            return gamesArray.map(game => game.replace(/["\[\]]/g, '').trim().toLowerCase()).join(',');
        } catch (error) {
            console.error("Error transforming game names:", error);
            return "N/A";
        }
    };

    const completeBooking = async () => {
        const gstAmount = data?.booking_details?.total_gst_amount || "0";
        const bookingCode = data?.booking_details?.booking_code;

        if (!apiBaseUrl || !bookingCode) {
            console.error("API base URL or booking code is missing.");
            return;
        }

        try {
            await axios.post(
                `${apiBaseUrl}/completeTheBooking/${bookingCode}`,
                { amount: gstAmount }
            );
            navigate("/vendor_dashboard");
        } catch (error) {
            console.error("Error completing the booking:", error);
        }
    };

    const proceedToPayment = () => {
        completeBooking();
    };

    return (
        <div>
            <section className="budget">
                <div className="page-title pageHead">
                    <button
                        onClick={back}
                        type="button"
                        className="white_button btn back-page-btn h-80 w-60 d-flex align-items-center justify-content-center rounded-full"
                    >
                        <img src={arrowleftblack} alt="arrow" />
                    </button>
                    <h3 className="main-title mainTitleLight">Booking Details</h3>
                </div>

                {data && (
                    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                        <div style={{ textAlign: "center", marginBottom: '15px' }}>
                            <img
                                style={{ borderRadius: '20px', boxShadow: '5px 5px 5px gray' }}
                                src={`${apiImageUrl}/venue_image/${data.booking_details?.venue_images}`}
                                width="90%"
                                alt="Venue"
                            />
                        </div>
                        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <h5>{data.booking_details?.venue_name || "N/A"}</h5>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: "space-between" }}>
                            <p style={{ fontSize: '18px' }}>Booking Code:</p>
                            <span style={{ fontSize: '16px' }}>{data.booking_details?.booking_code || "N/A"}</span>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: "space-between" }}>
                            <p style={{ fontSize: '18px' }}>Booking Date:</p>
                            <p style={{ fontSize: '16px' }}>{data.booking_details?.booking_date || "N/A"}</p>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: "space-between" }}>
                            <div><p style={{ fontSize: '18px' }}>Slot Timing</p></div>
                            <div style={{ marginBottom: '0px' }}>
                                <ul style={{ marginBottom: '0px' }}>
                                    {data.booking_details?.slots?.map((slot, index) => (
                                        <li key={index} style={{ marginBottom: '0px' }}>
                                            <div>
                                                <p style={{ fontSize: '16px', marginBottom: '5px', }}>{slot.slot_start_time} - {slot.slot_end_time}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: "space-between" }}>
                            <p style={{ fontSize: '18px' }}>Total Amount:</p>
                            <p style={{ fontSize: '16px' }}>₹{data.booking_details?.total_amount || "N/A"} +</p>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: "space-between" }}>
                            <p style={{ fontSize: '18px' }}>Platform Charges:</p>
                            <p style={{ fontSize: '16px' }}>₹{data.booking_details?.platform_charges || "0"} +</p>
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: "space-between" }}>
                            <p style={{ fontSize: '18px' }}>Final Amount:</p>
                            <p style={{ fontSize: '16px' }}><strong>₹{data.booking_details?.total_gst_amount || "N/A"}</strong></p>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <button
                                onClick={proceedToPayment}
                                style={{
                                    width: "90%",
                                    padding: '10px 20px',
                                    fontSize: '16px',
                                    backgroundColor: '#0e795d',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer'
                                }}
                            >
                                Complete The Booking
                            </button>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
};

export default BookingDetails;
