import React, { useEffect, useState } from 'react'
import bootstrapcss from '../assest/css/bootstrap.min.css'
import swipercss from '../assest/css/swiper-bundle.min.css'
import jquerycss from '../assest/css/jquery-ui.min.css'
import commoncss from '../assest/css/common.css'
import welcomecss from '../assest/css/welcome.css'
import animationscss from '../assest/css/animations.css'
import authcss from '../assest/css/auth.css'

import buttonswitch from '../assest/css/buttonswitch.css';
import skillintrest from '../assest/css/skillintrest.css';
import venuecss from '../assest/css/venue.css';
import x from '../assest/images/profile/close.png';

import firstaid from '../assest/svg/facility/firstaid.svg'
import frestroom from '../assest/svg/facility/frestroom.svg'
import fhotel from '../assest/svg/facility/fhotel.svg'
import fparking from '../assest/svg/facility/fparking.svg'

import arrowleftblack from "../assest/svg/arrow-left-black.svg";

import axios from 'axios'

import { Link, Navigate, useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom'

import Select from 'react-select';



const AddEvent = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const vendorIdFromParams = searchParams.get("id1");
    const id = localStorage.getItem('id');
    const id1 = localStorage.getItem("id2");
    const [flashMessage, setFlashMessage] = useState(false);
    console.log("idddddddd", id);

    const [vendorId, setVendorId] = useState(vendorIdFromParams);

    console.log("VVVVVVVVVV", id1);

    const [data, setData] = useState(null);


    const [gameType, setGameType] = useState(null);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();

    // const id1 = localStorage.getItem("id1");
    console.log("")
    console.log(id1);


    const [turfData, setTurfData] = useState({
        vendor_id: id1,
        title: "",
        description: "",
        date: "",
        start_time: "",
        end_time: "",
        price: "",
        location: "",
        locationLink: "",
        banner: "",
        vendor_id: id1,
    });


    useEffect(() => {
        // Replace icons after component is mounted




        const token = localStorage.getItem('token');

        if (token == null) {

            navigate('/user_sign');

        }
        console.log(token)
        // feather.replace();

        // fetchData();
    }, []);

    const handleChange = (event) => {
        // Check if event is defined
        if (!event || !event.target) {
            // If event or event.target is undefined, return early or handle the error
            console.error("Event or event.target is undefined");
            return;
        }

        const { name, value, files, type, selectedOptions } = event.target;

        if (type === "select-multiple") {
            // Handle multi-select change for sports and facilities
            const selectedValues = Array.from(
                selectedOptions || [],
                (option) => option.value
            );
            setTurfData({
                ...turfData,
                [name]: selectedValues,
            });
        } else if (name === "banner") {
            // Handle file input
            const selectedFile = files[0]; // Use files instead of selectedOptions
            const allowedFileTypes = [
                "image/jpeg",
                "image/png",
                "image/jpg",
                "application/pdf",
            ];
            if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
                // If the file type is allowed, update the form data with the file
                setTurfData({
                    ...turfData,
                    [name]: selectedFile,
                });
            } else {
                alert("Please upload a file with JPEG, PDF, PNG, or JPG format.");
                // If the file type is not allowed, reset the file input
                event.target.value = "";
                console.log("Invalid file type");
            }
        } else {
            // For other inputs, update the form data with the input value
            setTurfData({
                ...turfData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            for (const key in turfData) {
                formDataToSend.append(key, turfData[key])
            }
            console.log('Form Data:', turfData);
            console.log("form data to send :", formDataToSend)

            for (let [key, value] of formDataToSend.entries()) {
                console.log(key + ': ' + value);
            }
            const response = await axios.post(`${apiBaseUrl}/createEvent`,
                formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );

            // Show flash message
            setFlashMessage(true);

            // Hide flash message after 3 seconds and navigate
            setTimeout(() => {
                setFlashMessage(false);
                navigate("/vendor_dashboard");
            }, 3000);
        } catch (error) {
            console.log("Error while creating account", error);
        }
    };

    return (
        <div>
            {flashMessage && (
                <div className="popup">
                    <div className="popup-content">
                        Form submitted successfully!
                    </div>
                </div>
            )}
            <>
                <main>
                    <section className="auth signin">
                        <div className="page-title pageHead">
                            <button
                                type="button"
                                className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                            >
                                <Link to="/vendor_event">
                                    <img src={arrowleftblack} alt="arrow" />
                                </Link>

                            </button>
                            <h3 className="main-title mainTitleLight">Add Event</h3>
                        </div>
                        <div className="form-area auth-form">
                            <form onSubmit={handleSubmit}>
                                <div className="icon-container">
                                    <div>
                                        <label htmlFor="lemail1">Event Title <span class="required"></span></label>
                                        <input
                                            type="text"
                                            id="title"
                                            name="title"
                                            className="input-field"
                                            placeholder="Enter the Event Title"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lemail1">Event Description <span class="required"></span></label>
                                        <input
                                            type="text"
                                            id="description"
                                            name="description"
                                            className="input-field"
                                            placeholder="Enter the Event Description"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="banner">Event Banner <span class="required"></span></label>
                                        <input
                                            type="file"
                                            id="banner"
                                            name="banner"
                                            className="input-field"
                                            placeholder="Add Event Banner with App logo"
                                            style={{ padding: "8px" }}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lemail1">Event Date <span class="required"></span></label>
                                        <input
                                            type="date"
                                            id="date"
                                            name="date"
                                            className="input-field"
                                            placeholder="Enter Your Event Date"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lemail1">Event Start Time <span class="required"></span></label>
                                        <input
                                            type="time"
                                            id="start_time"
                                            name="start_time"
                                            className="input-field"
                                            placeholder="Enter Your Event Start Time"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lemail1">Event End Time<span class="required"></span></label>
                                        <input
                                            type="time"
                                            id="end_time"
                                            name="end_time"
                                            className="input-field"
                                            placeholder="Enter Your Event End Time"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lemail1">Event Price<span class="required"></span></label>
                                        <input
                                            type="text"
                                            id="price"
                                            name="price"
                                            className="input-field"
                                            placeholder="Enter Your Event Price"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lemail1">Event Location<span class="required"></span></label>
                                        <input
                                            type="text"
                                            id="location"
                                            name="location"
                                            className="input-field"
                                            placeholder="Enter Your Event Location"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lemail1">Event Location Link <span class="required"></span></label>
                                        <input
                                            type="text"
                                            id="locationLink"
                                            name="locationLink"
                                            className="input-field"
                                            placeholder="Enter Your Event Location Link. eg. google map"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <button className="btn-primary" style={{ marginTop: "15px" }}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                </main>
            </>
            <style jsx>{`
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .popup-content {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          font-size: 1.5rem;
          text-align: center;
        }
      `}</style>
        </div>
    );
}

export default AddEvent;


