import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { Tabs, Tab, Box, Typography } from "@mui/material";
import Slider from "react-slick";
import manualcss from '../assest/css/manualcss.css';
import ssk from "../assest/recommendationimage/ssk.svg";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import commoncss from "../assest/css/common.css";
import vendorBookingCss from "../assest/css/vendor_booking.css"; // Corrected the path
import kyccss from "../FieldsExecutive/kyc.css";
import plus from '../assest/svg/plus-black.png';
import eventCSS from '../assest/css/event.css';

import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BookIcon from '@mui/icons-material/Book'; // Import the booking icon

import arrowleftblack from "../assest/svg/arrow-left-black.svg";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const VendorEvent = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [data, setData] = useState({ activeEvent: [], completedEvent: [], pendingEvent: [] });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const loadList = async () => {
        try {
            const vendor_id = localStorage.getItem("id2");
            console.log('view id', vendor_id);
            const result = await axios.get(`${apiBaseUrl}/activeEvent/${vendor_id}`);
            setData((prevData) => ({ ...prevData, activeEvent: result.data.activeEvent || [] }));
            console.log("Result data:", result.data);
        } catch (error) {
            console.error("Error fetching booking data:", error);
        }
    };

    const comBook = async () => {
        try {
            const vendor_id = localStorage.getItem("id2");
            console.log('view id', vendor_id);
            const result = await axios.get(`${apiBaseUrl}/completedEvent/${vendor_id}`);
            setData((prevData) => ({ ...prevData, completedEvent: result.data.completedEvent || [] }));
            console.log("Result data:", result.data);
        } catch (error) {
            console.error("Error fetching booking data:", error);
        }
    };
    const payBook = async () => {
        try {
            const vendor_id = localStorage.getItem("id2");
            console.log('view id', vendor_id);
            const result = await axios.get(`${apiBaseUrl}/pendingEvent/${vendor_id}`);
            setData((prevData) => ({ ...prevData, pendingEvent: result.data.pendingEvent || [] }));
            console.log("Result data:", result.data);
        } catch (error) {
            console.error("Error fetching booking data:", error);
        }
    };

    useEffect(() => {
        loadList();
        comBook();
        payBook();
    }, []);

    const back = () => {
        navigate("/vendor_dashboard");
    };

    const addEvent = () => {
        navigate("/add_event");
    };

    const editEvent = () => {
        navigate("/eidt_event");
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/vendor_signin");
    };

    return (
        <main>
            <div style={{ marginBottom: "80px" }}>
                <div className="page-title pageHead">
                    <button
                        onClick={back}
                        type="button"
                        className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                    >
                        <img src={arrowleftblack} alt="arrow" />
                    </button>
                    <h3 className="main-title mainTitleLight">Event</h3>
                    <button
                        onClick={addEvent}
                        type="button"
                        className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
                        <img src={plus} alt="arrow" />
                    </button>
                </div>
                <div>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" sx={{
                        '& .Mui-selected': {
                            color: '#0e795d',
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#0e795d',
                        },
                    }}>
                        <Tab className="bookingTabes" label="Upcoming" {...a11yProps(0)} />
                        <Tab className="bookingTabes" label="Completed" {...a11yProps(1)} />
                        <Tab className="bookingTabes" label="Pending" {...a11yProps(2)} />
                        {/* <Tab className="bookingTabes" label="All Events" {...a11yProps(3)} /> */}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        {data.activeEvent.length ? (
                            <ul style={{ paddingLeft: 0 }}>
                                {data.activeEvent.map((row, index) => (
                                    <li key={index}>
                                        <section
                                            className="message "
                                            style={{
                                                marginTop: "0px !important",
                                                marginBottom: "-5px !important",
                                            }}
                                        >
                                            <div className="single-chat position-relative">
                                                <a
                                                    style={{ textDecoration: "none", border: '1px solid #e3e3e3', margin: '4px 3px 10px 3px', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                                                    className="main_a_tag single-main d-flex align-items-center justify-content-between radius-12"
                                                >
                                                    <div className="shrink-0 d-flex align-items-center">
                                                        <div className="image shrink-0 position-relative a_tag_img" style={{ marginRight: '8px' }}>
                                                            <img
                                                                src={`https://turf.ukvalley.com/public/event/${row.images}`}
                                                                alt="Avatar"
                                                                className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                                                            />
                                                        </div>
                                                        <div className="list-container" style={{ width: '180px', marginRight: '3px' }}>
                                                            <h6 style={{ width: '130px', fontSize: "15px", color: 'black' }}>{row.title}</h6>
                                                            <p style={{ width: '130px' }}>{row.date} -- {row.start_time} - {row.end_time}</p>
                                                        </div>
                                                    </div>

                                                    <div className="text-end buttons_container list-container" style={{ marginRight: '1px !important' }}>
                                                        <Link
                                                            to={`/edit_event?id=${row.id}`}
                                                            style={{ textDecoration: "none", display: 'flex', justifyContent: "end" }}
                                                        >
                                                            <p className="edit_event" style={{ color: '#065e1c', }}>Edit</p>
                                                        </Link>
                                                        <p>Rs. {row.price}</p>


                                                    </div>
                                                </a>
                                            </div>
                                        </section>
                                        {/* <div className="horizontal-line"></div> */}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <Typography>No upcoming Event.</Typography>
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1} style={{ overflowX: 'hidden' }}>
                        {data.completedEvent.length ? (
                            <ul style={{ paddingLeft: 0 }}>
                                {data.completedEvent.map((row, index) => (
                                    <li key={index}>
                                        <section
                                            className="message "
                                            style={{
                                                marginTop: "0px !important",
                                                marginBottom: "-5px !important",
                                            }}
                                        >
                                            <div className="single-chat position-relative">
                                                <a
                                                    style={{ textDecoration: "none", border: '1px solid #e3e3e3', margin: '4px 3px 10px 3px', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                                                    className="single-main d-flex align-items-center justify-content-between radius-12"
                                                >
                                                    <div className="shrink-0 d-flex align-items-center">
                                                        <div className="image shrink-0 position-relative" style={{ marginRight: '8px' }}>
                                                            <img
                                                                src={`https://turf.ukvalley.com/public/event/${row.images}`}
                                                                alt="Avatar"
                                                                className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                                                            />
                                                        </div>
                                                        <div className="list-container" style={{ marginRight: '3px' }}>
                                                            <h6 className="truncate-text" style={{ color: 'black', width: '170px', fontSize: "15px" }}>{row.title}</h6>
                                                            <p style={{ width: '100px' }}>{row.date} </p>
                                                        </div>
                                                    </div>

                                                    <div className="text-end buttons_container list-container" style={{ marginRight: '1px !important' }}>
                                                        <p>Rs. {row.price}</p>
                                                        <p>{row.start_time} - {row.end_time}</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </section>
                                        {/* <div className="horizontal-line"></div> */}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <Typography>No complete event.</Typography>
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {data.pendingEvent.length ? (
                            <ul style={{ paddingLeft: 0 }}>
                                {data.pendingEvent.map((row, index) => (
                                    <li key={index}>
                                        <section
                                            className="message "
                                            style={{
                                                marginTop: "0px !important",
                                                marginBottom: "-5px !important",
                                            }}
                                        >
                                            <div className="single-chat position-relative">
                                                <a
                                                    style={{ textDecoration: "none", border: '1px solid #e3e3e3', margin: '4px 3px 10px 3px', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                                                    className="single-main d-flex align-items-center justify-content-between radius-12"
                                                >
                                                    <div className="shrink-0 d-flex align-items-center">
                                                        <div className="image shrink-0 position-relative" style={{ marginRight: '8px' }}>
                                                            <img
                                                                src={`https://turf.ukvalley.com/public/event/${row.images}`}
                                                                alt="Avatar"
                                                                className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                                                            />
                                                        </div>
                                                        <div className="list-container" style={{ width: '170px', marginRight: '3px' }}>
                                                            <h6 className="truncate-text" style={{ color: 'black', maxWidth: '100%', fontSize: "15px" }}>{row.title}</h6>
                                                            <p>{row.date} </p>
                                                        </div>
                                                    </div>

                                                    <div className="text-end buttons_container list-container" style={{ marginRight: '1px !important' }}>
                                                        <p>Rs. {row.price}</p>
                                                        <p>{row.status}</p>

                                                    </div>
                                                </a>
                                            </div>
                                        </section>
                                        {/* <div className="horizontal-line"></div> */}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <Typography>No pending event.</Typography>
                        )}
                    </TabPanel>
                </div>
            </div>
            {/* Footer */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "#fff",
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-around",
                    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                }}
            >
                <Link to="/vendor_dashboard">
                    <IconButton>
                        <HomeIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_bookings"> {/* Add the booking link */}
                    <IconButton>
                        <BookIcon /> {/* Use the booking icon */}
                    </IconButton>
                </Link>
                <Link to="/vendor_addvenue">
                    <IconButton>
                        <AddCircleIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_query">
                    <IconButton>
                        <QuestionAnswerIcon />
                    </IconButton>
                </Link>
                <Link to="/vendorUpdate_profile">
                    <IconButton>
                        <PersonIcon />
                    </IconButton>
                </Link>
            </Box>
        </main>
    );
};

export default VendorEvent;
