import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Tabs, Typography, Box } from "@mui/material";

import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";

import IconSelector from "../assest/css/IconSelector.css";

import buttonswitch from "../assest/css/buttonswitch.css";
import skillintrest from "../assest/css/skillintrest.css";
import venuecss from "../assest/css/venue.css";
import x from "../assest/images/profile/close.png";

import axios from "axios";

import { Navigate } from "react-router-dom";



const EditExecutive = () => {
  const [data, setData] = useState(null);
  const [activeButton, setActiveButton] = useState("button1");
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [venderRegister, setVenderRegister] = useState({
    business_name: "",
    business_type: "",
    gst: "",
    location: "",
    ownername: "",
    owner_mobile: "",
    owner_email: "",
    // id: id,
  });

  const [kycUpdate, setKycUpdate] = useState({
    aadhar: "",
    aadharimg: "",
    pan: "",
    panimg: "",
    bank: "",
    accountname: "",
    accountnumber: "",
    // id: id1,
  });

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/showpageapi/${id}`);
      if (response.data) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const id = localStorage.getItem("id");

  const id1 = localStorage.getItem("id1");

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, type, value, files } = e.target;

    let acceptedFormats = [
      "image/jpeg",
      "application/pdf",
      "image/png",
      "image/jpg",
    ];

    switch (activeButton) {
      case "button1":
        setVenderRegister((prevState) => ({ ...prevState, [name]: value }));
        break;

      case "button2":
        if (name === "aadharimg" || name === "panimg") {
          const selectedFile = e.target.files[0];
          const allowedFileTypes = [
            "image/jpeg",
            "image/png",
            "image/jpg",
            "application/pdf",
          ];

          if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
            // If the file type is allowed, update the form data with the file
            // setKycUpdate({
            //     ...kycUpdate,
            //     [name]: selectedFile
            // });
            setKycUpdate((prevState) => ({
              ...prevState,
              [name]: selectedFile,
            }));
          } else {
            alert("Please upload a file with JPEG, PDF, PNG, or JPG format.");
            // If the file type is not allowed, reset the file input
            e.target.value = ""; // Reset the file input to clear the selected file
            // Display an error message or handle invalid file type as needed
            console.log("Invalid file type");
          }
        } else {
          // For non-file inputs, update the form data with the input value
          // setKycUpdate({
          //     ...kycUpdate,
          //     [name]: value
          // });

          setKycUpdate((prevState) => ({ ...prevState, [name]: value }));
        }

        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      if (activeButton === "button1") {
        // Handle submission for Vendor Register
      } else if (activeButton === "button2") {
        // Handle submission for KYC Updation
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <main className="auth-main">
        <section className="auth signin">
          <div className="heading" style={{ marginTop: "-60px" }}>
            <h2>Executive Data!</h2>
          </div>
          <div className="form-area auth-form">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={() => setActiveButton("button1")}
                className={`custom-buttonswitch ${
                  activeButton === "button1" ? "activeswitch" : ""
                }`}
              >
                Vendor Register
              </button>
              <button
                onClick={() => setActiveButton("button2")}
                className={`custom-buttonswitch ${
                  activeButton === "button2" ? "activeswitch" : ""
                }`}
              >
                KYC Updation
              </button>
            </div>
            {data && (
              <>
                {activeButton === "button1" && (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label htmlFor="lemail1">Business Name</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="bname"
                          name="bname"
                          placeholder="Enter Business Name"
                          className="input-field"
                          value={data.business_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="rpass">Business Type</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="btype"
                          name="btype"
                          placeholder="Enter Business Type"
                          className="input-field"
                          value={venderRegister.btype || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="rpass">GST</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="gst"
                          name="gst"
                          placeholder="Enter GSt"
                          className="input-field"
                          value={venderRegister.gst || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="rpass">Location</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="location"
                          name="location"
                          placeholder="Enter Location"
                          className="input-field"
                          value={venderRegister.location || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="rpass">Business Owner Name</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="bownername"
                          name="bownername"
                          placeholder="Enter Business Owner Name"
                          className="input-field"
                          value={venderRegister.bownername || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="rpass">Business Owner Mobile</label>
                      <div className="position-relative">
                        <input
                          type="number"
                          id="bownermobile"
                          name="bownermobile"
                          placeholder="Enter Business Owner Mobile"
                          className="input-field"
                          value={venderRegister.bownermobile || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="rpass">Business Owner Email</label>
                      <div className="position-relative">
                        <input
                          type="email"
                          id="bowneremail"
                          name="bowneremail"
                          placeholder="Enter Business Owner Email"
                          className="input-field"
                          value={venderRegister.bowneremail || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <button className="btn-primary">Submit</button>
                  </form>
                )}

                {activeButton === "button2" && (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label htmlFor="remail2">Aadhar Number</label>
                      <input
                        type="number"
                        id="aadhar"
                        name="aadhar"
                        placeholder="Enter your  Aadhar Number"
                        className="input-field d-block"
                        value={kycUpdate.aadhar || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="remail2">Aadhar</label>
                      <input
                        type="file"
                        id="aadharimg"
                        name="aadharimg"
                        placeholder="Upload Aadhar Image"
                        className="input-field d-block"
                        // value={kycUpdate.aadharimg  || ''}
                        style={{ padding: "8px" }}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="remail2">Pan Number</label>
                      <input
                        type="number"
                        id="pan"
                        name="pan"
                        placeholder="Enter  Pan Number"
                        className="input-field d-block"
                        value={kycUpdate.pan || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="remail2">Pan</label>
                      <input
                        type="file"
                        id="panimg"
                        name="panimg"
                        placeholder="Upload Pan Image"
                        className="input-field d-block"
                        style={{ padding: "8px" }}
                        // value={kycUpdate.panimg  || ''}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="remail2">Bank </label>
                      <input
                        type="text"
                        id="bank"
                        name="bank"
                        placeholder="Enter  Bank Name"
                        className="input-field d-block"
                        value={kycUpdate.bank || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="remail2">Account Name </label>
                      <input
                        type="text"
                        id="accountname"
                        name="accountname"
                        placeholder="Enter Account Holder Name"
                        className="input-field d-block"
                        value={kycUpdate.accountname || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="remail2">Account Number </label>
                      <input
                        type="text"
                        id="accountnumber"
                        name="accountnumber"
                        placeholder="Enter Account Number"
                        className="input-field d-block"
                        value={kycUpdate.accountnumber || ""}
                        onChange={handleChange}
                      />
                    </div>

                    <button className="btn-primary"> Submit Kyc</button>
                  </form>
                )}
              </>
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditExecutive;

