import React, { useEffect, useState } from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const VendorRules = () => {
    const navigate = useNavigate();
    const [flashMessage, setFlashMessage] = useState(false);
    const [data, setData] = useState(null);
    const [formData, setFormData] = useState({
        vendor_id: localStorage.getItem("id2"),
        rules: "",
        additional_terms: ""
    });

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/rulesView/${formData.rule_id}`);
            setData(response.data);
            console.log("data", response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (data && data.length > 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                rules: data[0].rules,
                additional_terms: data[0].additional_terms
            }));
        }
    }, [data]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token === null) {
            navigate("/vendor_signin");
        } else {
            fetchData();
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        console.log("Updated FormData:", { ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                formDataToSend.append(key, formData[key]);
            }
            console.log("FormData to send:", formDataToSend);

            await axios.post(`${apiBaseUrl}/rulesStore`, formDataToSend, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            // Show flash message
            setFlashMessage(true);

            // Hide flash message after 3 seconds and navigate
            setTimeout(() => {
                setFlashMessage(false);
                navigate("/view_rules");
            }, 3000);
        } catch (error) {
            console.log("Error while creating account", error);
        }
    };

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                formDataToSend.append(key, formData[key]);
            }
            console.log("FormData to send:", formDataToSend);

            await axios.post(`${apiBaseUrl}/updateRules`, formDataToSend, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            fetchData();
        } catch (error) {
            console.log("Error while updating rules", error);
        }
    };

    const back = () => {
        navigate("/view_rules");
    };

    return (
        <div>
            {flashMessage && (
                <div className="popup">
                    <div className="popup-content">
                        Form submitted successfully!
                    </div>
                </div>
            )}
            <main>
                <section className="auth signin">
                    <div className="page-title pageHead">
                        <button
                            type="button"
                            className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                            onClick={back}
                        >

                            <img src={arrowleftblack} alt="arrow" />

                        </button>
                        <h3 className="main-title mainTitleLight">Add Rules and Terms</h3>
                    </div>
                    <div className="form-area auth-form" style={{ height: "80vh" }}>
                        {data && data.length > 0 ? (
                            <form onSubmit={handleSubmit1}>
                                <div className="icon-container">
                                    <div>
                                        <label htmlFor="rules">Add Rules</label>
                                        <textarea
                                            id="rules"
                                            name="rules"
                                            className="input-field"
                                            placeholder="Venue Rules"
                                            onChange={handleChange}
                                            value={formData.rules}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="additional_terms">Add Terms and Conditions</label>
                                        <textarea
                                            id="additional_terms"
                                            name="additional_terms"
                                            className="input-field"
                                            placeholder="Venue Terms and Conditions"
                                            onChange={handleChange}
                                            value={formData.additional_terms}
                                        />
                                    </div>
                                    <button type="submit" className="btn-primary" style={{ marginTop: '15px' }}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <div className="icon-container">
                                    <div>
                                        <label htmlFor="rules">Add Rules</label>
                                        <textarea
                                            id="rules"
                                            name="rules"
                                            className="input-field"
                                            placeholder="Venue Rules"
                                            onChange={handleChange}
                                            value={formData.rules}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="additional_terms">Add Terms and Conditions</label>
                                        <textarea
                                            id="additional_terms"
                                            name="additional_terms"
                                            className="input-field"
                                            placeholder="Venue Terms and Conditions"
                                            onChange={handleChange}
                                            value={formData.additional_terms}
                                        />
                                    </div>
                                    <button type="submit" className="btn-primary" style={{ marginTop: '15px' }}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </section>
            </main>
            <style jsx>{`
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .popup-content {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          font-size: 1.5rem;
          text-align: center;
        }
      `}</style>
        </div>
    );
};

export default VendorRules;
