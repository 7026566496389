import React, { useState } from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import eyeon from "../assest/svg/eye-on.svg";
import eyeoff from "../assest/svg/eye-off.svg";
import icongoogle from "../assest/svg/icon-google.svg";
import iconapple from "../assest/svg/icon-apple.svg";
import { Link, useNavigate } from "react-router-dom";
import otp from "../assest/homeimage/otp.png";
import manaualcss from "../assest/css/manualcss.css";
import axios from "axios";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import Modal from "../Credantails/modal";

const VendorSignup = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmpassword: ""
  });

  const [errors, setErrors] = useState({});
  const [showRequirements, setShowRequirements] = useState(false);

  const back = () => {
    navigate("/vendor_signin");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;

    if (!emailPattern.test(formData.email)) {
      newErrors.email = "Email is incorrect or invalid";
    }
    if (!phonePattern.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      localStorage.setItem(
        "modalMessage4",
        JSON.stringify({
          title: "Vendor Account Registered Successfully",
          content: "You have been successfully registered",
        })
      );

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const response = await axios.post(
        `${apiBaseUrl}/VendorRegister`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { token, user_id, user_type } = response.data;

      localStorage.setItem("token", token);
      localStorage.setItem("id2", user_id);
      localStorage.setItem("user_type", user_type);

      console.log("Vendor registered successfully:", formData);

      setShowSuccessMessage(true);

      setTimeout(() => {
        navigate("/vendor_signin");
      }, 3000); // Redirect after 3 seconds
    } catch (error) {
      console.log("Error while creating account", error);
      setModalMessage({
        title: "Error",
        content: "Failed to Register Account. Please try again later.",
      });
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const showPasswordRequirements = () => {
    setShowRequirements(true);
  };

  const hidePasswordRequirements = () => {
    setShowRequirements(false);
  };

  return (
    <div>
      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
      <main>
        <section className="auth signin">
          <div className="page-title pageHead">
            <button
              onClick={back}
              type="button"
              className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowleftblack} alt="arrow" />
            </button>
            <h3 className="main-title mainTitleLight">Vendor Register</h3>
          </div>

          <div className="form-area auth-form">
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-column">
                <div>
                  <label htmlFor="name">
                    Name <span className="required"></span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    className="input-field d-block"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email">
                    Email <span className="required"></span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email address"
                    className="input-field d-block"
                    onChange={handleChange}
                    required
                  />
                  {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                </div>
                <div>
                  <label htmlFor="phone">
                    Mobile Number <span className="required"></span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Enter your mobile number"
                    className="input-field d-block"
                    onChange={handleChange}
                    required
                  />
                  {errors.phone && <div style={{ color: 'red', fontSize: "14px", margin: '0 0 2px 15px' }}><span className="required"></span> {errors.phone}</div>}
                </div>
                <div>
                  <label htmlFor="password">
                    Password <span className="required"></span>
                  </label>
                  <div className="position-relative">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      data-pssws-shown="false"
                      placeholder="Enter your password"
                      className="input-psswd input-field d-block"
                      onChange={handleChange}
                      onFocus={showPasswordRequirements}
                      onBlur={hidePasswordRequirements}
                      required
                    />
                    {showRequirements && (
                      <div id="password-requirements" style={{ background: 'white', border: '1px solid gray', boxShadow: '2px 2px 5px gray', padding: '2px 5px', borderRadius: '10px', margin: '2px 5px 5px 5px' }}>
                        <p style={{ fontSize: '16px', color: "red" }}><strong>Note:</strong> Password must contain</p>
                        <ul style={{ paddingLeft: "8px" }}>
                          <li style={{ color: "red" }}>Minimum length of 8 characters</li>
                          <li style={{ color: "red" }}>At least one lowercase letter</li>
                          <li style={{ color: "red" }}>At least one uppercase letter</li>
                          <li style={{ color: "red" }}>At least one digit</li>
                          <li style={{ color: "red" }}>At least one special character (@, $, !, %, *, #, ?, &)</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label htmlFor="confirmpassword">
                    Confirm Password <span className="required"></span>
                  </label>
                  <div className="position-relative">
                    <input
                      type="password"
                      id="confirmpassword"
                      name="confirmpassword"
                      data-pssws-shown="false"
                      placeholder="Confirm your password"
                      className="input-psswd input-field d-block"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <button type="submit" className="btn-primary">
                Register
              </button>
            </form>
            <h6>
              Already have an account? <Link to="/vendor_signin">Sign In</Link>
            </h6>
          </div>
        </section>
      </main>
    </div>
  );
};

export default VendorSignup;
