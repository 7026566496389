import React, { useEffect, useState } from "react";

import bootstrapcss from "../assest/css/bootstrap.min.css";
import commoncss from "../assest/css/common.css";
import mapmarker from "../assest/svg/map-marker.svg";
import bellblack from "../assest/svg/bell-black.svg";
import executiveprofile from "../assest/images/profile/executive.png";
import kyc1 from "../assest/homeimage/ky1.svg";
import kyc2 from "../assest/homeimage/newkyc.jpg";
import logout from "../assest/homeimage/newlogout.png";
import addvenue from "../assest/homeimage/add.png";
import venuelist from "../assest/homeimage/vendorlist.png";
import history from "../assest/homeimage/history.png";
import query from "../assest/homeimage/query.png";
import avatar from '../assest/images/profile/avatar.png'
import arrowleftblack from "../assest/svg/arrow-left-black.svg";

import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import slider1 from "../assest/homeimage/slider2.png";
import slider2 from "../assest/homeimage/slider3.png";
import slider3 from "../assest/homeimage/v1.png";
import slider4 from "../assest/homeimage/v2.png";

import kyccss from "../FieldsExecutive/kyc.css";

import Slider from "react-slick";

const BookingRequest = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const handleLogout = () => {

    localStorage.removeItem("token");


    navigate("/vendor_signin");
  };


  return (
    <div>
      <>
        <main className="tour-guide">
          <div className="page-title" style={{

          }}>
            <button
              type="button"
              className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <Link to="/fields_executive_dashboard">
                <img src={arrowleftblack} alt="arrow" />
              </Link>
            </button>

            <h3 className="main-title">Booking Request</h3>
          </div>
          <section className="message " style={{ marginTop: "0px !important", marginBottom: "-5px !important", }}>

            <div className="single-chat position-relative">
              <a
                style={{ textDecoration: "none" }}
                className="single-main d-flex align-items-center justify-content-between gap-04 radius-12"
              >
                <div className="shrink-0 d-flex align-items-center gap-12">
                  <div className="image shrink-0 position-relative">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                    />
                  </div>
                  <div className="list-container">
                    <h4>John Deo</h4>
                    <p>Rs. 1000</p>
                  </div>

                </div>

                <div className="text-end booking-request-container">

                  <span
                    className="d-inline-block text-center"

                    style={{
                      width: "70px",
                      height: "30px",
                      borderRadius: "5px",
                      padding: "2px",
                      textAlign: "center",
                      backgroundColor: "#01A669",
                      marginRight:'3px',

                    }}
                  >
                    <a>Confirm</a>
                  </span>

                  <span
                    className="d-inline-block text-center"

                    style={{
                      width: "70px",
                      height: "30px",
                      borderRadius: "5px",
                      padding: "2px",
                      textAlign: "center",
                      backgroundColor: '#ddd',
                      color: '#212529'
                    }}
                  >
                    <a>Delete</a>
                  </span>
                </div>
              </a>

              <button type="button" className="trash">
                <img
                  src="../../assets/svg/trash-red.svg"
                  alt="trash"
                />
              </button>
            </div>
          </section >
          <section className="message " style={{ marginTop: "0px !important", marginBottom: "-5px !important", }}>

            <div className="single-chat position-relative">
              <a
                style={{ textDecoration: "none" }}
                className="single-main d-flex align-items-center justify-content-between gap-04 radius-12"
              >
                <div className="shrink-0 d-flex align-items-center gap-12">
                  <div className="image shrink-0 position-relative">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                    />
                  </div>
                  <div className="list-container">
                    <h4>John Deo</h4>
                    <p>Rs. 1000</p>
                  </div>

                </div>

                <div className="text-end booking-request-container">

                  <span
                    className="d-inline-block text-center"

                    style={{
                      width: "70px",
                      height: "30px",
                      borderRadius: "5px",
                      padding: "2px",
                      textAlign: "center",
                      backgroundColor: "#01A669",
                      marginRight:'3px',

                    }}
                  >
                    <a>Confirm</a>
                  </span>

                  <span
                    className="d-inline-block text-center"

                    style={{
                      width: "70px",
                      height: "30px",
                      borderRadius: "5px",
                      padding: "2px",
                      textAlign: "center",
                      backgroundColor: '#ddd',
                      color: '#212529'
                    }}
                  >
                    <a>Delete</a>
                  </span>
                </div>
              </a>

              <button type="button" className="trash">
                <img
                  src="../../assets/svg/trash-red.svg"
                  alt="trash"
                />
              </button>
            </div>
          </section >
          <section className="message " style={{ marginTop: "0px !important", marginBottom: "-5px !important", }}>

            <div className="single-chat position-relative">
              <a
                style={{ textDecoration: "none" }}
                className="single-main d-flex align-items-center justify-content-between gap-04 radius-12"
              >
                <div className="shrink-0 d-flex align-items-center gap-12">
                  <div className="image shrink-0 position-relative">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                    />
                  </div>
                  <div className="list-container">
                    <h4>John Deo</h4>
                    <p>Rs. 1000</p>
                  </div>

                </div>

                <div className="text-end booking-request-container">

                  <span
                    className="d-inline-block text-center"

                    style={{
                      width: "70px",
                      height: "30px",
                      borderRadius: "5px",
                      padding: "2px",
                      textAlign: "center",
                      backgroundColor: "#01A669",
                      marginRight:'3px',

                    }}
                  >
                    <a>Confirm</a>
                  </span>

                  <span
                    className="d-inline-block text-center"

                    style={{
                      width: "70px",
                      height: "30px",
                      borderRadius: "5px",
                      padding: "2px",
                      textAlign: "center",
                      backgroundColor: '#ddd',
                      color: '#212529'
                    }}
                  >
                    <a>Delete</a>
                  </span>
                </div>
              </a>


            </div>
          </section >
        </main>
      </>
    </div >
  );
};

export default BookingRequest;
