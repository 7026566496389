import bundle from "../assest/assets-demo/css/style.css";
import App from "../App.css";
import homecss from "../assest/css/home.css";
import welcomecss from "../assest/css/welcome.css";
import commoncss from "../assest/css/common.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import ticket from "../assest/css/ticket.css";
import avatar from "../assest/images/profile/avatar.png";
import cricketimg from "../assest/homeimage/cricket.png";
import footballimg from "../assest/homeimage/soccer-ball.png";
import volleyballimg from "../assest/homeimage/volleyball.png";
import category from "../assest/images/home/category.png";

import humberg from "../assest/svg/menu/burger-white.svg";
import sunwhite from "../assest/svg/menu/sun-white.svg";
import moonwhite from "../assest/svg/menu/moon-white.svg";
import profilewhite from "../assest/svg/menu/profile-white.svg";
import search from "../assest/svg/search.svg";
import filterblack from "../assest/svg/filter-black.svg";
import {
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

import ssk from "../assest/recommendationimage/ssk.svg";
import chakde from "../assest/recommendationimage/chakde.svg";
import runbumi from "../assest/recommendationimage/runbhumi.svg";
import stumps from "../assest/recommendationimage/stonch.svg";
import startyellow from "../assest/svg/star-yellow.svg";
import axios from "axios";
import manualcss from '../assest/css/manualcss.css';
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import React, { useState, useEffect } from "react";
import plus from '../assest/svg/plus-white.svg';

const VenueList = () => {
  const navigate = useNavigate();

  const back = () => {
    navigate("/vendor_list");
  };

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const [data, setData] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const vendorIdFromParams = searchParams.get("id");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/show_Venue/${vendorIdFromParams}`
      );
      setData(response.data);
      console.log("data", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("token", token);
    if (token == null) {
      navigate("/fieds_executive_dashboard");
    }
    fetchData();
  }, []);

  const addVenue = (venue) => {
    const encodedId = encodeURIComponent(venue.vendor_id);
    localStorage.setItem("IDDDDDDDD", venue.vendor_id);
    navigate(`/add_venue?id=${encodedId}`);
  };

  const seeSlots = (venue) => {
    const encodeId = encodeURIComponent(venue.id);
    localStorage.setItem("turf_id", venue.id);
    navigate(`/executive_viewslot?id=${encodeId}`);
  };

  const updateVenue = (venue) => {
    navigate(`/update_venue`, { state: { venueData: venue } });
  };

  const transformGameNames = (gameNamesString) => {
    if (!gameNamesString) return "N/A"; // Handle undefined or null input

    try {
      const cleanedString = gameNamesString.slice(2, -2).replace(/\\/g, '');

      // Split the cleaned string by commas to create an array
      const gamesArray = cleanedString.split('","');

      // Remove any leading/trailing quotes and whitespace
      const cleanedArray = gamesArray.map(game => game.replace(/["\[\]]/g, '').trim().toLowerCase());

      // Join the array elements into a comma-separated string
      return cleanedArray.join(',');
    } catch (error) {
      console.error("Error transforming game names:", error);
      return "N/A"; // Fallback value in case of an error
    }
  };

  return (
    <div>
      <section className="budget">
        <div className="page-title pageHead">
          <button
            type="button"
            className="white_button btn back-page-btn h-80 w-60 d-flex align-items-center justify-content-center rounded-full"
          >
            <Link to="/vendor_list">
              <img src={arrowleftblack} alt="arrow" />
            </Link>
          </button>

          <h3 className="main-title mainTitleLight">Venue List</h3>
        </div>
        <Link to="/add_venue">
          <button
            type="button"
            className="add-chat d-flex align-items-center justify-content-center rounded-full"
          >
            <img src={plus} alt="plus" />
          </button>
        </Link>

        {data ? (
          <ul style={{ padding: "0px" }}>
            {data.venues.map((venue, index) => (
              <li key={index}>
                <section
                  className="message"
                  style={{
                    marginTop: "0px !important",
                    marginBottom: "-5px !important",
                  }}
                >
                  <div className="single-chat position-relative">
                    <a
                      style={{ textDecoration: "none", margin: '5px 10px 10px 10px', border: '1px solid #e3e3e3', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                      className="single-main d-flex align-items-center justify-content-between radius-12"
                    >
                      <div className="shrink-0 d-flex align-items-center gap-2">
                        <div className="image shrink-0 position-relative">
                          <img
                            src={`${apiImageUrl}/venue_image/${venue.images}`}
                            alt="Avatar"
                            className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                          />
                        </div>
                        <div className="list-container" style={{ width: '180px', marginRight: '3px' }}>
                          <h4 style={{ fontSize: "15px" }}>{venue.name}</h4>
                          <p style={{ width: '190px' }}>Games: {transformGameNames(venue.game_names)}</p>
                        </div>
                      </div>

                      <div className="text-end buttons_container list-container">
                        <h5 className="pb-9">
                          <span
                            className="d-inline-block text-center"
                            onClick={() => updateVenue(venue)}
                            style={{
                              width: "70px",
                              borderRadius: "5px",
                              padding: "0px 2px 2px 2px",
                              textAlign: "center",
                            }}
                          >
                            <a>Edit Venue</a>
                          </span>
                        </h5>
                      </div>
                    </a>
                  </div>
                </section>

                <button
                  type="button"
                  onClick={() => addVenue(venue)}
                  className="add-chat d-flex align-items-center justify-content-center rounded-full"
                >
                  <img src={plus} alt="plus" />
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <div className="addvenue" style={{ textAlign: "center" }}>
            <button onClick={addVenue}>Add Venue</button>
          </div>
        )}
      </section>
    </div>
  );
};

export default VenueList;
