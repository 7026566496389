import React from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";

import slotscss from '../slots/slot_list.css';

const SlotsList = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="cardslots">
            <div className="cardslots-headerslots">
              <h5 className="cardslots-title">Slots</h5>
            </div>
            <div className="cardslots-bodyslots">
              <div className="col" style={{ display: "contents" }}>
                <div className="mb-3">
                  <p className="mb-0">Start Time:</p>
                  <p className="time">8:00 AM</p>
                </div>
                <div className="mb-3">
                  <p className="mb-0">End Time:</p>
                  <p className="time">5:00 PM</p>
                </div>
              </div>
            </div>
            <div className="cardslots-footerslots  ">
              <button className="btnslots btnslots-customslots ml-2">
                Update
              </button>
              {/* Delete button with custom class and margin */}
              <button className="btnslots ml-2 justify-content-end">
                Delete
              </button>
              {/* Update button with custom class */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlotsList;


