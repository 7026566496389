import React from 'react'
import bundle from '../assest/assets-demo/css/style.css'
import App from '../App.css'
import homecss from '../assest/css/home.css'
import welcomecss from '../assest/css/welcome.css'
import commoncss from '../assest/css/common.css'
import jquerycss from '../assest/css/jquery-ui.min.css'
import swipercss from '../assest/css/swiper-bundle.min.css'
import bootstrapcss from '../assest/css/bootstrap.min.css'
import ticket from '../assest/css/ticket.css'

 import cricketimg from  '../assest/homeimage/cricket.png'
 import footballimg  from '../assest/homeimage/soccer-ball.png'
 import volleyballimg from '../assest/homeimage/volleyball.png'
 import category from '../assest/images/home/category.png'

import humberg from  '../assest/svg/menu/burger-white.svg'
import sunwhite from  '../assest/svg/menu/sun-white.svg'
import moonwhite from '../assest/svg/menu/moon-white.svg'
import profilewhite from  '../assest/svg/menu/profile-white.svg'
 import search from '../assest/svg/search.svg'
  import filterblack from '../assest/svg/filter-black.svg'
  import { Link, useNavigate } from 'react-router-dom'
  
 import ssk from '../assest/recommendationimage/ssk.svg'
 import chakde from '../assest/recommendationimage/chakde.svg'
 import runbumi from '../assest/recommendationimage/runbhumi.svg'
 import stumps from '../assest/recommendationimage/stonch.svg'

const Recommendation = () => {
  return (
    <div>



   <section className="search py-12">
      <form action="#">
        <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24">
          <img
            src={search}
            alt="search"
            className="shrink-0"
          />
          <input
            type="search"
            className="input-search input-field"
            placeholder="Search..."
          />
          <div className="filter shrink-0">
            <button
              type="button"
              className="d-flex align-items-center"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
            >
              <img src={filterblack} alt="filter" />


            </button>
          </div>
        </div>
      </form>
    </section>


   <section className="budget pt-12">
      {/* title */}
      <div className="title d-flex align-items-center justify-content-between">
        <h2 className="shrink-0">Top Listed</h2>
        <a href="hotels.html" className="shrink-0 d-inline-block">
          See All
        </a>
      </div>

      <ul>
        {/* item 1 */}


        <li>
          <a
            href="hotel-details.html"
            className="d-flex align-items-center gap-12"
          >
         


 <div className="ticket-card radius-8">
            {/* card-title */}
            <div className="card-title d-flex align-items-center justify-content-between">
              <p>22 march 2022, Thu</p>
              <span>Watch Video</span>
            </div>
            {/* card-item */}
            <div className="card-item d-flex align-items-center gap-16 w-100">
              <div className="image shrink-0 overflow-hidden radius-8">
                <img
                  src={ssk}
                  alt="Place"
                  className="img-fluid w-100 h-100 object-fit-cover"
                />
              </div>
              <div className="content flex-grow">
                <h4>Runbumi Turf</h4>
                <p className="d-flex align-items-center gap-04 location mt-04">
                  {/* <img src="../assets/svg/map-marker.svg" alt="icon" /> */}
                  City Center Mall, Nashik
                </p>
                <p className="rating d-flex align-items-center gap-04 mt-16">
                  <img src="../assets/svg/star-yellow.svg" alt="icon" />
                  4.4 <span>(41 Reviews)</span>
                </p>
              </div>
            </div>
            {/* card-footer */}
            <div className="card-footer d-flex align-items-center justify-content-between">
              <div>
                <p>Total Price</p>
                <h3>$1000</h3>
              </div>
              <a href="ticket-detail.html">Detail</a>
            </div>
          </div>




          </a>
        </li>
        <hr></hr>
        
        {/* item 2 */}
        <li>
          <a
            href="hotel-details.html"
            className="d-flex align-items-center gap-12"
          >
 <div className="ticket-card radius-8">
            {/* card-title */}
            <div className="card-title d-flex align-items-center justify-content-between">
              <p>22 march 2022, Thu</p>
              <span>Watch Video</span>
            </div>
            {/* card-item */}
            <div className="card-item d-flex align-items-center gap-16 w-100">
              <div className="image shrink-0 overflow-hidden radius-8">
                <img
                  src={chakde}
                  alt="Place"
                  className="img-fluid w-100 h-100 object-fit-cover"
                />
              </div>
              <div className="content flex-grow">
                <h4>Chakde Turf</h4>
                <p className="d-flex align-items-center gap-04 location mt-04">
                  {/* <img src="../assets/svg/map-marker.svg" alt="icon" /> */}
                  Pakhal Road , Nashik
                </p>
                <p className="rating d-flex align-items-center gap-04 mt-16">
                  <img src="../assets/svg/star-yellow.svg" alt="icon" />
                  4.4 <span>(40 Reviews)</span>
                </p>
              </div>
            </div>
            {/* card-footer */}
            <div className="card-footer d-flex align-items-center justify-content-between">
              <div>
                <p>Total Price</p>
                <h3>$800</h3>
              </div>
              <a href="ticket-detail.html">Detail</a>
            </div>
          </div>
          </a>
        </li>
        <hr></hr>

 {/* item 3 */}
        <li>
          <a
            href="hotel-details.html"
            className="d-flex align-items-center gap-12"
          >
  


   <div className="ticket-card radius-8">
            {/* card-title */}
            <div className="card-title d-flex align-items-center justify-content-between">
              <p>22 march 2022, Thu</p>
              <span>Watch Video</span>
            </div>
            {/* card-item */}
            <div className="card-item d-flex align-items-center gap-16 w-100">
              <div className="image shrink-0 overflow-hidden radius-8">
                <img
                  src={stumps}
                  alt="Place"
                  className="img-fluid w-100 h-100 object-fit-cover"
                />
              </div>
              <div className="content flex-grow">
                <h4>Stumps Turf</h4>
                <p className="d-flex align-items-center gap-04 location mt-04">
                  {/* <img src="../assets/svg/map-marker.svg" alt="icon" /> */}
                  City Center Mall, Nashik
                </p>
                <p className="rating d-flex align-items-center gap-04 mt-16">
                  <img src="../assets/svg/star-yellow.svg" alt="icon" />
                  4.4 <span>(31 Reviews)</span>
                </p>
              </div>
            </div>
            {/* card-footer */}
            <div className="card-footer d-flex align-items-center justify-content-between">
              <div>
                <p>Total Price</p>
                <h3>$320</h3>
              </div>
              <a href="ticket-detail.html">Detail</a>
            </div>
          </div>
          </a>
        </li>
<hr></hr>


      </ul>
    </section>

    </div>
  )
}

export default Recommendation;