import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Tabs, Typography, Box } from "@mui/material";

import buttonswitch from '../assest/css/buttonswitch.css';


import bootstrapcss from '../assest/css/bootstrap.min.css'
import swipercss from '../assest/css/swiper-bundle.min.css'
import jquerycss from '../assest/css/jquery-ui.min.css'
import commoncss from '../assest/css/common.css'
import welcomecss from '../assest/css/welcome.css'
import animationscss from '../assest/css/animations.css'
import authcss from '../assest/css/auth.css'


import skillintrest from '../assest/css/skillintrest.css'



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCoffee, faFirstAid, faMusic, faRestroom, faToilet } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";






const AddFieldsData = () => {

  /////////handle button click event//////////////
  const [activeButton, setActiveButton] = useState('button1');

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  /////////handle button click event//////////////




  ///////change color of icon on selection////////////////// 
  const [selectedIcon, setSelectedIcon] = useState(null);

  const handleIconClick = (iconName) => {
    setSelectedIcon(iconName);
  };
  ///////change color of icon on selection////////////////// 






  ////////add   skills  like linkedin////////////////

  const [skills, setSkills] = useState([]);
  const [currentSkill, setCurrentSkill] = useState('');

  const handleInputChange = (e) => {
    setCurrentSkill(e.target.value);
  };

  const handleAddSkill = () => {
    if (currentSkill.trim() !== '') {
      setSkills([...skills, currentSkill.trim()]);
      setCurrentSkill('');
    }
  };

  const handleRemoveSkill = (skillIndex) => {
    const updatedSkills = skills.filter((_, index) => index !== skillIndex);
    setSkills(updatedSkills);
  };

  ////////add   skills  like linkedin////////////////




  return (






    <div>


      <button
        onClick={() => handleButtonClick('button1')}
        className={`custom-buttonswitch ${activeButton === 'button1' ? 'activeswitch' : ''}`}
      >
        Basic Info
      </button>

      <button
        onClick={() => handleButtonClick('button2')}
        className={`custom-buttonswitch ${activeButton === 'button2' ? 'activeswitch' : ''}`}
      >
        Vendor Register
      </button>

      <button
        onClick={() => handleButtonClick('button3')}
        className={`custom-buttonswitch ${activeButton === 'button3' ? 'activeswitch' : ''}`}
      >
        Kyc Updation
      </button>


      <div>



        {activeButton === 'button1' &&


          <div className="form-area auth-form" style={{ padding: "15px" }}>


            <form action="#">


              <div className="icon-container">


                <div>
                  <label htmlFor="lemail1">Facility :</label>


                  <FontAwesomeIcon
                    icon={faRestroom}
                    className={`icon ${selectedIcon === 'coffee' ? 'selected' : ''}`}
                    onClick={() => handleIconClick('coffee')}
                    style={{ marginLeft: "20px" }}
                  />


                  <FontAwesomeIcon
                    icon={faFirstAid}
                    className={`icon ${selectedIcon === 'music' ? 'selected' : ''}`}
                    onClick={() => handleIconClick('music')}
                    style={{ marginLeft: "20px" }}
                  />
                  <FontAwesomeIcon
                    icon={faCamera}
                    className={`icon ${selectedIcon === 'camera' ? 'selected' : ''}`}
                    onClick={() => handleIconClick('camera')}
                    style={{ marginLeft: "20px" }}
                  />

                </div>




                <div>





                  <div>
                    <label htmlFor="lemail1">Add Venue</label>
                    <input
                      type="text"
                      id="businessname"
                      name="businessname"

                      className="input-field"

                      placeholder="Venue Name"
                      value={currentSkill}
                      onChange={handleInputChange}
                    />
                  </div>



                  <button onClick={handleAddSkill} className="btn-primary" style={{ marginTop: "20px", marginBottom: "15px" }}>Add</button>


                  <div className="skills-container" style={{ marginTop: "10px", marginBottom: "15px" }}>
                    {skills.map((skill, index) => (
                      <span key={index} className="skill">
                        {skill}
                        <button onClick={() => handleRemoveSkill(index)}>x</button>
                      </span>
                    ))}
                  </div>




                </div>




                <div>
                  <label htmlFor="lemail1">Select Sports</label>
                  <div className="position-relative">
                    <select class="input-field">
                      <option value="0">Select Sports</option>
                      <option value="1">Cricket</option>
                      <option value="2">Football</option>
                      <option value="3">Hockey</option>
                      <option value="4">Volleyball</option>
                      <option value="5">Yoga</option>
                      <option value="5">Basketball</option>
                    </select>
                  </div>
                </div>



                <button className="btn-primary" style={{ marginTop: "15px" }}>Submit</button>

              </div>










            </form>









          </div>
        }



        {activeButton === 'button2' &&



          <div className="form-area auth-form" style={{ padding: "15px" }}>


            <form action="#">



              <div>
                <label htmlFor="lemail1">Business Name</label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="businessname"
                    name="businessname"
                    placeholder="Enter Business Name"
                    className="input-field"
                  />
                </div>
              </div>



              <div>
                <label htmlFor="rpass">Business Type</label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="businessnametype"
                    name="businessnametype"
                    placeholder="Enter Business Type"
                    className="input-field"
                  />

                </div>
              </div>


              <div>
                <label htmlFor="rpass">GST</label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="gst"
                    name="gst"

                    placeholder="Enter GSt"
                    className="input-field"
                  />

                </div>
              </div>



              <div>
                <label htmlFor="rpass">Location</label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="location"
                    name="location"

                    placeholder="Enter Location"
                    className="input-field"
                  />

                </div>
              </div>


              <div>
                <label htmlFor="rpass">Business Owner Name</label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="bownername"

                    placeholder="Enter Business Owner Name"
                    className="input-field"
                  />

                </div>
              </div>

              <div>
                <label htmlFor="rpass">Business Owner Detail</label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="bownerdetail"

                    placeholder="Enter Business Owner Detail"
                    className="input-field"
                  />

                </div>
              </div>


              <div>
                <label htmlFor="rpass">Business Owner Profit</label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="bownerprofit"

                    placeholder="Enter Business Owner Profit"
                    className="input-field"
                  />

                </div>
              </div>



              {/* <a href="signin-email.html" className="btn-primary">
        
          </a> */}


              <button className="btn-primary">Submit</button>


            </form>









          </div>




        }



        {activeButton === 'button3' &&

          <div className="form-area auth-form" style={{ padding: "15px" }}>


            <form action="#">




              <div>
                <label htmlFor="remail2">Aadhar</label>
                <input
                  type="file"
                  id="aadhar"
                  name="aadhar"
                  placeholder="Upload Aadhar Image"
                  className="input-field d-block"
                  style={{ padding: "8px" }}

                />
              </div>



              <div>
                <label htmlFor="remail2">Aadhar Number</label>
                <input
                  type="number"
                  id="aadhar_no"
                  name="aadhar_no"
                  placeholder="Enter your  Aadhar Number"
                  className="input-field d-block"

                />
              </div>

              <div>
                <label htmlFor="remail2">Pan</label>
                <input
                  type="file"
                  id="pan"
                  name="pan"
                  placeholder="Upload Pan Image"
                  className="input-field d-block"
                  style={{ padding: "8px" }}

                />
              </div>


              <div>
                <label htmlFor="remail2">Pan Number</label>
                <input
                  type="text"
                  id="pan_no"
                  name="pan_no"
                  placeholder="Enter  Pan Number"
                  className="input-field d-block"

                />
              </div>


              <div>
                <label htmlFor="remail2">Bank </label>
                <input
                  type="text"
                  id="bank_name"
                  name="bank_name"
                  placeholder="Enter  Bank Name"
                  className="input-field d-block"

                />
              </div>


              <div>
                <label htmlFor="remail2">Account Name </label>
                <input
                  type="text"
                  id="acc_holder_name"
                  name="acc_holder_name"
                  placeholder="Enter Account Holder Name"
                  className="input-field d-block"

                />
              </div>


              <div>
                <label htmlFor="remail2">Account Number </label>
                <input
                  type="text"
                  id="acc_holder_name"
                  name="acc_holder_name"
                  placeholder="Enter Account Number"
                  className="input-field d-block"

                />
              </div>










              <a href="signin-email.html" className="btn-primary">
                Submit Kyc
              </a>



            </form>









          </div>



        }
      </div>
    </div>














  );
};



export default AddFieldsData;

