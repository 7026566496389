import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import manualcss from '../assest/css/manualcss.css';
import IconSelector from "../assest/css/IconSelector.css";
import buttonswitch from "../assest/css/buttonswitch.css";
import skillintrest from "../assest/css/skillintrest.css";
import venuecss from "../assest/css/venue.css";
import x from "../assest/images/profile/close.png";

const UpdateExecutive = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const responseData = location?.state?.data;

  console.log("location-->", location?.state?.data);
  const [name, setName] = useState(location?.state?.data);
  const [data, setData] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [gameType, setGameType] = useState([]);
  const [venderRegister, setVenderRegister] = useState({
    business_name: responseData?.business_name || "",
    business_type: responseData?.business_type
      ? Array.isArray(responseData.business_type)
        ? responseData.business_type
        : [responseData.business_type] // Convert to array if it's not already
      : [],
    location: responseData?.location || "",
    owner_name: responseData?.owner_name || "", // Adjusted property name
    owner_mobile: responseData?.owner_mobile || "",
    owner_email: responseData?.owner_email || "",
    owner_dob: responseData?.owner_dob || "",
    owner_address: responseData?.owner_address || "",
    id: responseData?.id || "",
  });

  const fetchData = async () => {
    try {
      const gameResponse = await axios.get(`${apiBaseUrl}/gametypeData`);
      setGameType(gameResponse.data);
      console.log("gameData", JSON.stringify(gameResponse.data));

      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/showpageapi/${id}`);
      if (response.data) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setVenderRegister({
      ...venderRegister,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOptions, actionMeta) => {
    setVenderRegister({
      ...venderRegister,
      [actionMeta.name]: selectedOptions.map(option => option.value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      for (const key in venderRegister) {
        formDataToSend.append(key, venderRegister[key]);
      }
      console.log("form data to send:", formDataToSend);

      const response = await axios.post(
        `${apiBaseUrl}/vendors`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const id1 = response.data.user_id1;
      console.log(id1);
      localStorage.setItem("id1", id1);

      navigate("/fields_executive_dashboard");
    } catch (error) {
      console.log("Error while creating account", error);
    }
  };

  const back = () => {
    navigate("/vendor_list");
  };

  return (
    <div>
      <main>
        <section className="auth signin">
          <div className="page-title pageHead">
            <button
              onClick={back}
              type="button"
              className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowleftblack} alt="arrow" />
            </button>
            <h3 className="main-title mainTitleLight">Update Vendor</h3>
          </div>

          <div className="form-area auth-form">
            {responseData && (
              <>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="business_name">Business Name</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        id="business_name"
                        name="business_name"
                        placeholder="Enter Business Name"
                        className="input-field"
                        value={venderRegister.business_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="business_type">Business Type</label>
                    <div className="position-relative">
                      <Select
                        id="business_type"
                        name="business_type"
                        options={
                          gameType && gameType.gameTypes
                            ? gameType.gameTypes.map((game) => ({
                              label: game,
                              value: game,
                            }))
                            : []
                        }
                        isMulti
                        value={
                          Array.isArray(venderRegister.business_type)
                            ? venderRegister.business_type.map((type) => ({
                              label: type,
                              value: type,
                            }))
                            : []
                        }
                        onChange={handleSelectChange}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="location">Location</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        id="location"
                        name="location"
                        placeholder="Enter Location"
                        className="input-field"
                        value={venderRegister.location}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="owner_name">Business Owner Name</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        id="owner_name"
                        name="owner_name"
                        placeholder="Enter Business Owner Name"
                        className="input-field"
                        value={venderRegister.owner_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="owner_mobile">Business Owner Mobile</label>
                    <div className="position-relative">
                      <input
                        type="number"
                        id="owner_mobile"
                        name="owner_mobile"
                        placeholder="Enter Business Owner Mobile"
                        className="input-field"
                        value={venderRegister.owner_mobile}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="owner_email">Business Owner Email</label>
                    <div className="position-relative">
                      <input
                        type="email"
                        id="owner_email"
                        name="owner_email"
                        placeholder="Enter Business Owner Email"
                        className="input-field"
                        value={venderRegister.owner_email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="owner_dob">Date Of Birth</label>
                    <input
                      type="date"
                      id="owner_dob"
                      name="owner_dob"
                      placeholder="Enter Date of Birth"
                      className="input-field d-block"
                      value={venderRegister.owner_dob}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="owner_address">Enter Address </label>
                    <input
                      type="text"
                      id="owner_address"
                      name="owner_address"
                      placeholder="Enter your Address"
                      className="input-field d-block"
                      value={venderRegister.owner_address}
                      onChange={handleChange}
                    />
                  </div>
                  <button className="btn-primary">Submit KYC</button>
                </form>
              </>
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

export default UpdateExecutive;
