import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import "../assest/css/bootstrap.min.css";
import "../slots/slot_list.css";
import "../assest/css/swiper-bundle.min.css";
import "../assest/css/common.css";
import "../assest/css/welcome.css";
import "../assest/css/animations.css";
import "../assest/css/auth.css";
import "../assest/css/profile.css";
import manualcss from '../assest/css/manualcss.css';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BookIcon from '@mui/icons-material/Book'; // Import the booking icon


const ViewAllSlot = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const venueIddddid = searchParams.get("id");
  const navigate = useNavigate();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(venueIddddid);
  const [slots, setSlots] = useState([]);

  const id2 = localStorage.getItem("id2");

  useEffect(() => {
    fetchData();
  }, [venueIddddid]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/all_Slots/${venueIddddid}`);
      setData(response.data);
      setSlots(response.data.slots || []);
      if (response.data.venueIDs && response.data.venueIDs.length > 0) {
        setActiveTab(venueIddddid || response.data.venueIDs[0].id.toString());
      }
      console.log("data", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("token", token);
    if (token == null) {
      navigate("/vendor_dashboard");
    } else {
      fetchData();
    }
  }, [navigate]);

  const handleSubmit = async (slot_id) => {
    try {
      console.log("FormData to send:", slot_id); // Log the slot_id being sent

      const response = await axios.post(
        `${apiBaseUrl}/destroySlots`,
        { slot_id }, // send slot_id as an object
        {
          headers: {
            "Content-Type": "application/json", // Assuming you're sending JSON, change if needed
          },
        }
      );
      console.log(response.data);
      fetchData();
    } catch (error) {
      console.log("Error while creating account", error);
    }
  };

  const back = () => {
    window.history.back();
  };

  return (
    <div>
      <main className="tour-guide mainBackground" style={{ marginBottom: "80px" }}>
        <div className="page-title pageHead">
          <button
            onClick={back}
            type="button"
            className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
          >
            <img src={arrowleftblack} alt="arrow" />
          </button>
          <h3 className="main-title mainTitleLight">Slots List</h3>
        </div>
        <div style={{ padding: '0 20px' }}>
          <div className="venue-tabs-container">
            <div className="venue-tabs">
              {data && data.venueIDs && data.venueIDs.map((venue) => (
                <div className="venue-button-container" key={venue.id}>
                  <Link to={`/viewall_slot?id=${venue.id}`}>
                    <button
                      className={`venue-button ${activeTab === venue.id.toString() ? 'active' : ''}`}
                      onClick={() => {
                        setActiveTab(venue.id.toString());
                        setSlots([]); // Clear slots when switching tab
                        fetchData();
                      }}
                    >
                      {venue.name}
                    </button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {slots.length > 0 ? (
            slots.map((slot) => (
              <section className="budget pt-10" key={slot.id}>
                <div
                  className="card"
                  style={{
                    padding: "5px 20px",
                    marginBottom: '10px'
                  }}
                >
                  <div className="card-body" style={{ padding: "0px" }}>
                    <div className="time-section">
                      <div className="time-info text-muted">
                        <p>Start Time: </p>
                        <p><span>{slot.slot_start_time}</span></p>
                      </div>
                      <div className="time-info text-muted">
                        <p>End Time:</p>
                        <p><span>{slot.slot_end_time}</span></p>
                      </div>
                      <div className="text-end">
                        <Link to={`/update_slot?id=${slot.id}`}>
                          <h5 className="pb-9">
                            <span
                              className="d-inline-block text-center"
                              style={{
                                width: "80px",
                                border: "none",
                                textDecoration: "none",
                                fontSize: "12px",
                                borderRadius: "5px",
                                padding: "5px",
                                textAlign: "center",
                                color: "white",
                                backgroundColor: "#0e795d",
                              }}
                            >
                              Update
                            </span>
                          </h5>
                        </Link>
                        <span
                          className="d-inline-block text-center"
                          style={{
                            textDecoration: "none",
                            width: "80px",
                            borderRadius: "5px",
                            padding: "2px",
                            fontSize: "12px",
                            textAlign: "center",
                            backgroundColor: "#fff",
                            color: "#0e795d",
                            border: "1px solid #0e795d",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSubmit(slot.id)}
                        >
                          Delete
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ))
          ) : (
            <div>No slots available for this venue.</div>
          )}
        </div>
      </main>
      {/* Footer */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#fff",
          padding: 1,
          display: "flex",
          justifyContent: "space-around",
          boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
        }}
      >
        <Link to="/vendor_dashboard">
          <IconButton>
            <HomeIcon />
          </IconButton>
        </Link>
        <Link to="/vendor_bookings"> {/* Add the booking link */}
          <IconButton>
            <BookIcon /> {/* Use the booking icon */}
          </IconButton>
        </Link>
        <Link to="/vendor_addvenue">
          <IconButton>
            <AddCircleIcon />
          </IconButton>
        </Link>
        <Link to="/vendor_query">
          <IconButton>
            <QuestionAnswerIcon />
          </IconButton>
        </Link>
        <Link to="/vendorUpdate_profile">
          <IconButton>
            <PersonIcon />
          </IconButton>
        </Link>
      </Box>
    </div>
  );
};

export default ViewAllSlot;
