import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';
import datetimepicker from "../assest/css/jquery.datetimepicker.css";
import uiMin from "../assest/css/jquery-ui.min.css";
import welcomecss from "../assest/css/welcome.css";
import commoncss from "../assest/css/common.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import notificationcss from "../assest/css/notification.css";
import avatar from "../assest/images/profile/avatar.png";
import mapmarker from "../assest/svg/map-marker.svg";
import bellblack from "../assest/svg/bell-black.svg";
import executiveprofile from "../assest/images/profile/executive.png";
import kyc1 from "../assest/homeimage/ky1.svg";
import kyc2 from "../assest/homeimage/newkyc.jpg";
import logout from "../assest/homeimage/newlogout.png";

import addvenue from "../assest/homeimage/add.png";
import venuelist from "../assest/homeimage/vendorlist.png";
import history from "../assest/homeimage/history.png";
import query from "../assest/homeimage/query.png";

import slider1 from "../assest/homeimage/slider2.png";
import slider2 from "../assest/homeimage/slider3.png";
import slider3 from "../assest/homeimage/v1.png";
import slider4 from "../assest/homeimage/v2.png";
import manualcss from '../assest/css/manualcss.css';
import kyccss from "../FieldsExecutive/kyc.css";
import axios from "axios";
import { Link, Navigate, useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom'
import Slider from "react-slick";
const History = () => {
    const navigate = useNavigate();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [data, setData] = useState(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const vendorIdFromParams = searchParams.get("id");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const id = localStorage.getItem("id");
                const response = await axios.get(`${apiBaseUrl}/fieldExecutive_reports/${id}`);
                if (response.data) {
                    setData(response.data);
                    console.log("dataaaaaa", response);
                } else {
                    navigate("/fields_executive_dashboard");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const token = localStorage.getItem("token");
        console.log("token", token);
        if (token == null) {
            navigate("/user_signin");
        } else {
            fetchData();
        }
    }, [navigate, apiBaseUrl]);

    return (
        <>
            <div>
                <section className="budget">
                    <div className="page-title pageHead">
                        <button
                            type="button"
                            className="white_button btn back-page-btn h-80 w-60 d-flex align-items-center justify-content-center rounded-full"
                        >
                            <Link to="/fields_executive_dashboard">
                                <img src={arrowleftblack} alt="arrow" />
                            </Link>
                        </button>

                        <h3 className="main-title mainTitleLight">History</h3>
                    </div>

                    {data && data.field_executive && (
                        <section class="notification">

                            {data.field_executive.map((report, index) => (
                                <React.Fragment key={index}>

                                    <div>
                                        <ul style={{ paddingLeft: '10px', marginBottom: '4px', paddingRight: "10px" }}>
                                            <li class="d-flex" style={{ border: '1px solid #0e795d', borderRadius: '15px', padding: '8px 10px', boxShadow: '5px 5px 10px #dddbdb' }}>
                                                <div class="image d-flex align-items-center justify-content-center rounded-full overflow-hidden shrink-0" style={{ marginRight: "2px" }}>
                                                    <img src="https://turf.ukvalley.com/public/business_img/report_icon_1.png" alt="person" class="img-fluid h-100 w-100 object-fit-cover" />
                                                </div>
                                                <div>
                                                    <p className="" style={{ marginBottom: '0', fontSize: "13px" }}>{report.description}</p>
                                                    <small className="d-block">
                                                        {new Date(report.created_at).toLocaleDateString('en-GB', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric'
                                                        })}
                                                    </small>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </React.Fragment>
                            ))}

                        </section>
                    )}

                </section>
            </div >
        </>
    );
};



export default History;
