import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import HorizontalScroll from 'react-horizontal-scroll';
import '../assest/css/list.css';
import bootstrapcss from "../assest/css/bootstrap.min.css";
import list from '../assest/css/list.css'
import plus from '../assest/svg/plus-white.svg'
import datetimepicker from "../assest/css/datetimepicker.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import "../assest/css/details.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import Vali from '../assest/images/turf/vali.jpg';
import map_marker from '../assest/svg/map-marker.svg';
import starYellow from '../assest/svg/star-yellow.svg';
import heartRed from '../assest/svg/heart-red-light.svg';
import canteen from '../assest/svg/building.svg';
import water from '../assest/svg/water.svg';
import security from '../assest/svg/24-support.svg';
import wind from '../assest/svg/wind.svg';
import avatar2 from '../assest/images/details/avatar-2.png'
import close from '../assest/svg/close-black.svg';
import { Link, Navigate, useNavigate } from "react-router-dom";


const TurfView = () => {

    return (
        <div>
            <>
                <main className="details  hotel-details">
                    <img src={Vali} alt="Turf Image" />
                    <div className="page-title" style={{
                    }}>
                        <button
                            type="button"
                            className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                        >
                            <Link to="/fields_executive_dashboard">
                                <img src={arrowleftblack} alt="arrow" />
                            </Link>
                        </button>

                        <h3 className="main-title">Turf</h3>
                    </div>
                    <section className="details-body">

                        <section className="d-flex align-items-center gap-8 details-title">
                            <div className="flex-grow">
                                <h3>Krida Sankul</h3>
                                <ul className="d-flex align-items-center gap-8">
                                    <li className="d-flex align-items-center gap-04">
                                        <img src={map_marker} alt="icon" />
                                        <p>Nashik, Maharashtra, India</p>
                                    </li>
                                    <li className="d-flex align-items-center gap-04">
                                        <img src={starYellow} alt="icon" />
                                        <p><span>4.4</span> (41 Reviews)</p>
                                    </li>
                                </ul>
                            </div>
                            <span className="d-flex align-items-center justify-content-center rounded-full shrink-0">
                                <img src={heartRed} alt="" />
                            </span>
                        </section>

                        <section className="facilities pt-32 pb-16">
                            <div className="title d-flex align-items-center justify-content-between">
                                <h4 className="shrink-0">Common Facilities</h4>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#serviceModal" className="shrink-0 d-inline-block">See All</button>
                            </div>

                            <div className="grid gap-24">
                                <div className="item text-center">
                                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                        <img src={wind} alt="icon" />
                                    </div>
                                    <p>ABC</p>
                                </div>

                                <div className="item text-center">
                                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                        <img src={canteen} alt="icon" />
                                    </div>
                                    <p>Canteen</p>
                                </div>

                                <div className="item text-center">
                                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                        <img src={water} alt="icon" />
                                    </div>
                                    <p>RO Water</p>
                                </div>

                                <div className="item text-center">
                                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                        <img src={security} alt="icon" />
                                    </div>
                                    <p>24-Hours Front Desk</p>
                                </div>

                            </div>
                        </section>

                        <section className="details-info py-16">
                            <div className="title">
                                <h4>Details</h4>
                            </div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor ac leo lorem nisl. Viverra vulputate sodales quis et dui, lacus. Iaculis eu egestas leo egestas vel.
                                <button type="button">More Details</button>
                            </p>
                        </section>

                        <section className="reviews py-16">
                            <div className="title d-flex align-items-center justify-content-between">
                                <h4 className="shrink-0">Reviews</h4>
                                <a href="#" className="shrink-0 d-inline-block">See All</a>
                            </div>
                            {/* style={{ backgroundColor: 'transparent', boxShadow: 'none' }} */}
                            <div className="review-card d-flex gap-16" >
                                <div className="shrink-0 image overflow-hidden rounded-full">
                                    <img src={avatar2} alt="Avatar" className="img-fluid w-100" />
                                </div>

                                <div className="flex-grow">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4>Jhone Kenoady</h4>
                                        <span className="d-inline-block">23 Nov 2022</span>
                                    </div>
                                    <ul className="d-flex align-items-center gap-8">
                                        <li><img src={starYellow} alt="icon" /></li>
                                        <li><img src={starYellow} alt="icon" /></li>
                                        <li><img src={starYellow} alt="icon" /></li>
                                        <li><img src={starYellow} alt="icon" /></li>
                                        <li><img src={starYellow} alt="icon" /></li>
                                    </ul>
                                    <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                                </div>
                            </div>
                        </section>

                        <section className="details-location pt-16">
                            <div className="title">
                                <h4>Location</h4>
                            </div>

                            <div className="overflow-hidden radius-16 map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3748.6102496528206!2d73.8004033752296!3d20.024866181385068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddea49d0049115%3A0x47d2d4c26f33dea3!2sKrida%20Sankul!5e0!3m2!1sen!2sin!4v1712324202192!5m2!1sen!2sin"
                                    style={{ border: '0', loading: 'lazy', referrerpolicy: 'no-referrer-when-downgrade' }}></iframe>
                            </div>
                        </section>
                    </section>

                    <section className="details-footer d-flex align-items-center justify-content-between gap-8 w-100">
                        <p>Rs.32 <span>Rs.312</span></p>
                        <a href="#"
                            style={{ backgroundColor: 'lightgreen', }}>Book Now</a>
                    </section>
                </main>


                <div className="modal fade serviceModal bottomModal modalBg" id="serviceModal" tabindex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="modal-close rounded-full" data-bs-dismiss="modal" aria-label="Close">
                                    <img src={close} alt="Close" />
                                </button>
                                <h1 className="modal-title">All Facilities</h1>
                            </div>
                            <div className="modal-body">
                                <div className="facilities">
                                    <div className="grid gap-24">
                                        <div className="item text-center">
                                            <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                                <img src={wind} alt="icon" />
                                            </div>
                                            <p>ABC</p>
                                        </div>

                                        <div className="item text-center">
                                            <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                                <img src={canteen} alt="icon" />
                                            </div>
                                            <p>Canteen</p>
                                        </div>

                                        <div className="item text-center">
                                            <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                                <img src={water} alt="icon" />
                                            </div>
                                            <p>RO Water</p>
                                        </div>

                                        <div className="item text-center">
                                            <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                                <img src={security} alt="icon" />
                                            </div>
                                            <p>24-Hours Front Desk</p>
                                        </div>

                                        <div className="item text-center">
                                            <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                                <img src={canteen} alt="icon" />
                                            </div>
                                            <p>Rest Room</p>
                                        </div>

                                        <div className="item text-center">
                                            <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                                <img src={security} alt="icon" />
                                            </div>
                                            <p>24-Hours Security</p>
                                        </div>

                                        <div className="item text-center">
                                            <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                                <img src={wind} alt="icon" />
                                            </div>
                                            <p>View</p>
                                        </div>

                                        <div className="item text-center">
                                            <div className="icon d-flex align-items-center justify-content-center rounded-full">
                                                <img src={water} alt="icon" />
                                            </div>
                                            <p>Open Space</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </>
        </div >
    );
};

export default TurfView;
