import React from 'react'
import bundle from '../assest/assets-demo/css/style.css'
import App from '../App.css'
import homecss from '../assest/css/home.css'
import welcomecss from '../assest/css/welcome.css'
import commoncss from '../assest/css/common.css'
import jquerycss from '../assest/css/jquery-ui.min.css'
import swipercss from '../assest/css/swiper-bundle.min.css'
import bootstrapcss from '../assest/css/bootstrap.min.css'
import ticket from '../assest/css/ticket.css'
import reviewcard from '../assest/css/reviewusercard.css'

import humberg from  '../assest/svg/menu/burger-white.svg'
import sunwhite from  '../assest/svg/menu/sun-white.svg'
import moonwhite from '../assest/svg/menu/moon-white.svg'
import profilewhite from  '../assest/svg/menu/profile-white.svg'
import closewhite  from'../assest/svg/menu/close-white.svg'
import profile from '../assest/images/profile/avatar.png'





 import v3 from '../assest/homeimage/v3.jpg'
 import v1 from '../assest/homeimage/v1.png'
 import v2 from '../assest/homeimage/v2.png'

import arrowright from  '../assest/svg/arrow-left-black.svg'


import { Carousel } from 'bootstrap'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import startyellow from '../assest/svg/star-yellow-big.svg'
import backarrow from '../assest/svg/arrow-left-black.svg'

const BookSlot = () => {

    const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };
  return (

 <div>
  <>




  <main className="home">


   
    
    <section className="wrapper dz-mode">



      <div className="menu">


        <button className="toggle-btn">
          <img
            src={backarrow}
            alt=""
            className="icon"
          />
        </button>


        <div className="btn-grp d-flex align-items-center gap-16">
         
            <img
              src={sunwhite}
              alt="icon"
              className="sun"
            />
           
       



        
            <img src={profilewhite} alt="icon" />
         
        </div>


      </div>


      <div className="m-menu__overlay"     />
     
 
    </section>


    





  

 
    {/* visited start */}
    <section className="visited py-12">
    
     <div className="swiper visited-swiper">
        <div className="swiper-wrapper">
          {/* item-1 */}
          <div className="swiper-slide place-card">
            <a href="vacation-details.html">


              <div className="image position-relative ">
                <img
                  src={v1}
                  alt="desert"
                  className="img-fluid w-100 overflow-hidden radius-8 "
                />
                {/* <span className="d-flex align-items-center justify-content-center rounded-full">
                  <img src="../assets/svg/heart-red.svg" alt="icon" />
                </span> */}
              </div>




            </a>
          </div>
       
          



       


        </div>
      </div>
    </section>








    <section className="guide py-12">
 

      <div className="title align-items-center justify-content-between">
        <h2 className="shrink-0">Ratting </h2>


   <div class="row row-cols-2 ">


  <div class="col" style={{width:"100%"}}>


    <div class="card" style={{padding:'0px'}}>

      <div class="card-body">


      <ul class="d-flex " style={{padding:'0px',justifyContent:'space-between'}}>
    <li class="d-flex align-items-center " style={{justifyContent:"flex-start"}}>
      <img src= {startyellow} alt="icon"/>
              <h2><span>4.4</span> (41 Reviews)</h2>


     </li>

<button className="btn-primary" style={{width:"100px" ,justifyContent:"flex-end"}}>Rate Now</button>

        </ul>








      </div>
    </div>
  </div>


  

 
 
    </div> 
 

 <div className="shrink-0" style={{marginTop:"10px",font:"message-box"}}>
<p>Rumbumi turf is located just near city center mall untwadi nashik, and offers vrous facilty ,and parking availability, Rumbumi turf is located just near city center mall untwadi nashik, and offers vrous facilty ,and parking availability
    ,Rumbumi turf is located just near city center mall untwadi nashik, and offers vrous facilty ,and parking availability
     </p>


 </div>


  <div class="row row-cols-2 ">


  <div class="col" style={{width:"100%"}}>


    <div class="card" style={{padding:'0px'}}>

      <div class="card-body">


      <ul class="d-flex " style={{padding:'0px',justifyContent:'space-between'}}>
    <li class="d-flex align-items-center " style={{justifyContent:"flex-start"}}>
      <img src= {startyellow} alt="icon"/>
           <h2>Facilities & Services </h2>


     </li>


<img src= {arrowright} alt="icon"  style={{justifyContent:"flex-end"}}/>
        </ul>








      </div>
    </div>
  </div>


  

 
 
    </div> 

 
     
      </div>
</section>





 

    
  

   <section className="guide ">
 

      <div className="title align-items-center justify-content-between">
        <h2 className="shrink-0">Top Offers for you </h2>

  <Slider {...settings}>

 
   <div class="row row-cols g-3 ">


  <div class="col" >


    <div class="card" style={{padding:'0px'}}>

      <div class="card-body">


      <ul class="d-flex " style={{padding:'0px',justifyContent:'space-between'}}>
    <li class="d-flex align-items-center " style={{justifyContent:"flex-start"}}>
      <img src= {startyellow} alt="icon"/>
              <h2><span>4.4</span> (41 Reviews)</h2>


     </li>

<button className="btn-primary" style={{width:"100px" ,justifyContent:"flex-end"}}>Rate Now</button>

        </ul>








      </div>
    </div>
  </div>


   <div class="col" >


    <div class="card" style={{padding:'0px'}}>

      <div class="card-body">


      <ul class="d-flex " style={{padding:'0px',justifyContent:'space-between'}}>
    <li class="d-flex align-items-center " style={{justifyContent:"flex-start"}}>
      <img src= {startyellow} alt="icon"/>
              <h2><span>4.4</span> (41 Reviews)</h2>


     </li>

<button className="btn-primary" style={{width:"100px" ,justifyContent:"flex-end"}}>Rate Now</button>

        </ul>








      </div>
    </div>
  </div>

   <div class="col" >


    <div class="card" style={{padding:'0px'}}>

      <div class="card-body">


      <ul class="d-flex " style={{padding:'0px',justifyContent:'space-between'}}>
    <li class="d-flex align-items-center " style={{justifyContent:"flex-start"}}>
      <img src= {startyellow} alt="icon"/>
              <h2><span>4.4</span> (41 Reviews)</h2>


     </li>

<button className="btn-primary" style={{width:"100px" ,justifyContent:"flex-end"}}>Rate Now</button>

        </ul>








      </div>
    </div>
  </div>
 
    </div> 
  
   </Slider>
     
      </div>
</section>





   <section className="guide py-12">
 

      <div className="title align-items-center justify-content-between">
        <h2 className="shrink-0">Top Reviews </h2>


<Slider {...settings}>
  <div class="reviewwrapper">

		<div class="review-card">

			<div class="header-content_review">
				<div class="img-area">
          <img alt="customer1" src= {profile}/>
        </div>
				<div class="inforeview">
					<h4>Valeed Pathan</h4>
					
				</div>
			</div>
			<div class="single-review">
				<p>overall very good experience and the turf mat was excellent and the grass so clean so every one can dif to catch a ball </p>
			</div>
			<div class="review-footer">
				<div class="rating-review">
					<span class="active">★</span> 
          <span class="active">★</span> 
          <span class="active">★</span> 
          <span class="">★</span> 
          <span class="">★</span>
				</div>
				<p>Reviewed on 01/01/2023</p>
			</div>
		</div>
		
        		<div class="review-card">
            
			<div class="header-content_review">
				<div class="img-area">
          <img alt="customer1" src= {profile}/>
        </div>
				<div class="inforeview">
					<h4>Valeed Pathan</h4>
					
				</div>
			</div>
			<div class="single-review">
				<p>overall very good experience and the turf mat was excellent and the grass so clean so every one can dif to catch a ball </p>
			</div>
			<div class="review-footer">
				<div class="rating-review">
					<span class="active">★</span> 
          <span class="active">★</span> 
          <span class="active">★</span> 
          <span class="">★</span> 
          <span class="">★</span>
				</div>
				<p>Reviewed on 01/01/2023</p>
			</div>
		</div>
		

		<div class="review-card">
            
			<div class="header-content_review">
				<div class="img-area">
          <img alt="customer1" src= {profile}/>
        </div>
				<div class="inforeview">
					<h4>Valeed Pathan</h4>
					
				</div>
			</div>
			<div class="single-review">
				<p>overall very good experience and the turf mat was excellent and the grass so clean so every one can dif to catch a ball </p>
			</div>
			<div class="review-footer">
				<div class="rating-review">
					<span class="active">★</span> 
          <span class="active">★</span> 
          <span class="active">★</span> 
          <span class="">★</span> 
          <span class="">★</span>
				</div>
				<p>Reviewed on 01/01/2023</p>
			</div>
		</div>



		<div class="review-card">
            
			<div class="header-content_review">
				<div class="img-area">
          <img alt="customer1" src= {profile}/>
        </div>
				<div class="inforeview">
					<h4>Valeed Pathan</h4>
					
				</div>
			</div>
			<div class="single-review">
				<p>overall very good experience and the turf mat was excellent and the grass so clean so every one can dif to catch a ball </p>
			</div>
			<div class="review-footer">
				<div class="rating-review">
					<span class="active">★</span> 
          <span class="active">★</span> 
          <span class="active">★</span> 
          <span class="">★</span> 
          <span class="">★</span>
				</div>
				<p>Reviewed on 01/01/2023</p>
			</div>
		</div>
	
	</div>
</Slider>

  
    

 
     
      </div>
</section>


 


<button className="btn-primary" >Book Slots</button>






  </main>
  {/* bottom navigation start */}




 






</>

 </div>

  )
}



export default BookSlot;
