import React, { useState } from 'react';

function ChatApp() {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');

    // Function to send a message
    const sendMessage = () => {
        if (inputValue.trim() !== '') {
            const newMessage = {
                id: messages.length + 1, // Assigning a sequential ID
                text: inputValue,
                sender: 'user',
            };
            setMessages([...messages, newMessage]);
            setInputValue('');
        }
    };

    return (
        <div>
            <div className="chat-container">
                {messages.map(message => (
                    <div key={message.id} className={`message ${message.sender}`}>
                        {message.text}
                    </div>
                ))}
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    placeholder="Type your message..."
                />
                <button onClick={sendMessage}>Send</button>
            </div>
        </div>
    );
}

export default ChatApp;
