import bundle from "../assest/assets-demo/css/style.css";
import App from "../App.css";
import homecss from "../assest/css/home.css";
import welcomecss from "../assest/css/welcome.css";
import commoncss from "../assest/css/common.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import ticket from "../assest/css/ticket.css";
import avatar from "../assest/images/profile/avatar.png";
import cricketimg from "../assest/homeimage/cricket.png";
import footballimg from "../assest/homeimage/soccer-ball.png";
import volleyballimg from "../assest/homeimage/volleyball.png";
import category from "../assest/images/home/category.png";

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BookIcon from '@mui/icons-material/Book'; // Import the booking icon

import humberg from "../assest/svg/menu/burger-white.svg";
import sunwhite from "../assest/svg/menu/sun-white.svg";
import moonwhite from "../assest/svg/menu/moon-white.svg";
import profilewhite from "../assest/svg/menu/profile-white.svg";
import search from "../assest/svg/search.svg";
import filterblack from "../assest/svg/filter-black.svg";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";

import ssk from "../assest/recommendationimage/ssk.svg";
import chakde from "../assest/recommendationimage/chakde.svg";
import runbumi from "../assest/recommendationimage/runbhumi.svg";
import stumps from "../assest/recommendationimage/stonch.svg";
import startyellow from "../assest/svg/star-yellow.svg";
import axios from "axios";
import manualcss from '../assest/css/manualcss.css';
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import React, { useState, useEffect } from "react";
import plus from '../assest/svg/plus-white.svg';



const VendorVenueList = () => {
  const navigate = useNavigate();

  const back = () => {
    navigate("/vendor_dashboard");
  };



  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [data, setData] = useState(null);

  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const vendueId = searchParams.get("id");
  // const vendueId = 3;




  const id2 = localStorage.getItem("id2");




  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/all_Venue/${id2}`);
      setData(response.data);
      console.log("data", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("token", token);
    if (token == null) {
      navigate("/vendor_dashboard");
    }
    fetchData();
  }, []);




  const addVenue = () => {
    navigate("/vendor_addvenue");
  };



  const seeSlots = (venue) => {
    const encodeId = encodeURIComponent(venue.id);
    localStorage.setItem("turf_id", venue.id);
    navigate(`/venue_slot?id=${encodeId}`);
  };




  const AddSlot = (venue) => {


    navigate(`/venue_slot`, { state: { data: venue } });





  };



  // const AddSlot = (venue) => {


  //          const encodedId = encodeURIComponent(venue.id);
  //          localStorage.setItem("vendor_id", venue.id);
  //          navigate(`/venue_slot?id=${encodedId}`);
  // };



  const updateVenue = (venue) => {
    navigate(`/vendor_update_venue`, { state: { venueData: venue } });
  };



  const transformGameNames = (gameNamesString) => {
    const cleanedString = gameNamesString.slice(2, -2).replace(/\\/g, '');

    // Split the cleaned string by commas to create an array
    const gamesArray = cleanedString.split('","');

    // Remove any leading/trailing quotes and whitespace
    const cleanedArray = gamesArray.map(game => game.replace(/["\[\]]/g, '').trim().toLowerCase());

    // Join the array elements into a comma-separated string
    return cleanedArray.join(',')
  };



  return (
    <div>
      <section className="budget">
        <div className="page-title pageHead">
          <button
            onClick={back}
            type="button"
            className="white_button btn back-page-btn h-80 w-60 d-flex align-items-center justify-content-center rounded-full"
          >
            <Link>
              <img src={arrowleftblack} alt="arrow" />
            </Link>
          </button>
          <h3 className="main-title mainTitleLight">Vendor Venue List</h3>
        </div>
        {/* <section className="search py-10 " style={{ padding: "10px" }}>
          <form action="#">
            <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24"
              style={{
                background: '#eeffef',
                border: '1px solid #e1e1e1',
              }}>
              <input
                type="search"
                className="input-search input-field"
                placeholder="Search..."
                style={{
                  background: '#eeffef',
                  marginBottom: '0 !important'
                }}
              />
            </div>
          </form>
        </section> */}

        {data && (
          <ul style={{ padding: "0px" }}>
            {data.map((venue) => (
              <li key={venue.id}>
                <section
                  className="message "
                  style={{
                    marginTop: "0px !important",
                    marginBottom: "-5px !important",
                  }}
                >
                  <div className="single-chat position-relative">
                    <a
                      style={{ textDecoration: "none", border: '1px solid #0e795d', margin: '0 10px', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                      className="single-main d-flex align-items-center justify-content-between radius-12"
                    >
                      <div className="shrink-0 d-flex align-items-center">
                        <div className="image shrink-0 position-relative" style={{ marginRight: '8px' }}>
                          <img
                            src={`${apiImageUrl}/venue_image/${venue.images}`}
                            alt="Avatar"
                            className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                          />
                        </div>
                        <div className="list-container">
                          <h4>{venue.name}</h4>
                          <p style={{ width: '190px' }}>Games: {transformGameNames(venue.game_names)}</p>
                        </div>
                      </div>

                      <div className="text-end buttons_container list-container">
                        <h5 className="pb-9">
                          <span
                            className="d-inline-block text-center"
                            onClick={() => updateVenue(venue)}
                            style={{
                              width: "70px",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >
                            <a>Edit Venue</a>
                          </span>
                        </h5>

                        <span
                          className="d-inline-block text-center"
                          onClick={() => AddSlot(venue)}


                          style={{
                            width: "70px",
                            borderRadius: "5px",

                            textAlign: "center",
                            backgroundColor: "rgb(255 255 255)",
                            color: "#0e795d",
                            border: '1px solid #0e795d'
                          }}
                        >
                          <a>Slots</a>
                        </span>


                      </div>
                    </a>
                  </div>
                </section>

                {/* <button
                  type="button"
                  onClick={() => addVenue(venue)}
                  className="add-chat d-flex align-items-center justify-content-center rounded-full"
                >
                  <img src={plus} alt="plus" />
                </button> */}
              </li>
            ))}
          </ul>
        )}


      </section>
      {/* Footer */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#fff",
          padding: 1,
          display: "flex",
          justifyContent: "space-around",
          boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
        }}
      >
        <Link to="/vendor_dashboard">
          <IconButton>
            <HomeIcon />
          </IconButton>
        </Link>
        <Link to="/vendor_bookings"> {/* Add the booking link */}
          <IconButton>
            <BookIcon /> {/* Use the booking icon */}
          </IconButton>
        </Link>
        <Link to="/vendor_addvenue">
          <IconButton>
            <AddCircleIcon />
          </IconButton>
        </Link>
        <Link to="/vendor_query">
          <IconButton>
            <QuestionAnswerIcon />
          </IconButton>
        </Link>
        <Link to="/vendorUpdate_profile">
          <IconButton>
            <PersonIcon />
          </IconButton>
        </Link>
      </Box>
    </div>
  );


};
export default VendorVenueList;
