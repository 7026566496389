import bundle from "../assest/assets-demo/css/style.css";
import App from "../App.css";
import homecss from "../assest/css/home.css";
import welcomecss from "../assest/css/welcome.css";
import commoncss from "../assest/css/common.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import ticket from "../assest/css/ticket.css";
import avatar from "../assest/images/profile/avatar.png";
import cricketimg from "../assest/homeimage/cricket.png";
import footballimg from "../assest/homeimage/soccer-ball.png";
import volleyballimg from "../assest/homeimage/volleyball.png";
import category from "../assest/images/home/category.png";

import humberg from "../assest/svg/menu/burger-white.svg";
import sunwhite from "../assest/svg/menu/sun-white.svg";
import moonwhite from "../assest/svg/menu/moon-white.svg";
import profilewhite from "../assest/svg/menu/profile-white.svg";
import search from "../assest/svg/search.svg";
import filterblack from "../assest/svg/filter-black.svg";
import {
    Link,
    Navigate,
    useLocation,
    useNavigate,
    useParams,
    useRoutes,
} from "react-router-dom";

import ssk from "../assest/recommendationimage/ssk.svg";
import chakde from "../assest/recommendationimage/chakde.svg";
import runbumi from "../assest/recommendationimage/runbhumi.svg";
import stumps from "../assest/recommendationimage/stonch.svg";
import startyellow from "../assest/svg/star-yellow.svg";
import axios from "axios";
import manualcss from '../assest/css/manualcss.css';
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import React, { useState, useEffect } from "react";
import plus from '../assest/svg/plus-white.svg';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BookIcon from '@mui/icons-material/Book'; // Import the booking icon

const ViewVenueBooking = () => {
    const navigate = useNavigate();

    const back = () => {
        navigate("/vendor_dashboard");
    };

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
    const [data, setData] = useState(null);
    const id2 = localStorage.getItem("id2");

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/view_venues/${id2}`);
            setData(response.data);
            console.log("data", response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("token", token);
        if (token == null) {
            navigate("/vendor_dashboard");
        }
        fetchData();
    }, []);


    return (
        <div>
            <section className="budget" style={{ marginBottom: "80px" }}>
                <div className="page-title pageHead">
                    <button
                        onClick={back}
                        type="button"
                        className="white_button btn back-page-btn h-80 w-60 d-flex align-items-center justify-content-center rounded-full"
                    >
                        <Link>
                            <img src={arrowleftblack} alt="arrow" />
                        </Link>
                    </button>
                    <h3 className="main-title mainTitleLight">Venues</h3>
                </div>

                {data && data.venues && (
                    <div className="venue-container">
                        {data.venues.map((venue) => (
                            <div key={venue.id} className="venue-item">
                                <Link
                                    to={`/selfSlotBook?id=${venue.id}`}
                                    style={{ textDecoration: "none" }}>
                                    <section className="venue-card">
                                        <div className="image-container">
                                            <img
                                                src={`${apiImageUrl}/venue_image/${venue.images}`}
                                                alt="Venue"
                                                style={{ marginBottom: '5px' }}
                                            />
                                        </div>
                                        <div className="list-container" style={{ margin: "5px 5px" }}>
                                            <h6 style={{ marginBottom: "0px", textDecoration: "none", color: "black" }}>{venue.name}</h6>
                                        </div>
                                    </section>
                                </Link>
                            </div>
                        ))}
                    </div>
                )}
            </section>
            {/* Footer */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "#fff",
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-around",
                    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                }}
            >
                <Link to="/vendor_dashboard">
                    <IconButton>
                        <HomeIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_bookings"> {/* Add the booking link */}
                    <IconButton>
                        <BookIcon /> {/* Use the booking icon */}
                    </IconButton>
                </Link>
                <Link to="/vendor_addvenue">
                    <IconButton>
                        <AddCircleIcon />
                    </IconButton>
                </Link>
                <Link to="/vendor_query">
                    <IconButton>
                        <QuestionAnswerIcon />
                    </IconButton>
                </Link>
                <Link to="/vendorUpdate_profile">
                    <IconButton>
                        <PersonIcon />
                    </IconButton>
                </Link>
            </Box>
        </div>
    );
};

export default ViewVenueBooking;
