import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAtx6-BtB6gMJoafai5uVQj6GCvkFK_I4w",
    authDomain: "kelomore-73354.firebaseapp.com",
    projectId: "kelomore-73354",
    storageBucket: "kelomore-73354.appspot.com",
    messagingSenderId: "864949380556",
    appId: "1:864949380556:web:38cd97a1986dd89f9f6969",
    measurementId: "G-8JLST9H5L9"
    // apiKey: "AIzaSyB5Qn8Qk4oI0Y1Fh07qzixx5_iWZeJ5AHg",
    // authDomain: "push-notification-2820d.firebaseapp.com",
    // projectId: "push-notification-2820d",
    // storageBucket: "push-notification-2820d.appspot.com",
    // messagingSenderId: "789404783234",
    // appId: "1:789404783234:web:a3c88fa694d864dc9b1a9b"
};

const app = initializeApp(firebaseConfig);

const messagingPromise = isSupported().then((supported) => {
    if (supported) {
        const messagingInstance = getMessaging(app);
        console.log("Firebase Messaging supported and initialized:", messagingInstance);

        // Listen for foreground messages
        onMessage(messagingInstance, (payload) => {
            console.log('Foreground message received:', payload);
            // You can update the UI directly instead of showing a notification
            const { title, body, image } = payload.notification;
            new Notification(title, { body, icon: image });
        });

        return messagingInstance;
    } else {
        console.error("Firebase Messaging is not supported in this browser.");
        return null;
    }
});

// Function to request permission for notifications and get the FCM token
export const requestPermission = async () => {
    try {
        const messaging = await messagingPromise;
        if (!messaging) {
            console.error("Firebase Messaging is not initialized.");
            return;
        }

        // Request permission for notifications
        const permission = await Notification.requestPermission();
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const userId = localStorage.getItem("id2");

        if (permission !== 'granted') {
            console.error("Notifications permission denied.");
            return;
        }

        const currentToken = await getToken(messaging, { vapidKey: "BNnFkWqgS6m585xEsILklqqnS7JkAm5lKGHyTY8EPMuTDZ0UZzZec7nZsPWV7nalzXM4OXj6nbql2Wfzd8dgOjk" });

        if (currentToken) {
            // Send the token to your backend server
            console.log('Current token is:', currentToken);
            const response = await fetch(`${apiBaseUrl}/save_fcm_token/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: currentToken }),
            });

            if (!response.ok) {
                console.error("Failed to send the token to the server.");
            }
        } else {
            console.error("Failed to obtain FCM token.");
        }
    } catch (error) {
        console.error("An error occurred while requesting permission or sending the token:", error);
    }
};

export { messagingPromise };





// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

// // Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyB5Qn8Qk4oI0Y1Fh07qzixx5_iWZeJ5AHg",
//     authDomain: "push-notification-2820d.firebaseapp.com",
//     projectId: "push-notification-2820d",
//     storageBucket: "push-notification-2820d.appspot.com",
//     messagingSenderId: "789404783234",
//     appId: "1:789404783234:web:a3c88fa694d864dc9b1a9b"
// };

// // Initialize Firebase app
// const app = initializeApp(firebaseConfig);

// const messagingPromise = isSupported().then((supported) => {
//     if (supported) {
//         const messagingInstance = getMessaging(app);
//         console.log("Firebase Messaging supported and initialized:", messagingInstance);

//         // Listen for foreground messages
//         onMessage(messagingInstance, (payload) => {
//             console.log('Foreground message received:', payload);
//             // Display a notification in the foreground
//             const { title, body, image } = payload.notification;
//             new Notification(title, { body, icon: image });
//         });

//         return messagingInstance;
//     } else {
//         console.error("Firebase Messaging is not supported in this browser.");
//         return null;
//     }
// });

// // Function to request permission for notifications and get the FCM token
// export const requestPermission = async () => {
//     try {
//         const messaging = await messagingPromise;
//         if (!messaging) {
//             console.error("Firebase Messaging is not initialized.");
//             return;
//         }

//         // Request permission for notifications
//         const permission = await Notification.requestPermission();
//         const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
//         const userId = localStorage.getItem("id2");

//         if (permission !== 'granted') {
//             console.error("Notifications permission denied.");
//             return;
//         }

//         const currentToken = await getToken(messaging, { vapidKey: "BHQPWrQxD_6XzaHaRG-Tar8t9a7-0QI875L60bi6nQ1ZaXUD_N8yLpu0VUSdPVrK6GQHlC4u3n-cdQtR3gaTFsg" });

//         if (currentToken) {
//             // Send the token to your backend server
//             console.log('Current token is:', currentToken);
//             const response = await fetch(`${apiBaseUrl}/save_fcm_token/${userId}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ token: currentToken }),
//             });

//             if (!response.ok) {
//                 console.error("Failed to send the token to the server.");
//             }
//         } else {
//             console.error("Failed to obtain FCM token.");
//         }
//     } catch (error) {
//         console.error("An error occurred while requesting permission or sending the token:", error);
//     }
// };

// export { messagingPromise };




// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

// // Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyB5Qn8Qk4oI0Y1Fh07qzixx5_iWZeJ5AHg",
//     authDomain: "push-notification-2820d.firebaseapp.com",
//     projectId: "push-notification-2820d",
//     storageBucket: "push-notification-2820d.appspot.com",
//     messagingSenderId: "789404783234",
//     appId: "1:789404783234:web:a3c88fa694d864dc9b1a9b"
// };

// // Initialize Firebase app
// const app = initializeApp(firebaseConfig);

// const messagingPromise = isSupported().then((supported) => {
//     if (supported) {
//         const messagingInstance = getMessaging(app);
//         console.log("Firebase Messaging supported and initialized:", messagingInstance);

//         // Listen for foreground messages
//         onMessage(messagingInstance, (payload) => {
//             console.log('Foreground message received:', payload);
//             // Handle the message if necessary
//         });

//         return messagingInstance;
//     } else {
//         console.error("Firebase Messaging is not supported in this browser.");
//         return null;
//     }
// });

// // Function to request permission for notifications and get the FCM token
// export const requestPermission = async () => {
//     try {
//         const messaging = await messagingPromise;
//         if (!messaging) {
//             console.error("Firebase Messaging is not initialized.");
//             return;
//         }

//         // Request permission for notifications
//         const permission = await Notification.requestPermission();
//         const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
//         const userId = localStorage.getItem("id2");

//         if (permission !== 'granted') {
//             console.error("Notifications permission denied.");
//             return;
//         }

//         const currentToken = await getToken(messaging, { vapidKey: "BHQPWrQxD_6XzaHaRG-Tar8t9a7-0QI875L60bi6nQ1ZaXUD_N8yLpu0VUSdPVrK6GQHlC4u3n-cdQtR3gaTFsg" });

//         if (currentToken) {
//             // Send the token to your backend server
//             console.log('Current token is:', currentToken);
//             const response = await fetch(`${apiBaseUrl}/save_fcm_token/${userId}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ token: currentToken }),
//             });

//             if (!response.ok) {
//                 console.error("Failed to send the token to the server.");
//             }
//         } else {
//             console.error("Failed to obtain FCM token.");
//         }
//     } catch (error) {
//         console.error("An error occurred while requesting permission or sending the token:", error);
//     }
// };

// export { messagingPromise };

// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken } from 'firebase/messaging';

// // Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyB5Qn8Qk4oI0Y1Fh07qzixx5_iWZeJ5AHg",
//     authDomain: "push-notification-2820d.firebaseapp.com",
//     projectId: "push-notification-2820d",
//     storageBucket: "push-notification-2820d.appspot.com",
//     messagingSenderId: "789404783234",
//     appId: "1:789404783234:web:a3c88fa694d864dc9b1a9b"
// };


// // Initialize Firebase app
// const app = initializeApp(firebaseConfig);

// // Initialize Firebase Messaging
// export const messaging = getMessaging(app);

// // Function to request permission for notifications and get the FCM token
// export const requestPermission = async () => {
//     try {
//         // Request permission for notifications
//         const permission = await Notification.requestPermission();
//         const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
//         const userId = localStorage.getItem("id2");

//         if (permission !== 'granted') {
//             console.error("Notifications permission denied.");
//             return;
//         }

//         if (!messaging) {
//             console.error("Firebase Messaging is not supported in this browser.");
//             return;
//         }

//         const currentToken = await getToken(messaging, { vapidKey: "BHQPWrQxD_6XzaHaRG-Tar8t9a7-0QI875L60bi6nQ1ZaXUD_N8yLpu0VUSdPVrK6GQHlC4u3n-cdQtR3gaTFsg" });

//         if (currentToken) {
//             // Send the token to your backend server
//             console.log('current token is:', currentToken)
//             const response = await fetch(`${apiBaseUrl}/save_fcm_token/${userId}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ token: currentToken }),
//             });


//         } else {
//             console.error("Failed to obtain FCM token.");
//         }
//     } catch (error) {
//         console.error("An error occurred while requesting permission or sending the token:", error);
//     }
// };