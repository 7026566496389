import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { Tabs, Tab, Box, Typography } from "@mui/material";
import manualcss from '../assest/css/manualcss.css';
import ssk from "../assest/recommendationimage/ssk.svg";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import commoncss from "../assest/css/common.css";
import vendorBookingCss from "../assest/css/vendor_booking.css";
import kyccss from "../FieldsExecutive/kyc.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";

import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BookIcon from '@mui/icons-material/Book'; // Import the booking icon

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const VendorBooking = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [data, setData] = useState({ UpcomingBooking: [], CompleteBooking: [], RejectBooking: [], Payment: [] });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loadList = async () => {
    try {
      const userId = localStorage.getItem("id2");
      console.log('view id', userId);
      const result = await axios.get(`${apiBaseUrl}/vendorUpcomingBooking/${userId}`);
      setData((prevData) => ({ ...prevData, UpcomingBooking: result.data.UpcomingBooking || [] }));
      console.log("Result data:", result.data);
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  const comBook = async () => {
    try {
      const userId = localStorage.getItem("id2");
      console.log('view id', userId);
      const result = await axios.get(`${apiBaseUrl}/vendorCompleteBooking/${userId}`);
      setData((prevData) => ({ ...prevData, CompleteBooking: result.data.CompleteBooking || [] }));
      console.log("Result data:", result.data);
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  const rejBook = async () => {
    try {
      const userId = localStorage.getItem("id2");
      console.log('view id', userId);
      const result = await axios.get(`${apiBaseUrl}/vendorRejectBooking/${userId}`);
      setData((prevData) => ({ ...prevData, RejectBooking: result.data.RejectBooking || [] }));
      console.log("Result data:", result.data);
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  const payBook = async () => {
    try {
      const userId = localStorage.getItem("id2");
      console.log('view id', userId);
      const result = await axios.get(`${apiBaseUrl}/vendorBookingAmount/${userId}`);
      setData((prevData) => ({ ...prevData, Payment: result.data.Payment || [] }));
      console.log("Result data:", result.data);
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  useEffect(() => {
    loadList();
    comBook();
    rejBook();
    payBook();
  }, []);

  const back = () => {
    navigate("/vendor_dashboard");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/vendor_signin");
  };

  const rejectBooking = async (bookingCode) => {
    const apiUrl = `https://turf.ukvalley.com/public/api/rejectBooking/${bookingCode}`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        alert('Booking rejected successfully.');
        window.location.reload(); // Reload the page
      } else {
        alert('Failed to reject the booking.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while rejecting the booking.');
    }
  };

  return (
    <main>
      <div style={{ marginBottom: "40px" }}>
        <div className="page-title pageHead">
          <button
            onClick={back}
            type="button"
            className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
          >
            <img src={arrowleftblack} alt="arrow" />
          </button>
          <h3 className="main-title mainTitleLight">Booking</h3>
        </div>
        <div>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example ">
            <Tab className="bookingTabes" label="Upcoming" {...a11yProps(0)} />
            <Tab className="bookingTabes" label="Completed" {...a11yProps(1)} />
            <Tab className="bookingTabes" label="Reject" {...a11yProps(2)} />
            <Tab className="bookingTabes" label="Payment" {...a11yProps(3)} />
          </Tabs>
          <TabPanel value={value} index={0} style={{ padding: '0px' }}>
            {data.UpcomingBooking.length ? (
              <ul style={{ paddingLeft: 0 }}>
                {data.UpcomingBooking.map((row, index) => (
                  <li key={index}>
                    <section
                      className="message"
                      style={{
                        marginTop: "0px !important",
                        marginBottom: "-5px !important",
                        padding: '10px !important'
                      }}
                    >
                      <div className="single-chat position-relative">
                        <a
                          style={{ textDecoration: "none", border: '1px solid #e3e3e3', margin: '4px 3px 10px 3px', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                          className="single-main d-flex align-items-center justify-content-between radius-12"
                        >
                          <div className="shrink-0 d-flex align-items-center">
                            <div className="image shrink-0 position-relative" style={{ marginRight: '8px' }}>
                              <img
                                src={`https://turf.ukvalley.com/public/venue_image/${row.details.venue_img}`}
                                alt="Avatar"
                                className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                              />
                            </div>
                            <div className="list-container" style={{ marginRight: '3px' }}>
                              <h4 style={{ width: '130px', fontSize: "15px" }}>{row.user?.name.split(' ')[0]} Booked {row.details.venue_name}</h4>
                              <p style={{ width: '130px' }} >{row.booking.booking_date} </p>
                            </div>
                          </div>

                          <div className="text-end buttons_container list-container" style={{ marginRight: '1px !important' }}>
                            <p>{row.details.start} - {row.details.end}</p>
                            <button
                              className="reject_booking"
                              onClick={() => {
                                if (window.confirm("Are you sure you want to reject this booking?")) {
                                  rejectBooking(row.booking.booking_code);
                                }
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </a>
                      </div>
                    </section>
                  </li>
                ))}
              </ul>
            ) : (
              <Typography>No upcoming bookings.</Typography>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {data.CompleteBooking.length ? (
              <ul style={{ paddingLeft: 0 }}>
                {data.CompleteBooking.map((row, index) => (
                  <li key={index}>
                    <section
                      className="message"
                      style={{
                        marginTop: "0px !important",
                        marginBottom: "-5px !important",
                      }}
                    >
                      <div className="single-chat position-relative">
                        <a
                          style={{ textDecoration: "none", border: '1px solid #e3e3e3', margin: '4px 3px 10px 3px', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                          className="single-main d-flex align-items-center justify-content-between radius-12"
                        >
                          <div className="shrink-0 d-flex align-items-center">
                            <div className="image shrink-0 position-relative" style={{ marginRight: '8px' }}>
                              <img
                                src={`https://turf.ukvalley.com/public/venue_image/${row.details.venue_img}`}
                                alt="Avatar"
                                className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                              />
                            </div>
                            <div className="list-container" style={{ marginRight: '3px' }}>
                              <h4 style={{ width: '130px', fontSize: "15px" }}>{row.user?.name.split(' ')[0]} Booked {row.details.venue_name}</h4>
                              <p style={{ width: '130px' }} >{row.booking.booking_date} </p>
                            </div>
                          </div>

                          <div className="text-end buttons_container list-container" style={{ marginRight: '1px !important' }}>
                            <p>{row.details.start} - {row.details.end}</p>
                            <p>{row.booking.amount}</p>
                          </div>
                        </a>
                      </div>
                    </section>
                    {/* <div className="horizontal-line"></div> */}
                  </li>
                ))}
              </ul>
            ) : (
              <Typography>No complete bookings.</Typography>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {data.RejectBooking.length ? (
              <ul style={{ paddingLeft: 0 }}>
                {data.RejectBooking.map((row, index) => (
                  <li key={index}>
                    <section
                      className="message"
                      style={{
                        marginTop: "0px !important",
                        marginBottom: "-5px !important",
                      }}
                    >
                      <div className="single-chat position-relative">
                        <a
                          style={{ textDecoration: "none", border: '1px solid #e3e3e3', margin: '4px 3px 10px 3px', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                          className="single-main d-flex align-items-center justify-content-between gap-04 radius-12"
                        >
                          <div className="shrink-0 d-flex align-items-center gap-12">
                            <div className="image shrink-0 position-relative" style={{ marginRight: '8px' }}>
                              <img
                                src={`https://turf.ukvalley.com/public/venue_image/${row.details.venue_img}`}
                                alt="Avatar"
                                className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                              />
                            </div>
                            <div className="list-container" style={{ marginRight: '3px' }}>
                              <h4 style={{ width: '130px', fontSize: "15px" }}>{row.user?.name.split(' ')[0]} Paid for {row.details.venue_name}</h4>
                              <p style={{ width: '130px' }}>{row.booking.booking_date} </p>
                            </div>
                          </div>

                          <div className="text-end buttons_container list-container" style={{ marginRight: '1px !important' }}>
                            <p>{row.booking.amount}</p>
                            <p>{row.booking.booking_code}</p>
                          </div>
                        </a>
                      </div>
                    </section>
                    {/* <div className="horizontal-line"></div> */}
                  </li>
                ))}
              </ul>
            ) : (
              <Typography>No rejected bookings.</Typography>
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {data.Payment.length ? (
              <ul style={{ paddingLeft: 0 }}>
                {data.Payment.map((row, index) => (
                  <li key={index}>
                    <section
                      className="message"
                      style={{
                        marginTop: "0px !important",
                        marginBottom: "-5px !important",
                      }}
                    >
                      <div className="single-chat position-relative">
                        <a
                          style={{ textDecoration: "none", border: '1px solid #e3e3e3', margin: '4px 3px 10px 3px', padding: '10px', boxShadow: '2px 2px 4px 0px gray' }}
                          className="single-main d-flex align-items-center justify-content-between gap-04 radius-12"
                        >
                          <div className="shrink-0 d-flex align-items-center gap-12">
                            <div className="image shrink-0 position-relative" style={{ marginRight: '8px' }}>
                              <img
                                src={`https://turf.ukvalley.com/public/venue_image/${row.details.venue_img}`}
                                alt="Avatar"
                                className="img-fluid w-100 h-100 object-fit-cover rounded-full"
                              />
                            </div>
                            <div className="list-container" style={{ marginRight: '3px' }}>
                              <h4 style={{ width: '130px', fontSize: "15px" }}>{row.user?.name.split(' ')[0]} Paid for {row.details.venue_name}</h4>
                              <p style={{ width: '130px' }}>{row.booking.booking_date} </p>
                            </div>
                          </div>

                          <div className="text-end buttons_container list-container" style={{ marginRight: '1px !important' }}>
                            <p>{row.booking.amount}</p>
                            <p>{row.booking.booking_code}</p>
                          </div>
                        </a>
                      </div>
                    </section>
                    {/* <div className="horizontal-line"></div> */}
                  </li>
                ))}
              </ul>
            ) : (
              <Typography>No booking payments.</Typography>
            )}
          </TabPanel>
        </div>
      </div>
      {/* Footer */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#fff",
          padding: 1,
          display: "flex",
          justifyContent: "space-around",
          boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
        }}
      >
        <Link to="/vendor_dashboard">
          <IconButton>
            <HomeIcon />
          </IconButton>
        </Link>
        <Link to="/vendor_bookings"> {/* Add the booking link */}
          <IconButton>
            <BookIcon /> {/* Use the booking icon */}
          </IconButton>
        </Link>
        <Link to="/vendor_addvenue">
          <IconButton>
            <AddCircleIcon />
          </IconButton>
        </Link>
        <Link to="/vendor_query">
          <IconButton>
            <QuestionAnswerIcon />
          </IconButton>
        </Link>
        <Link to="/vendorUpdate_profile">
          <IconButton>
            <PersonIcon />
          </IconButton>
        </Link>
      </Box>
    </main>
  );
};

export default VendorBooking;
