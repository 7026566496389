import React, { useState } from "react";
import bootstrapcss from "../assest/css/bootstrap.min.css";
import swipercss from "../assest/css/swiper-bundle.min.css";
import jquerycss from "../assest/css/jquery-ui.min.css";
import commoncss from "../assest/css/common.css";
import welcomecss from "../assest/css/welcome.css";
import animationscss from "../assest/css/animations.css";
import authcss from "../assest/css/auth.css";
import eyeon from "../assest/svg/eye-on.svg";
import eyeoff from "../assest/svg/eye-off.svg";
import icongoogle from "../assest/svg/icon-google.svg";
import iconapple from "../assest/svg/icon-apple.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import otp from "../assest/homeimage/otp.png";

import axios from "axios";
import Modal from "../Credantails/modal";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";

const VendorForgetpassword = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");


  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      localStorage.setItem(
        "modalMessage",
        JSON.stringify({
          title: "Password Reset Request Sent",
          content:
            "Password reset request sent successfully. Check your email for OTP.",
        })
      );

      const formDataToSend = new FormData();
      formDataToSend.append("email", email);

      const response = await axios.post(
        `${apiBaseUrl}/otpSend`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Redirect to the login page after successful password reset request
      const url = `/vendor_otpverify?email=${encodeURIComponent(email)}`;
      navigate(url);
    } catch (error) {

      console.error("Error sending password reset request:", error);
      setModalMessage({
        title: "Error",
        content:
          "Failed to send password reset request. Please try again later or check the email you entered.",
      });
      setShowModal(true);

    }
  };


  const back = () => {
    navigate("/vendor_signin");
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      <>
        {showModal && (
          <Modal message={modalMessage} closeModal={closeModal} />
        )}
        <main>
          <section className="auth signin">
            <div className="page-title pageHead">
              <button
                onClick={back}
                type="button"
                className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
              >
                <img src={arrowleftblack} alt="arrow" />
              </button>
              <h3 className="main-title mainTitleLight">Enter Email</h3>
            </div>
            <div className="form-area auth-form" style={{ height: '80vh' }}>
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="lemail1">Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Enter your email address"
                    className="input-field"
                    onChange={handleChange}
                  />
                </div>

                <input value="Submit" type="submit" className="btn-primary" />
              </form>

              <h6>
                {" "}
                Back to Login Page? <Link to="/vendor_signup">Login</Link>
              </h6>
            </div>
          </section>
        </main>
      </>
    </div>
  );
};


export default VendorForgetpassword;