import React, { useEffect, useState } from 'react'

import swipercss from '../assest/css/swiper-bundle.min.css'

import commoncss from '../assest/css/common.css'
import welcomecss from '../assest/css/welcome.css'
import animationscss from '../assest/css/animations.css'
import authcss from '../assest/css/auth.css'
import profile from '../assest/css/profile.css'
import arrowleftblack from '../assest/svg/arrow-left-black.svg'


import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import axios, { Axios } from 'axios'
import { Hidden } from '@mui/material'


const VenueSlot = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [data, setData] = useState(null);



  const location = useLocation();
  const responseVenue = location?.state?.data;

  const [venueDat, setVenueDat] = useState(location?.state?.data);

  console.log("DDD", responseVenue);





  const [formData, setFormData] = useState({
    venue_id: responseVenue?.id || "",
    start_time: "",
    end_time: "",
    price: "",
  });





  const id2 = localStorage.getItem("id2");






  const fetchData = async () => {
    try {
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      navigate("/vendor_signin");
    } else {
      navigate("/venue_slot");
    }
    fetchData();
  }, []);



  const back = () => {
    navigate("/vendor_venue_list");
  };



  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
    console.log("Updated FormData:", { ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();

      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      console.log("FormData to send:", formDataToSend); // Log the formDataToSend

      const response = await axios.post(
        `${apiBaseUrl}/storeSlots`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const token = response.data.token;

      console.log("Token id is:", token);
      console.log("User id2 is:", id2);

      localStorage.setItem("token", token);
      localStorage.setItem("id2", id2);
      console.log("slots send :", formData);
      console.log("User tOKEN:", token);
      console.log("User id2:", id2);
      navigate("/vendor_dashboard");
    } catch (error) {
      console.log("Error while creating account", error);
    }
  };



  return (
    <div>
      <>
        <main>
          {/* page-title */}
          <div className="page-title pageHead">
            <button
              onClick={back}
              type="button"
              className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowleftblack} alt="arrow" />
            </button>
            <h3 className="main-title mainTitleLight">Add Venue Slot</h3>
          </div>

          <section className="user-info px-24" style={{ marginTop: '25px' }}>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="mb-16" style={{ margin: "5px", width: "50%" }}>
                  <label className="input-label">Select Start Time</label>
                  <input
                    type="time"
                    className="input"
                    id="start_time"
                    name="start_time"
                    placeholder='Enter the Start Time'
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-16" style={{ margin: "5px", width: "50%" }}>
                  <label className="input-label">Select End Time</label>
                  <input
                    type="time"
                    className="input"
                    id="end_time"
                    name="end_time"
                    placeholder='Enter the End Time'
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mb-16">
                <label className="input-label">Slot Rate </label>
                <input
                  type="number"
                  className="input"
                  name="price"
                  id="'price"
                  placeholder='Enter the Slot Rate'
                  onChange={handleChange}
                />
              </div>

              <input
                type="hidden"
                className="input"
                name="venue_id"
                value={formData.venue_id}
                id="'venue_id"
                onChange={handleChange}
              />

              <input type="submit" className="btn-primary" value="submit" />
            </form>
          </section>
        </main>
      </>
    </div>
  );
};



export default VenueSlot;




