import React from 'react'


import bundle from '../assest/assets-demo/css/style.css'
import App from '../App.css'
import homecss from '../assest/css/home.css'
import welcomecss from '../assest/css/welcome.css'
import commoncss from '../assest/css/common.css'
import jquerycss from '../assest/css/jquery-ui.min.css'
import swipercss from '../assest/css/swiper-bundle.min.css'
import bootstrapcss from '../assest/css/bootstrap.min.css'
import ticket from '../assest/css/ticket.css'

import humberg from '../assest/svg/menu/burger-white.svg'
import sunwhite from '../assest/svg/menu/sun-white.svg'
import moonwhite from '../assest/svg/menu/moon-white.svg'
import profilewhite from '../assest/svg/menu/profile-white.svg'
import closewhite from '../assest/svg/menu/close-white.svg'
import avatar from '../assest/images/profile/avatar.png'
import mapmarker from '../assest/svg/map-marker.svg'
import bellblack from '../assest/svg/bell-black.svg'
import messagesquaredots from '../assest/svg/message-square-dots.svg'
import search from '../assest/svg/search.svg'
import filterblack from '../assest/svg/filter-black.svg'

import cricketimg from '../assest/homeimage/cricket.png'
import footballimg from '../assest/homeimage/soccer-ball.png'
import volleyballimg from '../assest/homeimage/volleyball.png'
import category from '../assest/images/home/category.png'

import v3 from '../assest/homeimage/v3.jpg'
import v1 from '../assest/homeimage/v1.png'
import v2 from '../assest/homeimage/v2.png'

import ssk from '../assest/recommendationimage/ssk.svg'
import chakde from '../assest/recommendationimage/chakde.svg'
import runbumi from '../assest/recommendationimage/runbhumi.svg'
import stumps from '../assest/recommendationimage/stonch.svg'

import vocher1 from '../assest/homeimage/vocher1.svg'
import vocher2 from '../assest/homeimage/vocher2.svg'
import vocher3 from '../assest/homeimage/vocher3.svg'


import bottomhome from '../assest/svg/bottom-nav/home-active.svg'
import bottomcategary from '../assest/svg/bottom-nav/category.svg'
import bottomticket from '../assest/svg/bottom-nav/ticket.svg'
import bottomheart from '../assest/svg/bottom-nav/heart.svg'
import bottomprofile from '../assest/svg/bottom-nav/profile.svg'


// file:///C:/Users/Administrator/Downloads/preview/preview/pages/home.html


const HomeApp = () => {
  return (

    <div>
      <>
        {/* Mirrored from devsaidul.com/tm2/html/preview/pages/home.html by HTTrack Website Copier/3.x [XR&CO'2014], Wed, 20 Mar 2024 06:07:49 GMT */}
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Travgo</title>
        {/* favicon */}
        <link
          rel="shortcut icon"
          href="../assets/images/favicon.png"
          type="image/x-icon"
        />
        {/* bootstrap */}
        <link rel="stylesheet" href="../assets/css/bootstrap.min.css" />
        {/* swiper */}
        <link rel="stylesheet" href="../assets/css/swiper-bundle.min.css" />
        {/* datepicker */}
        <link rel="stylesheet" href="../assets/css/jquery.datetimepicker.css" />
        {/* jquery ui */}
        <link rel="stylesheet" href="../assets/css/jquery-ui.min.css" />
        {/* common */}
        <link rel="stylesheet" href="../assets/css/common.css" />
        {/* animations */}
        <link rel="stylesheet" href="../assets/css/animations.css" />
        {/* welcome */}
        <link rel="stylesheet" href="../assets/css/welcome.css" />
        {/* home */}
        <link rel="stylesheet" href="../assets/css/home.css" />




        <main className="home">
          {/* menu, side-menu start */}

          <section className="wrapper dz-mode">
            {/* menu */}


            <div className="menu">


              <button className="toggle-btn">
                <img
                  src={humberg}
                  alt=""
                  className="icon"
                />
              </button>


              <div className="btn-grp d-flex align-items-center gap-16">
                <label
                  htmlFor="mode-change"
                  className="mode-change d-flex align-items-center justify-content-center"
                >
                  <input type="checkbox" id="mode-change" />
                  <img
                    src={sunwhite}
                    alt="icon"
                    className="sun"
                  />
                  <img
                    src={moonwhite}
                    alt="icon"
                    className="moon"
                  />
                </label>



                <a href="profile/user-profile.html">
                  <img src={profilewhite} alt="icon" />
                </a>
              </div>


            </div>


            <div className="m-menu__overlay" />
            {/* main menu */}
            <div className="m-menu">
              <div className="m-menu__header">
                <button className="m-menu__close">

                  <img src={closewhite} />
                </button>
                <div className="menu-user">

                  <img src="../assets/images/profile/avatar.png" alt="avatar" />
                  <div>
                    <a href="#!">angela mayer</a>
                    <h3>Verified user · Membership</h3>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <h2 className="menu-title">menu</h2>
                </li>
                <li>
                  <a href="home.html">
                    <div className="d-flex align-items-center gap-16">
                      <span className="icon">
                        <img src="../assets/svg/menu/pie-white.svg" alt="" />
                      </span>
                      overview
                    </div>
                    <img src="../assets/svg/menu/chevron-right-black.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="../page.html">
                    <div className="d-flex align-items-center gap-16">
                      <span className="icon">
                        <img src="../assets/svg/menu/page-white.svg" alt="" />
                      </span>
                      pages
                    </div>
                    <img src="../assets/svg/menu/chevron-right-black.svg" alt="" />
                  </a>
                </li>
                <li>
                  <h2 className="menu-title">others</h2>
                </li>
                <li>
                  <label className="a-label__chevron" htmlFor="item-4">
                    <span className="d-flex align-items-center gap-16">
                      <span className="icon">
                        <img src="../assets/svg/menu/grid-white.svg" alt="" />
                      </span>
                      components
                    </span>
                    <img src="../assets/svg/menu/chevron-right-black.svg" alt="" />
                  </label>
                  <input
                    type="checkbox"
                    id="item-4"
                    name="item-4"
                    className="m-menu__checkbox"
                  />
                  <div className="m-menu">
                    <div className="m-menu__header">
                      <label className="m-menu__toggle" htmlFor="item-4">
                        <img src="../assets/svg/menu/back-white.svg" alt="" />
                      </label>
                      <span className="m-menu__header-title">components</span>
                    </div>
                    <ul>
                      <li>
                        <a href="../components/splash-screen.html">
                          <div className="d-flex align-items-center gap-16">
                            <span className="icon">
                              <img
                                src="../assets/svg/menu/box-white.svg"
                                alt="icon"
                              />
                            </span>
                            splash screen
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <label className="a-label__chevron" htmlFor="item-5">
                    <span className="d-flex align-items-center gap-16">
                      <span className="icon">
                        <img src="../assets/svg/menu/gear-white.svg" alt="" />
                      </span>
                      settings
                    </span>
                    <img src="../assets/svg/menu/chevron-right-black.svg" alt="" />
                  </label>
                  <input
                    type="checkbox"
                    id="item-5"
                    name="item-5"
                    className="m-menu__checkbox"
                  />
                  <div className="m-menu">
                    <div className="m-menu__header">
                      <label className="m-menu__toggle" htmlFor="item-5">
                        <img src="../assets/svg/menu/back-white.svg" alt="" />
                      </label>
                      <span className="m-menu__header-title">settings</span>
                    </div>
                    <ul>
                      <li>
                        <a href="profile/user-address.html">
                          <div className="d-flex align-items-center gap-16">
                            <span className="icon">
                              <img
                                src="../assets/svg/menu/box-white.svg"
                                alt="icon"
                              />
                            </span>
                            My Address
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="profile/user-payment.html">
                          <div className="d-flex align-items-center gap-16">
                            <span className="icon">
                              <img
                                src="../assets/svg/menu/box-white.svg"
                                alt="icon"
                              />
                            </span>
                            Payment Method
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="profile/change-password.html">
                          <div className="d-flex align-items-center gap-16">
                            <span className="icon">
                              <img
                                src="../assets/svg/menu/box-white.svg"
                                alt="icon"
                              />
                            </span>
                            Change Password
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="profile/forgot-password.html">
                          <div className="d-flex align-items-center gap-16">
                            <span className="icon">
                              <img
                                src="../assets/svg/menu/box-white.svg"
                                alt="icon"
                              />
                            </span>
                            Forgot Password
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="profile/security.html">
                          <div className="d-flex align-items-center gap-16">
                            <span className="icon">
                              <img
                                src="../assets/svg/menu/box-white.svg"
                                alt="icon"
                              />
                            </span>
                            Security
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="profile/user-language.html">
                          <div className="d-flex align-items-center gap-16">
                            <span className="icon">
                              <img
                                src="../assets/svg/menu/box-white.svg"
                                alt="icon"
                              />
                            </span>
                            Language
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="profile/notifications.html">
                          <div className="d-flex align-items-center gap-16">
                            <span className="icon">
                              <img
                                src="../assets/svg/menu/box-white.svg"
                                alt="icon"
                              />
                            </span>
                            Notifications
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="dz-switch">
                  <div className="a-label__chevron">
                    <div className="d-flex align-items-center gap-16">
                      <span className="icon">
                        <img src="../assets/svg/menu/moon-white.svg" alt="" />
                      </span>
                      switch to dark mode
                    </div>
                    <label className="toggle-switch" htmlFor="enableMode">
                      <input
                        type="checkbox"
                        id="enableMode"
                        className="mode-switch"
                      />
                      <span className="slider" />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            {/* end main menu */}
          </section>
          {/* menu, side-menu end */}
          {/* info start */}




          <section className="info d-flex align-items-start justify-content-between pb-12">
            <div className="d-flex align-items-center justify-content-between gap-14">
              <div className="image shrink-0 rounded-full overflow-hidden">
                <img
                  src={avatar}
                  alt="avatar"
                  className="w-100 h-100 object-fit-cover"
                />
              </div>
              <div>
                <h3>Hi, Valeed </h3>
                <p className="d-flex align-items-center gap-04">
                  <img src={mapmarker} alt="icon" />
                  India
                </p>
              </div>
            </div>

            <ul className="d-flex align-items-center gap-16">
              <li>
                <a
                  href="notification.html"
                  className="d-flex align-items-center justify-content-center rounded-full position-relative"
                >
                  <img src={bellblack} alt="icon" />
                  <span className="dot" />
                </a>
              </li>
              <li>
                <a
                  href="chat/message.html"
                  className="d-flex align-items-center justify-content-center rounded-full position-relative"
                >
                  <img src={messagesquaredots} alt="icon" />
                  <span className="dot" />
                </a>
              </li>
            </ul>
          </section>





          {/* info end */}
          {/* search start */}


          <section className="search py-12">
            <form action="#">
              <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24">
                <img
                  src={search}
                  alt="search"
                  className="shrink-0"
                />
                <input
                  type="search"
                  className="input-search input-field"
                  placeholder="Search..."
                />
                <div className="filter shrink-0">
                  <button
                    type="button"
                    className="d-flex align-items-center"
                    data-bs-toggle="modal"
                    data-bs-target="#filterModal"
                  >
                    <img src={filterblack} alt="filter" />


                  </button>
                </div>
              </div>
            </form>
          </section>
          {/* search end */}




          <section className="guide py-12">


            <div className="title align-items-center justify-content-between">
              <h2 className="shrink-0">Sports Vocher </h2>


              <div class="row row-cols-2 g-3">
                <div class="col">
                  <div class="card">

                    <div class="card-body">
                      <h5 class="card-title">Payment</h5>
                      <p class="card-text">
                        Add payment
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card">

                    <div class="card-body">
                      <h5 class="card-title">Turf</h5>
                      <p class="card-text">
                        Turf Rating.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">

                    <div class="card-body">
                      <h5 class="card-title">Executive</h5>
                      <p class="card-text">Add Executive</p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">

                    <div class="card-body">
                      <h5 class="card-title">Task </h5>
                      <p class="card-text">
                        Task Completed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>











          </section>

          {/* visited start */}
          <section className="visited py-12">
            {/* title */}
            <div className="title d-flex align-items-center justify-content-between">
              <h2 className="shrink-0">Discount Vocher</h2>

              <div className="custom-pagination visited-pagination" />
            </div>
            <div className="swiper visited-swiper">
              <div className="swiper-wrapper">
                {/* item-1 */}
                <div className="swiper-slide place-card">
                  <a href="vacation-details.html">


                    <div className="image position-relative ">
                      <img
                        src={v1}
                        alt="desert"
                        className="img-fluid w-100 overflow-hidden radius-8 "
                      />

                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>





          {/* visited end */}
          {/* guide start */}


          <section className="guide py-12">
            {/* title */}

            <div className="title d-flex align-items-center justify-content-between">
              <h2 className="shrink-0">Recommended</h2>


              <a href="tour-guide.html" className="shrink-0 d-inline-block">
                See All
              </a>
            </div>


            {/* cards */}


            <div className="d-flex gap-24 all-cards scrollbar-hidden">







              <a
                href="profile/guide-profile.html"
                className="d-flex gap-16 item w-fit shrink-0">

                <div className="image position-relative shrink-0">


                  <img
                    src={ssk}
                    alt="guide"
                    className="guide-img object-fit-cover img-fluid radius-12"
                  />

                  <div className="rating d-flex align-items-center gap-04 w-fit">

                    <span className="d-inline-block">ssk</span>
                  </div>

                </div>





              </a>





              <a
                href="profile/guide-profile.html"
                className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">


                  <img
                    src={stumps}
                    alt="guide"
                    className="guide-img object-fit-cover img-fluid radius-12"
                  />

                  <div className="rating d-flex align-items-center gap-04 w-fit">

                    <span className="d-inline-block">stumps</span>
                  </div>

                </div>





              </a>





              <a
                href="profile/guide-profile.html"
                className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">


                  <img
                    src={chakde}
                    alt="guide"
                    className="guide-img object-fit-cover img-fluid radius-12"
                  />

                  <div className="rating d-flex align-items-center gap-04 w-fit">

                    <span className="d-inline-block">chakde</span>
                  </div>

                </div>





              </a>


              {/* ///item4/// */}

              <a
                href="profile/guide-profile.html"
                className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">


                  <img
                    src={runbumi}
                    alt="guide"
                    className="guide-img object-fit-cover img-fluid radius-12"
                  />

                  <div className="rating d-flex align-items-center gap-04 w-fit">

                    <span className="d-inline-block">runbumi</span>
                  </div>

                </div>





              </a>















            </div>

          </section>





          {/* spotlight */}




          <section className="guide py-12">
            {/* title */}

            <div className="title d-flex align-items-center justify-content-between">
              <h2 className="shrink-0">Spot Light</h2>


              <a href="tour-guide.html" className="shrink-0 d-inline-block">
                See All
              </a>
            </div>


            {/* cards */}


            <div className="d-flex gap-24 all-cards scrollbar-hidden">







              <a
                href="profile/guide-profile.html"
                className="d-flex gap-16 item w-fit shrink-0">

                <div className="images position-relative shrink-0">


                  <img
                    src={vocher1}
                    alt="guide"
                    className="guides-img object-fit-cover img-fluid radius-12"
                  />

                  <div className="rating d-flex align-items-center gap-04 w-fit">

                    <span className="d-inline-block">ssk</span>
                  </div>

                </div>





              </a>





              <a
                href="profile/guide-profile.html"
                className="d-flex gap-16 item w-fit shrink-0">
                <div className="images position-relative shrink-0">


                  <img
                    src={vocher2}
                    alt="guide"
                    className="guides-img object-fit-cover img-fluid radius-12"
                  />

                  <div className="rating d-flex align-items-center gap-04 w-fit">

                    <span className="d-inline-block">stumps</span>
                  </div>

                </div>





              </a>





              <a
                href="profile/guide-profile.html"
                className="d-flex gap-16 item w-fit shrink-0">
                <div className="images position-relative shrink-0">


                  <img
                    src={vocher3}
                    alt="guide"
                    className="guides-img object-fit-cover img-fluid radius-12"
                  />

                  <div className="rating d-flex align-items-center gap-04 w-fit">

                    <span className="d-inline-block">chakde</span>
                  </div>

                </div>





              </a>


              {/* ///item4/// */}

              <a
                href="profile/guide-profile.html"
                className="d-flex gap-16 item w-fit shrink-0">
                <div className="images position-relative shrink-0">


                  <img
                    src={runbumi}
                    alt="guide"
                    className="guides-img object-fit-cover img-fluid radius-12"
                  />

                  <div className="rating d-flex align-items-center gap-04 w-fit">

                    <span className="d-inline-block">runbumi</span>
                  </div>

                </div>





              </a>














            </div>

          </section>





          {/* service start */}
          <section className="service py-12">
            {/* item 1 */}
            <a href="service-location.html">
              <figure className="item text-center">
                <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                  <img
                    src={cricketimg}
                    alt="airport"
                    className="img-fluid backface-hidden iconsize"
                  />
                </div>
                <figcaption>Cricket</figcaption>
              </figure>
            </a>
            {/* item 2 */}
            <a href="service-location.html">
              <figure className="item text-center">
                <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                  <img
                    src={footballimg}
                    alt="car"
                    className="img-fluid backface-hidden iconsize"
                  />
                </div>
                <figcaption>Football</figcaption>
              </figure>
            </a>
            {/* item 3 */}
            <a href="service-location.html">
              <figure className="item text-center">
                <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                  <img
                    src={volleyballimg}
                    alt="hotel"
                    className="img-fluid backface-hidden iconsize"
                  />
                </div>
                <figcaption>VolleyBall</figcaption>
              </figure>
            </a>
            {/* item 4 */}
            <figure
              className="item text-center"
              data-bs-toggle="modal"
              data-bs-target="#serviceModal"
            >
              <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                <img
                  src={category}
                  alt="category"
                  className="img-fluid backface-hidden"
                />
              </div>
              <figcaption>More</figcaption>
            </figure>
          </section>
          {/* service end */}






          {/* ////////////////////////toplisted///////////////////////////////////// */}

          <section className="budget pt-12">
            {/* title */}
            <div className="title d-flex align-items-center justify-content-between">
              <h2 className="shrink-0">Top Listed</h2>
              <a href="hotels.html" className="shrink-0 d-inline-block">
                See All
              </a>
            </div>

            <ul>
              {/* item 1 */}


              <li>
                <a
                  href="hotel-details.html"
                  className="d-flex align-items-center gap-12"
                >



                  <div className="ticket-card radius-8">
                    {/* card-title */}
                    <div className="card-title d-flex align-items-center justify-content-between">
                      <p>22 march 2022, Thu</p>
                      <span>Watch Video</span>
                    </div>
                    {/* card-item */}
                    <div className="card-item d-flex align-items-center gap-16 w-100">
                      <div className="image shrink-0 overflow-hidden radius-8">
                        <img
                          src={ssk}
                          alt="Place"
                          className="img-fluid w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <div className="content flex-grow">
                        <h4>Runbumi Turf</h4>
                        <p className="d-flex align-items-center gap-04 location mt-04">
                          {/* <img src="../assets/svg/map-marker.svg" alt="icon" /> */}
                          City Center Mall, Nashik
                        </p>
                        <p className="rating d-flex align-items-center gap-04 mt-16">
                          <img src="../assets/svg/star-yellow.svg" alt="icon" />
                          4.4 <span>(41 Reviews)</span>
                        </p>
                      </div>
                    </div>
                    {/* card-footer */}
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <div>
                        <p>Total Price</p>
                        <h3>$1000</h3>
                      </div>
                      <a href="ticket-detail.html">Detail</a>
                    </div>
                  </div>




                </a>
              </li>
              <hr></hr>

              {/* item 2 */}
              <li>
                <a
                  href="hotel-details.html"
                  className="d-flex align-items-center gap-12"
                >
                  <div className="ticket-card radius-8">
                    {/* card-title */}
                    <div className="card-title d-flex align-items-center justify-content-between">
                      <p>22 march 2022, Thu</p>
                      <span>Watch Video</span>
                    </div>
                    {/* card-item */}
                    <div className="card-item d-flex align-items-center gap-16 w-100">
                      <div className="image shrink-0 overflow-hidden radius-8">
                        <img
                          src={chakde}
                          alt="Place"
                          className="img-fluid w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <div className="content flex-grow">
                        <h4>Chakde Turf</h4>
                        <p className="d-flex align-items-center gap-04 location mt-04">
                          {/* <img src="../assets/svg/map-marker.svg" alt="icon" /> */}
                          Pakhal Road , Nashik
                        </p>
                        <p className="rating d-flex align-items-center gap-04 mt-16">
                          <img src="../assets/svg/star-yellow.svg" alt="icon" />
                          4.4 <span>(40 Reviews)</span>
                        </p>
                      </div>
                    </div>
                    {/* card-footer */}
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <div>
                        <p>Total Price</p>
                        <h3>$800</h3>
                      </div>
                      <a href="ticket-detail.html">Detail</a>
                    </div>
                  </div>
                </a>
              </li>
              <hr></hr>

              {/* item 3 */}
              <li>
                <a
                  href="hotel-details.html"
                  className="d-flex align-items-center gap-12"
                >



                  <div className="ticket-card radius-8">
                    {/* card-title */}
                    <div className="card-title d-flex align-items-center justify-content-between">
                      <p>22 march 2022, Thu</p>
                      <span>Watch Video</span>
                    </div>
                    {/* card-item */}
                    <div className="card-item d-flex align-items-center gap-16 w-100">
                      <div className="image shrink-0 overflow-hidden radius-8">
                        <img
                          src={stumps}
                          alt="Place"
                          className="img-fluid w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <div className="content flex-grow">
                        <h4>Stumps Turf</h4>
                        <p className="d-flex align-items-center gap-04 location mt-04">
                          {/* <img src="../assets/svg/map-marker.svg" alt="icon" /> */}
                          City Center Mall, Nashik
                        </p>
                        <p className="rating d-flex align-items-center gap-04 mt-16">
                          <img src="../assets/svg/star-yellow.svg" alt="icon" />
                          4.4 <span>(31 Reviews)</span>
                        </p>
                      </div>
                    </div>
                    {/* card-footer */}
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <div>
                        <p>Total Price</p>
                        <h3>$320</h3>
                      </div>
                      <a href="ticket-detail.html">Detail</a>
                    </div>
                  </div>
                </a>
              </li>
              <hr></hr>


            </ul>
          </section>
          {/* budget end */}



        </main>
        {/* bottom navigation start */}




        <footer className="bottom-nav">

          <ul className="d-flex align-items-center justify-content-around w-100 h-100">
            <li>
              <a href="home.html">
                <img src={bottomhome} alt="home" />


              </a>
            </li>
            <li>
              <a href="explore.html">
                <img src={bottomcategary} alt="category" />
              </a>
            </li>
            <li>
              <a href="ticket-booked.html">
                <img src={bottomticket} alt="ticket" />
              </a>
            </li>
            <li>
              <a href="wishlist.html">
                <img src={bottomheart} alt="heart" />
              </a>
            </li>
            <li>
              <a href="profile/user-profile.html">
                <img src={bottomprofile} alt="profile" />
              </a>
            </li>
          </ul>
        </footer>


        {/* bottom navigation end */}
        {/* service modal start */}




        <div
          className="modal fade serviceModal bottomModal modalBg"
          id="serviceModal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close rounded-full"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="../assets/svg/close-black.svg" alt="Close" />
                </button>
                <h1 className="modal-title">All Services</h1>
              </div>
              <div className="modal-body">
                {/* item 1 */}
                <a href="service-location.html">
                  <figure className="item text-center">
                    <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                      <img
                        src="../assets/images/home/airport.png"
                        alt="airport"
                        className="img-fluid backface-hidden"
                      />
                    </div>
                    <figcaption>Airport</figcaption>
                  </figure>
                </a>
                {/* item 2 */}
                <a href="service-location.html">
                  <figure className="item text-center">
                    <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                      <img
                        src="../assets/images/home/car.png"
                        alt="car"
                        className="img-fluid backface-hidden"
                      />
                    </div>
                    <figcaption>Taxi</figcaption>
                  </figure>
                </a>
                {/* item 3 */}
                <a href="service-location.html">
                  <figure className="item text-center">
                    <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                      <img
                        src="../assets/images/home/hotel.png"
                        alt="hotel"
                        className="img-fluid backface-hidden"
                      />
                    </div>
                    <figcaption>Hotel</figcaption>
                  </figure>
                </a>
                {/* item 4 */}
                <a href="service-location.html">
                  <figure className="item text-center">
                    <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                      <img
                        src="../assets/images/home/villa.png"
                        alt="airport"
                        className="img-fluid backface-hidden"
                      />
                    </div>
                    <figcaption>Villa</figcaption>
                  </figure>
                </a>
                {/* item 5 */}
                <a href="service-location.html">
                  <figure className="item text-center">
                    <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                      <img
                        src="../assets/images/home/cafe.png"
                        alt="car"
                        className="img-fluid backface-hidden"
                      />
                    </div>
                    <figcaption>Cafe</figcaption>
                  </figure>
                </a>
                {/* item 6 */}
                <a href="service-location.html">
                  <figure className="item text-center">
                    <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                      <img
                        src="../assets/images/home/luggage.png"
                        alt="hotel"
                        className="img-fluid backface-hidden"
                      />
                    </div>
                    <figcaption>Luggage</figcaption>
                  </figure>
                </a>
                {/* item 7 */}
                <a href="service-location.html">
                  <figure className="item text-center">
                    <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                      <img
                        src="../assets/images/home/boat.png"
                        alt="car"
                        className="img-fluid backface-hidden"
                      />
                    </div>
                    <figcaption>Ship</figcaption>
                  </figure>
                </a>
                {/* item 8 */}
                <a href="service-location.html">
                  <figure className="item text-center">
                    <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                      <img
                        src="../assets/images/home/camera.png"
                        alt="hotel"
                        className="img-fluid backface-hidden"
                      />
                    </div>
                    <figcaption>Camera </figcaption>
                  </figure>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* service modal end */}
        {/* filter modal start */}
        <div
          className="modal fade filterModal bottomModal"
          id="filterModal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close rounded-full"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="../assets/svg/close-black.svg" alt="Close" />
                </button>
                <h1 className="modal-title">Filter</h1>
              </div>
              <div className="modal-body">
                {/* price range */}
                <div className="pb-12">
                  <h4 className="content-title">Price Range</h4>
                  <div className="filter-range">
                    <div id="price-slider-range" />
                    <div className="price-range w-100 d-flex align-items-center justify-content-between">
                      <input type="text" id="amount1" />
                      <input type="text" id="amount2" />
                    </div>
                  </div>
                </div>
                {/* popular filters */}
                <div className="py-12">
                  <h4 className="content-title">Popular Filters</h4>
                  <ul className="popular-filters">
                    <li>
                      <label htmlFor="hotel" className="filter-label">
                        <input type="checkbox" id="hotel" />
                        Hotels (340)
                      </label>
                    </li>
                    <li>
                      <label htmlFor="pool" className="filter-label">
                        <input type="checkbox" id="pool" defaultChecked="" />
                        Swimming Pool (340)
                      </label>
                    </li>
                    <li>
                      <label htmlFor="stars" className="filter-label">
                        <input type="checkbox" id="stars" />5 stars (100)
                      </label>
                    </li>
                    <li>
                      <label htmlFor="bathroom" className="filter-label">
                        <input type="checkbox" id="bathroom" />
                        Private Bathroom (200)
                      </label>
                    </li>
                    <li>
                      <label htmlFor="breakfast" className="filter-label">
                        <input type="checkbox" id="breakfast" />
                        Breakfast Included (115)
                      </label>
                    </li>
                    <li>
                      <label htmlFor="kitchen" className="filter-label">
                        <input type="checkbox" id="kitchen" />
                        Kitchen (10)
                      </label>
                    </li>
                  </ul>
                </div>
                {/* star rating */}
                <div className="py-12">
                  <h4 className="content-title">Star Rating</h4>
                  <ul className="star-rating">
                    <li>
                      <label htmlFor="star1" className="filter-label">
                        <input type="radio" name="star" id="star1" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                      </label>
                    </li>
                    <li>
                      <label htmlFor="star2" className="filter-label">
                        <input type="radio" name="star" id="star2" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                      </label>
                    </li>
                    <li>
                      <label htmlFor="star3" className="filter-label">
                        <input type="radio" name="star" id="star3" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                      </label>
                    </li>
                    <li>
                      <label htmlFor="star4" className="filter-label">
                        <input
                          type="radio"
                          name="star"
                          id="star4"
                          defaultChecked=""
                        />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                      </label>
                    </li>
                    <li>
                      <label htmlFor="star5" className="filter-label">
                        <input type="radio" name="star" id="star5" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                        <img src="../assets/svg/star-yellow.svg" alt="star" />
                      </label>
                    </li>
                  </ul>
                </div>
                {/* apply-filter */}
                <div className="py-12">
                  <a
                    href="search-result.html"
                    className="btn-primary apply-filter-btn"
                  >
                    Apply Filter
                  </a>
                </div>
                {/* clear-all */}
                <div className="pt-12">
                  <button
                    type="button"
                    className="clear-all-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>

    </div>

  )
}

export default HomeApp;
