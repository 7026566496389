import React, { useEffect, useState } from 'react'

import swipercss from '../assest/css/swiper-bundle.min.css'

import commoncss from '../assest/css/common.css'
import welcomecss from '../assest/css/welcome.css'
import animationscss from '../assest/css/animations.css'
import authcss from '../assest/css/auth.css'
import profile from '../assest/css/profile.css'
import arrowleftblack from '../assest/svg/arrow-left-black.svg'


import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import axios, { Axios } from 'axios'
import { Hidden } from '@mui/material'


const UpdateSlot = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const id = searchParams.get("id");



    const responseVenue = location?.state?.data;

    const [venueDat, setVenueDat] = useState(location?.state?.data);

    console.log("DDD", responseVenue);










    const id2 = localStorage.getItem("id2");






    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/viewSlot/${id}`);
            setData(response.data);
            console.log("data", response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token === null) {
            navigate("/vendor_signin");
        }
        fetchData();
    }, []);



    const back = () => {
        window.history.back();
    };

    const slotid = data && data.slot && data.slot.id;

    console.log(slotid);

    const [formData, setFormData] = useState({
        slot_id: slotid,
        start_time: "",
        end_time: "",
        price: "",
    });

    useEffect(() => {
        if (data && data.slot && data.slot.id) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                slot_id: data.slot.id,
                start_time: data.slot.slot_start_time,
                end_time: data.slot.slot_end_time,
                price: data.slot.price,
            }));
        }
    }, [data]);


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
        console.log("Updated FormData:", { ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();

            for (const key in formData) {
                formDataToSend.append(key, formData[key]);
            }

            console.log("FormData to send:", formDataToSend); // Log the formDataToSend

            const response = await axios.post(
                `${apiBaseUrl}/updateSlots`,
                formDataToSend,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            const token = response.data.token;

            console.log("Token id is:", token);
            console.log("User id2 is:", id2);

            localStorage.setItem("token", token);
            localStorage.setItem("id2", id2);
            console.log("slots send :", formData);
            console.log("User tOKEN:", token);
            console.log("User id2:", id2);
            window.history.back();
        } catch (error) {
            console.log("Error while creating account", error);
        }
    };



    return (
        <div>
            <>
                <main>
                    {/* page-title */}
                    <div className="page-title">
                        <button
                            onClick={back}
                            type="button"
                            className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                        >
                            <img src={arrowleftblack} alt="arrow" />
                        </button>

                        <h3 className="main-title">Venue Slots</h3>
                    </div>

                    <section className="user-info px-24">
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <div className="mb-16" style={{ margin: "5px", width: "50%" }}>
                                    <label className="input-label">Select Start Time</label>
                                    <input
                                        type="time"
                                        className="input"
                                        id="start_time"
                                        name="start_time"
                                        defaultValue={data && data.slot && data.slot.slot_start_time}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="mb-16" style={{ margin: "5px", width: "50%" }}>
                                    <label className="input-label">Select End Time</label>
                                    <input
                                        type="time"
                                        className="input"
                                        id="end_time"
                                        name="end_time"
                                        defaultValue={data && data.slot && data.slot.slot_end_time}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="mb-16">
                                <label className="input-label">Select Slot Rate</label>
                                <input
                                    type="number"
                                    className="input mb-2"
                                    name="price"
                                    id="'price"
                                    defaultValue={data && data.slot && data.slot.price}
                                    onChange={handleChange}
                                />
                            </div>

                            <input
                                type="hidden"
                                className="input mb-2"
                                name="slot_id"
                                value={data && data.slot && data.slot.id}
                                id="'slot_id"
                                onChange={handleChange}
                            />

                            <input type="submit" className="btn-primary" value="submit" />
                        </form>
                    </section>
                </main>
            </>
        </div>
    );
};



export default UpdateSlot;




