import React from 'react'
import bundle from '../assest/assets-demo/css/style.css'
import App from '../App.css'
import homecss from '../assest/css/home.css'
import welcomecss from '../assest/css/welcome.css'
import commoncss from '../assest/css/common.css'
import jquerycss from '../assest/css/jquery-ui.min.css'
import swipercss from '../assest/css/swiper-bundle.min.css'
import bootstrapcss from '../assest/css/bootstrap.min.css'
import ticket from '../assest/css/ticket.css'

 import cricketimg from  '../assest/homeimage/cricket.png'
 import footballimg  from '../assest/homeimage/soccer-ball.png'
 import volleyballimg from '../assest/homeimage/volleyball.png'
 import category from '../assest/images/home/category.png'

import humberg from  '../assest/svg/menu/burger-white.svg'
import sunwhite from  '../assest/svg/menu/sun-white.svg'
import moonwhite from '../assest/svg/menu/moon-white.svg'
import profilewhite from  '../assest/svg/menu/profile-white.svg'
 import search from '../assest/svg/search.svg'
  import filterblack from '../assest/svg/filter-black.svg'
  import { Link, Navigate, useNavigate } from 'react-router-dom'
  
 import ssk from '../assest/recommendationimage/ssk.svg'
 import chakde from '../assest/recommendationimage/chakde.svg'
 import runbumi from '../assest/recommendationimage/runbhumi.svg'
 import stumps from '../assest/recommendationimage/stonch.svg'
 import startyellow from '../assest/svg/star-yellow.svg'
import arrowleftblack from "../assest/svg/arrow-left-black.svg";


const UpdateVenueList = () => {
  const navigate = useNavigate();

    const back = () => {
      navigate("/vendor_list");
    };
  return (
    <div>
      <section className="budget pt-12">
        {/* title */}
        <div
          className="title d-flex align-items-center "
          style={{ justifyContent: "center" }}
        >
          <h2 className="shrink-0"> All Venue List</h2>
        </div>

        <section className="search py-12 " style={{ padding: "10px" }}>
          <form action="#">
            <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24">
              <img src={search} alt="search" className="shrink-0" />
              <input
                type="search"
                className="input-search input-field"
                placeholder="Search..."
              />
              <div className="filter shrink-0">
                <button
                  type="button"
                  className="d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#filterModal"
                >
                  <img src={filterblack} alt="filter" />
                </button>
              </div>
            </div>
          </form>
        </section>

        <ul style={{ padding: "0px" }}>
          {/* item 1 */}

          <li>
            <a className="d-flex align-items-center gap-12">
              <div
                className="ticket-card radius-8"
                style={{ width: "100%", margin: "10px" }}
              >
                {/* card-title */}
                <div className="card-title d-flex align-items-center justify-content-between">
                  <span>Update Venue</span>
                  <span>Delete Venue</span>
                </div>

                {/* card-item */}

                <div className="card-item d-flex align-items-center gap-16 w-100">
                  <div
                    className=" shrink-0 overflow-hidden radius-8"
                    style={{ width: "100%", height: "200px" }}
                  >
                    <img
                      src={ssk}
                      alt="Place"
                      className=" object-fit-cover"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>

                <div className="content flex-grow">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h4>Runbumi Turf</h4>
                    <p className="rating d-flex align-items-center gap-04 ">
                      <img src={startyellow} alt="icon" />
                      4.4 <span>(41 Reviews)</span>
                    </p>
                  </div>

                  <p className="d-flex align-items-center gap-04 location mt-04">
                    {/* <img src="../assets/svg/map-marker.svg" alt="icon" /> */}
                    City Center Mall, Nashik
                  </p>
                </div>
              </div>
            </a>
          </li>
          <hr></hr>

          {/* item 2 */}

          <li>
            <a className="d-flex align-items-center gap-12">
              <div
                className="ticket-card radius-8"
                style={{ width: "100%", margin: "10px" }}
              >
                {/* card-title */}
                <div className="card-title d-flex align-items-center justify-content-between">
                  <span>Update Venue</span>
                  <span>Delete Venue</span>
                </div>

                {/* card-item */}

                <div className="card-item d-flex align-items-center gap-16 w-100">
                  <div
                    className=" shrink-0 overflow-hidden radius-8"
                    style={{ width: "100%", height: "200px" }}
                  >
                    <img
                      src={chakde}
                      alt="Place"
                      className=" object-fit-cover"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>

                <div className="content flex-grow">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h4>Chakde Turf</h4>
                    <p className="rating d-flex align-items-center gap-04 ">
                      <img src={startyellow} alt="icon" />
                      4.4 <span>(41 Reviews)</span>
                    </p>
                  </div>

                  <p className="d-flex align-items-center gap-04 location mt-04">
                    {/* <img src="../assets/svg/map-marker.svg" alt="icon" /> */}
                    Pakhal Road, Nashik, Maharashtra
                  </p>
                </div>
                {/* card-footer */}
      
              </div>
            </a>
          </li>
          <hr></hr>

          {/* item 3 */}
          <li>
            <a className="d-flex align-items-center gap-12">
              <div
                className="ticket-card radius-8"
                style={{ width: "100%", margin: "10px" }}
              >
                {/* card-title */}
                <div className="card-title d-flex align-items-center justify-content-between">
                  <span>Update Venue</span>
                  <span>Delete Venue</span>
                </div>

                {/* card-item */}

                <div className="card-item d-flex align-items-center gap-16 w-100">
                  <div
                    className=" shrink-0 overflow-hidden radius-8"
                    style={{ width: "100%", height: "200px" }}
                  >
                    <img
                      src={stumps}
                      alt="Place"
                      className=" object-fit-cover"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>

                <div className="content flex-grow">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <h4>Starigth drive Turf</h4>
                    <p className="rating d-flex align-items-center gap-04 ">
                      <img src={startyellow} alt="icon" />
                      4.4 <span>(41 Reviews)</span>
                    </p>
                  </div>

                  <p className="d-flex align-items-center gap-04 location mt-04">
                    {/* <img src="../assets/svg/map-marker.svg" alt="icon" /> */}
                    Bapu Pool College Road , Nashik
                  </p>
                </div>
                {/* card-footer */}
    
              </div>
            </a>
          </li>

          <hr></hr>
        </ul>
      </section>
    </div>
  );
};



export default UpdateVenueList;