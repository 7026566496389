

import React, { useState, useEffect } from "react";
import axios from "axios";

import chat from '../assest/css/chat.css'
import avatar from '../assest/images/profile/avatar.png'
import arrowleftblack from '../assest/svg/arrow-left-black.svg';
import send from '../assest/svg/send.svg'
import clip from '../assest/svg/paperclip.svg'
import manualcss from '../assest/css/manualcss.css';
import { Link, Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';


function VendorSupport({ conId }) {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [userId, setId] = useState(localStorage.getItem("id2"));
    // console.log(userId);
    const [con_id, setConId] = useState(localStorage.getItem("con_id"))
    const [getRows, setRows] = useState([]);
    const [loadData, setData] = useState([]);
    const [message, setMessages] = useState("");
    const [conversation, setConversation] = useState(null);
    const [desc, setDesc] = useState("");
    const { conIdFromParams } = useParams();
    const [conversationData, setConversationData] = useState(null);
    const [messagesData, setMessagesData] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const conid = searchParams.get("id");
    console.log(conid);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // conId = 35;
                const response = await axios.get(`${apiBaseUrl}/userConversations/${conid}`);
                setConversationData(response.data.conversation);
                setMessagesData(response.data.messages);
                setId(userId);
                // console.log(response.data.conversation);
                console.log(response);
                console.log('view id', userId);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [conId]);

    const [open, setOpen] = React.useState(false);
    const loadList = async () => {
        try {
            const id2 = localStorage.getItem("id2");
            // const con_id = localStorage.getItem("con_id");
            console.log('view id', userId);
            console.log(con_id);
            const result = await axios.get(`${apiBaseUrl}/userConversations/${con_id}`);
            setConversation(result.data.conversation);
            setMessages(result.data.messages);
            console.log("Result data:", result.data.conversation);
            console.log("Result data:", result.data.messages);
            setData(result.data);
        } catch (error) {
            console.error("Error fetching conversation messages:", error);
        }
    };

    // To get total count from backend
    const getLatestRowDetails = async () => {
        const results = await axios.get(`${apiBaseUrl}/getLatestRow`);
        console.log("Result data:", results.data);
        setRows(results.data);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        return `${day}/${month}/${year} - ${hours}:${minutes}`;
    };


    // // Calling useEffect
    // useEffect(() => {
    //     loadList(conIdFromParams);
    //     getLatestRowDetails();
    // }, [conIdFromParams]);

    const handleSubmission = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        // const id = localStorage.getItem("id");
        console.log("handle submite id", userId);
        const formData = new FormData();
        formData.append("message", message);
        formData.append("vendor_id", userId);
        formData.append("con_id", conid);
        // formData.append("desc", desc);

        try {
            await fetch(`${apiBaseUrl}/sendMessage`, {
                method: "POST",
                body: formData,
            });

            console.log("Data submitted successfully:", message);

            // Reset the input field after submission
            setMessages(""); // This will clear the input field
            window.location.reload(); // Reload the page

        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    return (
        <div>
            <main className="chat">
                {/* page-title */}
                {conversationData &&
                    <div className="page-title pageHead">
                        <button
                            type="button"
                            className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                        >
                            <Link to="/vendor_query">
                                <img src={arrowleftblack} alt="arrow" />
                            </Link>
                        </button>
                        <h3 className="main-title mainTitleLight">{conversationData.user1_name}</h3>
                        <button
                            type="button"
                            className="btn d-flex align-items-center justify-content-center rounded-full"

                        >
                            <img src={avatar} style={{
                                width: "43px",
                                border: '2px solid white',
                                borderRadius: "100px"

                            }} alt="avtar" />
                        </button>
                    </div>
                }

                <section className="inbox px-24">
                    {messagesData && (
                        <ul style={{ paddingLeft: "0" }}>
                            {messagesData.map((row, index) => (
                                <li key={index} className={
                                    row.user_type === 'admin' ? 'left d-flex gap-12' :
                                        row.user_type === 'field_executive' ? 'left d-flex gap-12' :
                                            'right d-flex flex-row-reverse gap-12'
                                }>
                                    <div className="avatar position-relative">
                                        <img
                                            src={avatar}
                                            alt="Avatar"
                                            className="w-100 img-fluid h-100 object-fit-cover rounded-full"
                                        />
                                        <span className="active-dot rounded-full d-inline-block" />
                                    </div>
                                    <div className="text">
                                        <p className="msg" style={{ marginBottom: '0' }}>
                                            {row.message}
                                            {/* {row.desc} */}
                                        </p>
                                        <p className="time pt-04">{formatDate(row.created_at)}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </section>


                {/* Submit form       */}
                <section className="type-msg">
                    <div className="d-flex align-items-center gap-8 type-main">
                        <label htmlFor="input-file" className="input-file-label shrink-0">
                            <input type="file" id="input-file" />
                            <span className="d-flex align-items-center justify-content-center rounded-full">
                                <img src={clip} alt="icon" />
                            </span>
                        </label>
                        <input
                            type="text"
                            placeholder="Message"
                            name="message"
                            label="message"
                            className="flex-grow input-msg"
                            onChange={(e) => setMessages(e.target.value)}

                        />

                        <button
                            variant="contained"
                            type="submit"
                            className="d-flex align-items-center justify-content-center rounded-full shrink-0"
                            onClick={handleSubmission}
                        >
                            <img src={send} alt="icon" />
                        </button>

                    </div>

                </section>

            </main>
        </div>


    );
}
export default VendorSupport;

