import React, { useEffect, useState } from 'react';
import bootstrapcss from '../assest/css/bootstrap.min.css';
import swipercss from '../assest/css/swiper-bundle.min.css';
import jquerycss from '../assest/css/jquery-ui.min.css';
import commoncss from '../assest/css/common.css';
import welcomecss from '../assest/css/welcome.css';
import animationscss from '../assest/css/animations.css';
import authcss from '../assest/css/auth.css';
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import manaualcss from "../assest/css/manualcss.css";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const AddVenue = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const vendorIdFromParams = searchParams.get("id");
  const [vendorId, setVendorId] = useState(vendorIdFromParams);
  const [data, setData] = useState(null);
  const [gameType, setGameType] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const id1 = localStorage.getItem("id1");

  const [turfData, setTurfData] = useState({
    turfname: "",
    facilities: [],
    turfsports: [],
    turfimage: "",
    vendor_id: vendorIdFromParams,
  });

  const [showPopup, setShowPopup] = useState(false);

  const fetchData = async () => {
    try {
      const gameResponse = await axios.get(`${apiBaseUrl}/gametypeData`);
      setGameType(gameResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token == null) {
      navigate('/user_sign');
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value, files, type, selectedOptions } = event.target;
    if (type === "select-multiple") {
      const selectedValues = Array.from(selectedOptions, (option) => option.value);
      setTurfData({
        ...turfData,
        [name]: selectedValues,
      });
    } else if (name === "turfimage") {
      const selectedFile = files[0];
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
      if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
        setTurfData({
          ...turfData,
          [name]: selectedFile,
        });
      } else {
        alert("Please upload a file with JPEG, PDF, PNG, or JPG format.");
        event.target.value = "";
      }
    } else {
      setTurfData({
        ...turfData,
        [name]: value,
      });
    }
  };

  const back = () => {
    navigate("/venue_list");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in turfData) {
        formDataToSend.append(key, turfData[key]);
      }
      const response = await axios.post(`${apiBaseUrl}/add_venue`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate("/fields_executive_dashboard");
      }, 3000);
    } catch (error) {
      console.error("Error while creating account", error);
    }
  }

  return (
    <div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            Form submitted successfully!
          </div>
        </div>
      )}
      <main>
        <section className="auth signin">
          <div className="page-title pageHead">
            <button
              onClick={back}
              type="button"
              className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowleftblack} alt="arrow" />
            </button>
            <h3 className="main-title mainTitleLight">Add Venue</h3>
          </div>

          <div className="form-area auth-form" style={{ height: '80vh' }}>
            <form onSubmit={handleSubmit}>
              <div className="icon-container">
                <div>
                  <label htmlFor="turfname">Add Venue Name <span className="required"></span></label>
                  <input
                    type="text"
                    id="turfname"
                    name="turfname"
                    className="input-field"
                    placeholder="Venue Name"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="turfimage">Add Venue Image <span className="required"></span></label>
                  <input
                    type="file"
                    id="turfimage"
                    name="turfimage"
                    className="input-field"
                    placeholder="Venue Image"
                    style={{ padding: "8px" }}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="turfsports">Select Sports Type </label>
                  <div className="position-relative">
                    <select
                      multiple
                      id="turfsports"
                      name="turfsports"
                      className="input-field"
                      value={turfData.turfsports}
                      onChange={handleChange}
                      required
                    >
                      {gameType && gameType.gameTypes
                        ? gameType.gameTypes.map((game, index) => (
                          <option key={index} value={game}>
                            {game}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                </div>

                <div>
                  <label htmlFor="facilities">Select Facilities</label>
                  <select
                    multiple
                    id="facilities"
                    name="facilities"
                    className="input-field"
                    value={turfData.facilities}
                    onChange={handleChange}
                    required
                  >
                    {gameType && gameType.facilities
                      ? gameType.facilities.map((facility, index) => (
                        <option key={index} value={facility}>
                          {facility}
                        </option>
                      ))
                      : null}
                  </select>
                </div>

                <button className="btn-primary" style={{ marginTop: "15px" }}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>

      <style jsx>{`
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .popup-content {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          font-size: 1.5rem;
          text-align: center;
        }
      `}</style>
    </div>
  );
}

export default AddVenue;

