import bootstrapcss from '../assest/css/bootstrap.min.css'
import swipercss from '../assest/css/swiper-bundle.min.css'
import jquerycss from '../assest/css/jquery-ui.min.css'
import commoncss from '../assest/css/common.css'
import welcomecss from '../assest/css/welcome.css'
import animationscss from '../assest/css/animations.css'
import authcss from '../assest/css/auth.css'
import manualcss from '../assest/css/manualcss.css';

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Tabs, Typography, Box } from "@mui/material";

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCancel, faCoffee, faFirstAid, faMusic, faRestroom, faToilet } from "@fortawesome/free-solid-svg-icons";
import IconSelector from '../assest/css/IconSelector.css'

import buttonswitch from '../assest/css/buttonswitch.css';
import skillintrest from '../assest/css/skillintrest.css';
import venuecss from '../assest/css/venue.css';
import x from '../assest/images/profile/close.png';
import calenderIcon from '../assest/images/details/calender-icon.png';
import firstaid from '../assest/svg/facility/firstaid.svg'
import frestroom from '../assest/svg/facility/frestroom.svg'
import fhotel from '../assest/svg/facility/fhotel.svg'
import fparking from '../assest/svg/facility/fparking.svg'
import manaualcss from "../assest/css/manualcss.css";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";
import axios from 'axios';
import { Navigate } from 'react-router-dom';

import Select from 'react-select';
const AddExecutiveData = () => {
  const navigate = useNavigate();

  const back = () => {
    navigate("/fields_executive_dashboard");
  };

  const [data, setData] = useState(null);
  const [gameType, setGameType] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [flashMessage, setFlashMessage] = useState(false);  // State for flash message

  const fetchData = async () => {
    try {
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/showpageapi/${id}`);
      setData(response.data);
      console.log(response.data);
      const gameResponse = await axios.get(`${apiBaseUrl}/gametypeData`);
      setGameType(gameResponse.data);
      console.log("gameData", JSON.stringify(gameResponse.data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/user_signin');
    } else {
      navigate('/add_executive_data');
    }
    fetchData();
  }, []);

  const id = localStorage.getItem('id');
  const id1 = localStorage.getItem("id1");
  const [activeButton, setActiveButton] = useState('button1');

  const [venderRegister, setVenderRegister] = useState({
    bname: "",
    btype: [],
    gst: "",
    description: "",
    location: "",
    turf_img: "",
    bownername: "",
    bownermobile: "",
    bowneremail: "",
    dob: "",
    address: "",
    adhar: "",
    adharimg: "",
    pan: "",
    panimg: "",
    bank: "",
    accountname: "",
    accountnumber: "",
    ifsc: "",
    id: id,
  });

  const handleChange = (e) => {
    const {
      name,
      type,
      value,
      files,
    } = e.target;

    if (name === 'adharimg' || name === 'panimg' || name === 'turf_img') {
      const selectedFile = files[0];
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
      if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
        setVenderRegister({
          ...venderRegister,
          [name]: selectedFile,
        });
      } else {
        alert('Please upload a file with JPEG, PDF, PNG, or JPG format.');
        e.target.value = '';
      }
    } else if (name === "turfsports" || name === "facilities") {
      const selectedValues = Array.from(
        e.target.selectedOptions || [],
        (option) => option.value
      );
      setVenderRegister({
        ...venderRegister,
        [name]: selectedValues,
      });
    } else {
      setVenderRegister({
        ...venderRegister,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in venderRegister) {
        formDataToSend.append(key, venderRegister[key]);
      }

      const response = await axios.post(
        `${apiBaseUrl}/add_venderRegister`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const id1 = response.data.user_id1;
      localStorage.setItem("id1", id1);

      // Show flash message
      setFlashMessage(true);

      // Hide flash message after 3 seconds and navigate
      setTimeout(() => {
        setFlashMessage(false);
        navigate("/fields_executive_dashboard");
      }, 3000);
    } catch (error) {
      console.log("Error while creating account", error);
    }
  };

  const handleSelectChange = (e) => {
    const selected = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedOptions(selected);
    setVenderRegister({
      ...venderRegister,
      btype: selected,
    });
  };

  return (
    <div>
      {flashMessage && (
        <div className="popup">
          <div className="popup-content">
            Form submitted successfully!
          </div>
        </div>
      )}
      <main>
        <section className="auth signin">
          <div className="page-title pageHead">
            <button
              onClick={back}
              type="button"
              className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowleftblack} alt="arrow" />
            </button>
            <h3 className="main-title mainTitleLight">Add Vendor</h3>
          </div>

          <div className="form-area auth-form">
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="lemail1">
                  Business Name <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="bname"
                    name="bname"
                    placeholder="Enter Business Name"
                    className="input-field"
                    value={venderRegister.bname || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="rpass">Business Type</label>
                <div className="position-relative">
                  <select
                    multiple
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    id="btype"
                    name="btype"
                    required
                  >
                    {gameType && gameType.gameTypes
                      ? gameType.gameTypes.map((game, index) => (
                        <option key={index} value={game}>
                          {game}
                        </option>
                      ))
                      : null}
                  </select>
                </div>
              </div>

              <div>
                <label htmlFor="remail2">
                  Business Image <span className="required"></span>
                </label>
                <input
                  type="file"
                  id="turf_img"
                  name="turf_img"
                  className="input-field d-block"
                  style={{ padding: "8px" }}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label htmlFor="rpass">
                  GST <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="gst"
                    name="gst"
                    placeholder="Enter your GST Number"
                    className="input-field"
                    value={venderRegister.gst || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="description">
                  Description <span className="required"></span>
                </label>
                <div className="position-relative">
                  <textarea
                    id="description"
                    name="description"
                    placeholder="Enter Description"
                    className="input-field"
                    style={{ padding: '16px', height: '132px' }}
                    value={venderRegister.description || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  Location Link<span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Google map link"
                    className="input-field"
                    value={venderRegister.location || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  Business Owner Name <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="bownername"
                    name="bownername"
                    placeholder="Enter Business Owner Name"
                    className="input-field"
                    value={venderRegister.bownername || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  Business Owner Mobile <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="bownermobile"
                    name="bownermobile"
                    placeholder="Enter Business Owner Mobile Number"
                    className="input-field"
                    value={venderRegister.bownermobile || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  Business Owner Email <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="bowneremail"
                    name="bowneremail"
                    placeholder="Enter Business Owner Email"
                    className="input-field"
                    value={venderRegister.bowneremail || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  Date of Birth <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    placeholder="Enter DOB"
                    className="input-field"
                    value={venderRegister.dob || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  Address <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Enter Address"
                    className="input-field"
                    value={venderRegister.address || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  Adhar Number <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="adhar"
                    name="adhar"
                    placeholder="Enter Adhar Number"
                    className="input-field"
                    value={venderRegister.adhar || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="remail2">
                  Adhar Image <span className="required"></span>
                </label>
                <input
                  type="file"
                  id="adharimg"
                  name="adharimg"
                  className="input-field d-block"
                  style={{ padding: "8px" }}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label htmlFor="rpass">
                  Pan Number <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="pan"
                    name="pan"
                    placeholder="Enter Pan Number"
                    className="input-field"
                    value={venderRegister.pan || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="remail2">
                  Pan Image <span className="required"></span>
                </label>
                <input
                  type="file"
                  id="panimg"
                  name="panimg"
                  className="input-field d-block"
                  style={{ padding: "8px" }}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label htmlFor="rpass">
                  Bank Name <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="bank"
                    name="bank"
                    placeholder="Enter Bank Name"
                    className="input-field"
                    value={venderRegister.bank || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  Account Holder Name <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="accountname"
                    name="accountname"
                    placeholder="Enter Account Holder Name"
                    className="input-field"
                    value={venderRegister.accountname || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  Account Number <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="accountnumber"
                    name="accountnumber"
                    placeholder="Enter Account Number"
                    className="input-field"
                    value={venderRegister.accountnumber || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rpass">
                  IFSC Code <span className="required"></span>
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="ifsc"
                    name="ifsc"
                    placeholder="Enter IFSC Code"
                    className="input-field"
                    value={venderRegister.ifsc || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="mt-3">
                <button type="submit" className="btn-primary"> Submit KYC</button>
              </div>
            </form>
          </div>
        </section>
      </main>
      <style jsx>{`
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .popup-content {
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          font-size: 1.5rem;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default AddExecutiveData;
