

// import React, { useEffect, useState } from "react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

// Import your CSS files as needed
import "../assest/css/bootstrap.min.css";
import "../assest/css/swiper-bundle.min.css";
import "../assest/css/jquery-ui.min.css";
import "../assest/css/common.css";
import "../assest/css/welcome.css";
import "../assest/css/animations.css";
import "../assest/css/auth.css";
import "../assest/css/home.css";
import "../assest/css/chat.css";
import "../assest/css/datetimepicker.css";
import "../assest/css/vendor_booking.css";
import "../assest/css/manualcss.css";

import plus from '../assest/svg/plus-white.svg';
import avatar from "../assest/images/profile/avatar.png";
import arrowleftblack from "../assest/svg/arrow-left-black.svg";

const BookingStatus = ({ vendorId }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [isOn, setIsOn] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the current booking status when the component mounts
        const fetchStatus = async () => {
            const id2 = localStorage.getItem("id2");
            try {
                const response = await axios.get(`${apiBaseUrl}/booking_status/${id2}`);
                setIsOn(response.data.status === 'on');
                setLoading(false);
            } catch (error) {
                console.error('Error fetching booking status:', error);
                setLoading(false);
            }
        };

        fetchStatus();
    }, [apiBaseUrl]);

    const toggleSwitch = async () => {
        const id2 = localStorage.getItem("id2");
        const newStatus = !isOn;
        setIsOn(newStatus);

        try {
            await axios.post(`${apiBaseUrl}/booking_status`, {
                vendor_id: id2,
                status: newStatus ? 'on' : 'off',
            });

            console.log('Booking status updated successfully');
        } catch (error) {
            console.error('Error updating booking status:', error);
            // Revert the state in case of an error
            setIsOn(!newStatus);
        }
    };

    const handleLogout = () => {
        // Remove token from localStorage
        localStorage.removeItem("token");
        // Navigate to the login page
        navigate("/vendor_signin");
    };

    if (loading) {
        return (
            <div className="loader">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <main className="mainBackground">
            <div className="page-title pageHead">
                <button
                    type="button"
                    className="white_button btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
                >
                    <Link to="/vendor_dashboard">
                        <img src={arrowleftblack} alt="arrow" />
                    </Link>
                </button>
                <h3 className="main-title mainTitleLight">Settings</h3>
            </div>
            <div className="settings_main">
                <p className="settings_main_p"><strong>Booking Status Change</strong></p>
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', fontSize: '18px' }} className="settings_point">
                    <p style={{ display: 'inline' }}>Online Booking</p>
                    <div className="switch-container" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                        <label className="switch">
                            <input type="checkbox" checked={isOn} onChange={toggleSwitch} />
                            <span className="slider"></span>
                        </label>
                        <p style={{ fontSize: "16px", margin: '0 10px', color: "gray" }}>{isOn ? 'ON' : 'OFF'}</p>
                    </div>
                </div>
            </div>
            <div>
                <ul>
                    <li onClick={handleLogout}>
                        {/* <a className="d-flex align-items-center justify-content-center position-relative" */}
                        <a className="position-relative "
                            style={{ textDecoration: "none", margin: '5px 0', textAlign: "center" }} >
                            <p style={{ color: "black", fontSize: '16px' }}>Logout</p>
                        </a>
                    </li>
                </ul>
            </div>
        </main>
    );
};

export default BookingStatus;
